import React, { useState } from 'react';

import { Box, Button, Grid, TextField, useMediaQuery } from '@mui/material';

import { theme } from '../../../theme';
import { ImpactText, StyledButton } from '../explore.style';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../app/hooks';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { googleApiUrl } from '../../../app/api/googleApi';
import { setToastNotification } from '../../../app/slices/appSlice';
import { LoadingButton } from '@mui/lab';
import InputMask from 'react-input-mask';

type CouponRequestFormT = {
  name: string;
  email: string;
  whatsapp: string;
};

function Form() {
  const dispatch = useAppDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Nome é obrigatório'),
    email: Yup.string().required('Email é obrigatório').email('Email inválido'),
    whatsapp: Yup.string().required('Whatsapp é obrigatório'),
  });

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm<CouponRequestFormT>({
    resolver: yupResolver(validationSchema),
    defaultValues: {},
  });

  const handleSubmitForm: SubmitHandler<CouponRequestFormT> = async (values) => {
    const form = new FormData();
    form.append('email', values.email);
    form.append('nome', values.name);
    form.append('whatsapp', values.whatsapp);
    form.append('sheet', 'cupom');
    setLoading(true);

    fetch(googleApiUrl, {
      method: 'POST',
      body: form,
    })
      .then(() => {
        setLoading(false);
        dispatch(
          setToastNotification({
            type: 'success',
            open: true,
            message: 'Pedido de cupom enviado com sucesso!!',
          }),
        );
        reset({ name: '', whatsapp: '', email: '' });
      })
      .catch((err) => console.log(err));
  };

  const handleNavigateToExplore = () => navigate('/explore/search');

  return (
    <Box
      sx={{
        paddingX: { xs: 0, md: 10 },
        backgroundColor: theme.palette.primary.main,
      }}
    >
      <Grid container>
        <Grid
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            paddingBottom: { xs: 0, md: 10 },
            paddingTop: isMobile ? 10 : 5,
            paddingRight: isMobile ? 0 : 20,
          }}
          item
          xs={12}
          md={6}
        >
          <ImpactText
            variant={isMobile ? 'h4' : 'h2'}
            sx={{
              textAlign: { xs: 'center', md: 'left' },
              color: theme.palette.white.main,
              fontWeight: 200,
            }}
          >
            Preencha os dados e receba um <b>cupom de desconto exclusivo</b> para seu evento!
          </ImpactText>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              paddingX: { xs: 0, md: 15 },
              paddingTop: { xs: 0, md: 15 },
              paddingBottom: { xs: 5, md: 0 },
            }}
          >
            <Box
              sx={{
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.white.main,
                padding: { xs: 2, md: 5 },
                paddingBottom: { xs: 2, md: 0 },
              }}
            >
              <Box
                display='flex'
                flexDirection='column'
                justifyContent='space-between'
                gap={2}
                flexWrap={isMobile ? 'wrap' : 'inherit'}
                sx={{
                  input: {
                    backgroundColor: theme.palette.white.main,
                  },
                }}
              >
                <TextField
                  required
                  id='firstName'
                  label='Nome'
                  size='small'
                  margin='normal'
                  type='text'
                  fullWidth
                  placeholder='Digite aqui seu nome'
                  variant='filled'
                  {...register('name')}
                  error={errors.name ? true : false}
                  helperText={errors.name ? String(errors.name.message) : null}
                />
                <TextField
                  required
                  id='email'
                  label='Email'
                  size='small'
                  margin='normal'
                  type='email'
                  fullWidth
                  placeholder='Digite aqui seu email'
                  variant='filled'
                  {...register('email')}
                  error={errors.email ? true : false}
                  helperText={errors.email ? String(errors.email.message) : null}
                />
                <Controller
                  name='whatsapp'
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <InputMask mask='(99) 99999-9999' value={value} onChange={onChange}>
                      <TextField
                        required
                        id='whatsapp'
                        label='Whatsapp'
                        fullWidth
                        type='tel'
                        size='small'
                        margin='normal'
                        variant='filled'
                        InputLabelProps={{ shrink: true }}
                        error={errors.whatsapp ? true : false}
                        helperText={errors.whatsapp ? String(errors.whatsapp.message) : null}
                      />
                    </InputMask>
                  )}
                />
                {isLoading ? (
                  <LoadingButton
                    size='large'
                    sx={{ marginTop: '15px' }}
                    loading
                    variant='contained'
                    color='primary'
                    fullWidth
                  >
                    Carregando
                  </LoadingButton>
                ) : (
                  <StyledButton
                    size='large'
                    sx={{ marginTop: '15px' }}
                    variant='contained'
                    onClick={handleSubmit(handleSubmitForm)}
                    fullWidth
                  >
                    ENVIAR
                  </StyledButton>
                )}
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                paddingX: { xs: 2, md: 5 },
                flexDirection: { xs: 'column', md: 'row' },
                textAlign: { xs: 'center', md: 'left' },
              }}
            >
              <ImpactText variant={isMobile ? 'h4' : 'h3'} sx={{color: theme.palette.white.main}}>OU</ImpactText>
              <Button
                size='large'
                sx={{
                  marginLeft: isMobile ? '0' : '40px',
                  border: `2px solid ${theme.palette.white.main}`,
                  marginTop: isMobile ? '0' : '5%',
                  marginBottom: isMobile ? '0' : '-5%',
                  borderRadius: 0,
                  boxShadow: 0,
                  // color: theme.palette.primary.main,
                  // background: theme.palette.white.main,
                  // ':hover': {
                  //   color: theme.palette.white.main,
                  // },
                }}
                variant='contained'
                onClick={handleNavigateToExplore}
                fullWidth
              >
                EXPLORAR MENUS NA PLATAFORMA
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Form;
