import { useEffect } from 'react';

import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Box, Button, TextField, Typography, Link } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { theme } from '../../theme';
import useMediaQuery from '@mui/material/useMediaQuery';

import { useCreateContactMutation } from '../../app/api/contactAPI';
import { useAppDispatch } from '../../app/hooks';
import { setToastNotification } from '../../app/slices/appSlice';

import { BoxCenter, ImpactText } from './contact.styles';
import { Instagram } from '@mui/icons-material';

export type CreateContactFormT = {
  firstName: string;
  secondName: string;
  email: string;
  message: string;
};

function Contact() {
  const dispatch = useAppDispatch();
  const [createContact, { isLoading, isSuccess }] = useCreateContactMutation();
  const smallSize = useMediaQuery(theme.breakpoints.down('sm'));

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('Nome é obrigatório'),
    secondName: Yup.string(),
    email: Yup.string().required('Email é obrigatório').email('Email inválido'),
    message: Yup.string()
      .required('Mensagem é obrigatório')
      .max(600, 'Máximo de caracteres atingido.'),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<CreateContactFormT>({
    resolver: yupResolver(validationSchema),
    defaultValues: {},
  });

  const handleSubmitForm: SubmitHandler<CreateContactFormT> = async (values) => {
    const params = {
      name: `${values.firstName.trim()} ${values.secondName.trim()}`,
      email: values.email,
      message: values.message,
    };
    createContact(params);
  };

  useEffect(() => {
    if (isSuccess) {
      dispatch(
        setToastNotification({
          type: 'success',
          open: true,
          message: 'Contato enviado com sucesso!!',
        }),
      );
      reset({ email: '', firstName: '', message: '', secondName: '' });
    }
  }, [isSuccess]);

  return (
    <Box
      component='form'
      paddingTop={8}
      paddingBottom={20}
      display='flex'
      gap={3}
      flexDirection='column'
    >
      <BoxCenter padding={3}>
        <ImpactText variant={smallSize ? 'h3' : 'h2'}>Precisa de ajuda?</ImpactText>
        <Typography variant='subtitle1'>
          Entre em contato conosco para que possamos ajudá-lo, retornaremos o mais breve possível!
        </Typography>
      </BoxCenter>
      <BoxCenter padding={2} sx={{ backgroundColor: '#ebedec' }}>
        <Box
          paddingTop={3}
          display='flex'
          justifyContent='center'
          alignItems='center'
          flexDirection='column'
        >
          <Box
            display='flex'
            flexDirection='row'
            width='100%'
            justifyContent='space-between'
            flexWrap='wrap'
          >
            <Box margin={1}>
              <Typography variant='body1' marginBottom={1}>
                Telefone
              </Typography>
              <Typography variant='inherit'>(13) 99648-5920</Typography>
            </Box>
            <Box margin={1}>
              <Typography variant='body1' marginBottom={1}>
                Email
              </Typography>
              <Typography variant='inherit'>abouteat@abouteat.com.br</Typography>
            </Box>
            <Box margin={1}>
              <Typography variant='body1' marginBottom={1}>
                Redes sociais
              </Typography>
              <Link
                href='https://www.instagram.com/abouteatbr/'
                underline='none'
                target='_blank'
                sx={{ cursor: 'pointer' }}
              >
                <Instagram fontSize='small' sx={{ color: theme.palette.black.main }} />
              </Link>
            </Box>
          </Box>

          <Box
            display='flex'
            flexDirection='row'
            justifyContent='space-between'
            paddingTop={5}
            gap={2}
            flexWrap={smallSize ? 'wrap' : 'inherit'}
          >
            <TextField
              required
              id='firstName'
              label='Nome'
              size='small'
              margin='normal'
              InputLabelProps={{ shrink: true }}
              type='text'
              fullWidth
              placeholder='Digite aqui seu nome'
              {...register('firstName')}
              error={errors.firstName ? true : false}
              helperText={errors.firstName ? String(errors.firstName.message) : null}
            />
            <TextField
              required
              id='secondName'
              label='Sobrenome'
              size='small'
              margin='normal'
              InputLabelProps={{ shrink: true }}
              type='text'
              fullWidth
              placeholder='Digite aqui seu sobrenome'
              {...register('secondName')}
              error={errors.secondName ? true : false}
              helperText={errors.secondName ? String(errors.secondName.message) : null}
            />
            <TextField
              required
              id='email'
              label='Email'
              size='small'
              margin='normal'
              InputLabelProps={{ shrink: true }}
              type='email'
              fullWidth
              placeholder='Digite aqui seu email'
              {...register('email')}
              error={errors.email ? true : false}
              helperText={errors.email ? String(errors.email.message) : null}
            />
          </Box>

          <Box
            display='flex'
            flexDirection='row'
            width='100%'
            justifyContent='space-between'
            paddingTop={2}
          >
            <TextField
              required
              id='message'
              label='Mensagem'
              size='small'
              margin='normal'
              InputLabelProps={{ shrink: true }}
              type='text'
              fullWidth
              multiline
              rows={5}
              maxRows={5}
              placeholder='Digite aqui o motivo do contato...'
              {...register('message')}
              error={errors.message ? true : false}
              helperText={errors.message ? String(errors.message.message) : null}
            />
          </Box>

          <Box
            display='flex'
            flexDirection='row'
            width='100%'
            justifyContent='flex-end'
            paddingTop={1}
            flexWrap='wrap'
          >
            {isLoading ? (
              <LoadingButton
                loading
                size='medium'
                variant='contained'
                color='info'
                sx={{ width: '10rem' }}
              >
                Enviar
              </LoadingButton>
            ) : (
              <Button
                size='medium'
                variant='contained'
                color='info'
                sx={{
                  width: '10rem',
                }}
                onClick={handleSubmit(handleSubmitForm)}
              >
                Enviar
              </Button>
            )}
          </Box>
        </Box>
      </BoxCenter>
    </Box>
  );
}

export default Contact;
