import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
  
  export const CarouselItem = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 5px',
    cursor: 'pointer',
  }));