import { apiBase } from '../../service';

export type ChefT = {
  _id?: string;
  name: string;
  email: string;
  password?: string | undefined;
  phone: string;
  document: string;
  chef_type: string;
  bio: string;
  title: string;
  active?: boolean;
  latitude?: number | undefined;
  longitude?: number | undefined;
  location_address?: string | undefined;
  attendance_radius_km: number;
  bank_info: {
    pix?: string;
    bank_acc?: string;
    bank_agency?: string;
    bank_type?: string;
  };
};

export type EvaluationByChefIds = {
  ids: string[];
};

export type EvaluationDataByChefId = {
  chef_id: {
    evaluation_summary: { total_evaluation: number; total_number_of_schedulings: number };
  };
  total_eval: number;
  _id: string;
};

export type ChefUploadImageT = {
  _id?: string;
  picture: FormData;
};

export type ChefListT = {
  _id?: string;
  name: string;
  email: string;
  password: string;
  active: boolean;
  chef_id: {
    _id?: string;
    picture_url: string;
    phone: string;
    document: string;
    chef_type: string;
    bio: string;
    title: string;
    attendance_radius_km: number;
    bank_info: {
      pix?: string;
      bank_acc?: string;
      bank_agency?: string;
      bank_type?: string;
    };
    evaluation_summary: { total_evaluation: number; total_number_of_schedulings: number };
    location: {
      coordinates: number[];
      type: string;
    };
    location_address: string;
  };
  scheduling_evaluations: {
    total: number;
    preview: [
      {
        client_id: { _id: string; name: string };
        comment?: string;
        evaluation: number;
      },
    ];
  };
};

export const ChefService = apiBase.injectEndpoints({
  endpoints: (builder) => ({
    createChef: builder.mutation<ChefT, ChefT>({
      query: (createProps) => ({
        url: '/user/chef',
        method: 'POST',
        body: { ...createProps },
      }),
      invalidatesTags: ['Chef'],
    }),
    getEvaluationByChefIds: builder.mutation<EvaluationDataByChefId[], EvaluationByChefIds>({
      query: (ids) => ({
        url: 'user/chef/list-chef-evaluation-summary-by-user-ids',
        method: 'POST',
        body: ids,
      }),
    }),
    getChefs: builder.query<ChefListT[], undefined>({
      query: () => ({
        url: '/user?user_type_arr=["chef"]',
      }),
      providesTags: ['Chef'],
    }),
    getChefById: builder.query<ChefListT, string | undefined>({
      query: (id) => ({
        url: `/user/chef/${id}`,
      }),
      providesTags: ['Chef'],
    }),
    updateChef: builder.mutation<ChefT, ChefT>({
      query: ({
        _id,
        name,
        email,
        document,
        phone,
        chef_type,
        bio,
        bank_info,
        title,
        attendance_radius_km,
        password,
        active,
        longitude,
        latitude,
        location_address,
      }) => ({
        url: `/user/chef/${_id}`,
        method: 'PUT',
        body: {
          name,
          email,
          document,
          phone,
          chef_type,
          bio,
          bank_info,
          title,
          attendance_radius_km,
          password: password ?? undefined,
          active,
          longitude,
          latitude,
          location_address,
        },
      }),
      invalidatesTags: ['Chef'],
    }),
    uploadImage: builder.mutation<ChefUploadImageT, ChefUploadImageT>({
      query: (data) => ({
        url: `/user/chef/${data._id}/picture`,
        method: 'PATCH',
        body: data.picture,
      }),
      invalidatesTags: ['Chef'],
    }),
  }),
});

export const {
  useGetChefsQuery,
  useGetChefByIdQuery,
  useCreateChefMutation,
  useUpdateChefMutation,
  useUploadImageMutation,
  useGetEvaluationByChefIdsMutation,
} = ChefService;
