import React from 'react';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControlLabel,
  Switch,
  Box,
  Typography,
  IconButton,
  FormControl,
  Autocomplete,
} from '@mui/material';

import { CRUDTemplate } from '../../../components';
import {
  useGetAllCategoriesQuery,
  useCreateCategoryMutation,
  useUpdateCategoryMutation,
} from '../../../app/api/categoryAPI';
import { setToastNotification } from '../../../app/slices/appSlice';
import { useAppDispatch } from '../../../app/hooks';
import { useGetMenusQuery } from '../../../app/api/menuAPI';
import { Add, Delete } from '@mui/icons-material';

export type CategoryFormT = {
  title: string;
  sub_title: string;
  icon_name: string;
  order: number;
  is_showcase: boolean;
  active: boolean;
  menus: {
    _id?: string | undefined;
    label?: string;
  }[];
};

function Category() {
  const dispatch = useAppDispatch();
  const { data: categories } = useGetAllCategoriesQuery(undefined);
  const { data: menus } = useGetMenusQuery(undefined);
  const [createCategory, { isSuccess: isSuccessCreate }] = useCreateCategoryMutation();
  const [updateCategory, { isSuccess: isSuccessUpdate }] = useUpdateCategoryMutation();
  const [categoryToEdit, setCategoryToEdit] = React.useState<string | undefined>(undefined);
  const [isModalOpen, setModalOpen] = React.useState<boolean>(false);
  const resetFields = () => {
    reset({
      title: '',
      sub_title: '',
      icon_name: '',
      order: 0,
      is_showcase: false,
      active: false,
      menus: [],
    });
  };

  React.useEffect(() => {
    if (isSuccessCreate) {
      setModalOpen(false);
      resetFields();
      dispatch(
        setToastNotification({
          type: 'success',
          open: true,
          message: 'Categoria criada com sucesso!',
        }),
      );
    }
  }, [isSuccessCreate]);

  React.useEffect(() => {
    if (isSuccessUpdate) {
      setModalOpen(false);
      resetFields();
      dispatch(
        setToastNotification({
          type: 'success',
          open: true,
          message: 'Categoria atualizada com sucesso!',
        }),
      );
    }
  }, [isSuccessUpdate]);

  const validationSchema = Yup.object().shape({
    title: Yup.string().required('Título é obrigatório'),
    sub_title: Yup.string().required('Sub Título é obrigatório'),
    icon_name: Yup.string().required('Link do ícone é obrigatório'),
    order: Yup.number().required('Ordem é obrigatória').typeError('Ordem é obrigatória'),
    is_showcase: Yup.bool(),
    active: Yup.bool(),
    menus: Yup.array(
      Yup.object()
        .shape({
          _id: Yup.string(),
          label: Yup.string(),
        })
        .nullable(true)
        .required('Menu é obrigatório')
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .test('empty-menu', 'Menu é obrigatório', function (field: any) {
          if (field && field._id) {
            return true;
          }
          return false;
        }),
    ),
  });

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm<CategoryFormT>({
    resolver: yupResolver(validationSchema),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'menus',
  });

  const handleEditClick = (id: string) => {
    const selectedCategory = categories?.find((cat) => cat._id === id);
    if (selectedCategory) {
      reset({
        title: selectedCategory.title,
        sub_title: selectedCategory.sub_title,
        icon_name: selectedCategory.icon_name,
        order: selectedCategory.order,
        is_showcase: selectedCategory.is_showcase,
        active: selectedCategory.active,
        menus:
          selectedCategory.menus?.map((menuSelected) => {
            return {
              _id: menus?.find((menu) => menu._id === menuSelected._id && menu.active)?._id,
              label: menus?.find((menu) => menu._id === menuSelected._id && menu.active)?.name,
            };
          }) ?? [],
      });
      setCategoryToEdit(selectedCategory._id);
      setModalOpen(true);
    }
  };

  const handleCancelModal = () => {
    setModalOpen(false);
    setCategoryToEdit(undefined);
    resetFields();
  };

  const handleSubmitForm = (formValues: CategoryFormT) => {
    const menusArr: string[] = [];
    for (let index = 0; index < formValues.menus.length; index++) {
      const menuElement = formValues.menus[index];
      if (menuElement._id) {
        menusArr.push(menuElement?._id);
      }
    }
    const values = { ...formValues, menus: menusArr };
    if (categoryToEdit) {
      updateCategory({ ...values, _id: categoryToEdit });
    } else {
      // Use flat icons URL to the icons: https://www.flaticon.com/br/
      createCategory(values);
    }
  };

  return (
    <>
      <CRUDTemplate
        title='Categoria'
        onAddClick={() => setModalOpen(true)}
        onEditClick={(id: string) => handleEditClick(id)}
        hasEdit={true}
        columns={[
          {
            field: 'title',
            headerName: 'Título',
          },
          {
            field: 'sub_title',
            headerName: 'Sub Título',
          },
          {
            field: 'icon_name',
            headerName: 'Link do ícone',
          },
          {
            field: 'order',
            headerName: 'Ordem',
            type: 'number',
          },
          {
            field: 'is_showcase',
            headerName: 'Vitrine',
            type: 'boolean',
          },
          {
            field: 'active',
            headerName: 'Ativo',
            type: 'boolean',
          },
        ]}
        rows={categories || []}
      />
      <Dialog
        open={isModalOpen}
        onClose={(event: object, reason: string) => {
          if (reason !== 'backdropClick') {
            handleCancelModal();
          }
        }}
      >
        <DialogTitle>{categoryToEdit ? 'Editar' : 'Adicionar'} Categoria</DialogTitle>
        <DialogContent>
          <TextField
            required
            id='title'
            label='Título'
            fullWidth
            size='small'
            margin='normal'
            InputLabelProps={{ shrink: true }}
            {...register('title')}
            error={errors.title ? true : false}
            helperText={errors.title ? String(errors.title.message) : null}
          />
          <TextField
            required
            id='sub_title'
            label='Sub Título'
            fullWidth
            size='small'
            margin='normal'
            InputLabelProps={{ shrink: true }}
            {...register('sub_title')}
            error={errors.sub_title ? true : false}
            helperText={errors.sub_title ? String(errors.sub_title.message) : null}
          />
          <TextField
            required
            id='icon_name'
            label='Link do ícone'
            fullWidth
            size='small'
            margin='normal'
            InputLabelProps={{ shrink: true }}
            {...register('icon_name')}
            error={errors.icon_name ? true : false}
            helperText={errors.icon_name ? String(errors.icon_name.message) : null}
          />
          <TextField
            required
            id='order'
            label='Ordem'
            type='number'
            fullWidth
            size='small'
            margin='normal'
            InputLabelProps={{ shrink: true }}
            {...register('order')}
            error={errors.order ? true : false}
            helperText={errors.order ? String(errors.order.message) : null}
          />
          <Typography>Menus</Typography>
          {fields.map((item, index) => (
            <Box key={item.id} sx={{ width: '100%' }} display='flex' alignItems='center'>
              <Box paddingX={2} sx={{ width: '100%' }}>
                {menus && (
                  <Controller
                    name={`menus.${index}`}
                    control={control}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <FormControl fullWidth error={errors?.menus?.[index]?._id ? true : false}>
                          <Autocomplete
                            id={`menus.${index}`}
                            size='small'
                            defaultValue={value}
                            options={menus
                              .filter((menu) => menu.active)
                              .map((menu) => {
                                return {
                                  _id: menu._id,
                                  label: menu.name,
                                };
                              }, [])}
                            loadingText='Carregando...'
                            getOptionLabel={(option) => option?.label ?? ''}
                            onChange={(_, option) => {
                              onChange(option);
                            }}
                            ListboxProps={{
                              style: {
                                maxHeight: '250px',
                              },
                            }}
                            isOptionEqualToValue={(option, optionValue) =>
                              option?._id === optionValue?._id
                            }
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            renderOption={(props, option: any) => {
                              return <li {...props}>{option.label}</li>;
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                required
                                label={`Menu ${index + 1}`}
                                fullWidth
                                size='small'
                                margin='normal'
                                InputLabelProps={{ shrink: true }}
                                error={errors?.menus?.[index] ? true : false}
                                helperText={
                                  errors?.menus?.[index]
                                    ? String(errors?.menus?.[index]?.message)
                                    : null
                                }
                              />
                            )}
                          />
                        </FormControl>
                      );
                    }}
                  />
                )}
              </Box>
              <IconButton aria-label='delete' onClick={() => remove(index)}>
                <Delete color={'primary'} />
              </IconButton>
            </Box>
          ))}
          <Box>
            <Button
              onClick={() => {
                append({ _id: '' });
              }}
            >
              <Add color={'primary'} /> Adicionar Item
            </Button>
          </Box>
          <Controller
            control={control}
            name='is_showcase'
            render={({ field: { onChange, value } }) => (
              <FormControlLabel
                control={
                  <Switch
                    onChange={onChange}
                    checked={value ?? false}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                label='Vitrine'
              />
            )}
          />
          <Controller
            control={control}
            name='active'
            render={({ field: { onChange, value } }) => (
              <FormControlLabel
                control={
                  <Switch
                    onChange={onChange}
                    checked={value ?? false}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                label='Ativo'
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelModal} color='secondary'>
            Cancelar
          </Button>
          <Button onClick={handleSubmit(handleSubmitForm)} color='primary'>
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Category;
