import { styled } from '@mui/material/styles';
import { Box, Button, Typography } from '@mui/material';
import TaskAltIcon from '@mui/icons-material/TaskAlt';

export const Container = styled(Box)(() => ({
  paddingTop: '50px',
}));

interface SizePropsI {
  isMobile: boolean;
}

export const ImpactText = styled(Typography)(({ theme }) => ({
  color: theme.palette.black.main,
  fontWeight: '700',
  marginBottom: 10,
}));

export const StyledImg = styled('img')<{ size: SizePropsI }>(({ theme, size }) => ({
  width: '100%',
  height: size.isMobile ? '40vh' : '70vh',
  objectFit: 'cover',
  objectPosition: 'right',
  backgroundColor: theme.palette.creamBg.main
}));

export const PopImage = styled('img')<{ size: SizePropsI }>(({ size }) => ({
  width: size.isMobile ? '50%' : '70%',
}));

export const ImgBackground = styled(Box)<{ size: SizePropsI }>(({theme, size}) => ({
  backgroundColor: theme.palette.creamBg.main,
  paddingLeft: size.isMobile ? 0 : '5%',
  paddingRight: size.isMobile ? 0 : '5%',
  paddingTop: size.isMobile? 0 : '10%',
  paddingBottom: size.isMobile? 0 : '10%',
  position: 'relative',
}))

export const StyledButton = styled(Button)(({ theme }) => ({
  color: theme.palette.white.main,
  background: theme.palette.primary.main,
  borderRadius: '10px',
  fontWeight: '700',
  width: '200px',
  height: '50px',
  margin: '30px 0px',
  ':hover': {
    color: theme.palette.primary.main
  }
}));

export const CheckedIcon = styled(TaskAltIcon)(() => ({
  fontSize: '20px',
  marginBottom: '-5px'
}));