import { CategoryT } from '../../app/api/categoryAPI';

import { CarouselWrapper, CarouselItem, ItemIcon, ItemText } from './categoryCarousel.styles';

type CategoryCarouselPropsT = {
  items: CategoryT[];
  onItemClick: (itemId?: string, active?: boolean) => void;
  itemActiveId?: string;
};

const CategoryCarousel = ({ items, onItemClick, itemActiveId }: CategoryCarouselPropsT) => {
  return (
    <CarouselWrapper>
      {items.map((item) => (
        <CarouselItem
          key={item.title}
          onClick={() => {
            onItemClick(item._id, !(itemActiveId === item._id));
          }}
          active={{ isActive: itemActiveId ? Boolean(itemActiveId === item._id) : false }}
        >
          <ItemIcon src={item.icon_name} alt='Logo' />
          <ItemText variant='subtitle2' textAlign='center'>
            {item.title}
          </ItemText>
        </CarouselItem>
      ))}
    </CarouselWrapper>
  );
};

export default CategoryCarousel;
