import Cookies from 'universal-cookie';

export interface LocationCookieT {
  latitude: string;
  longitute: string;
  address: string;
}

export const setLocationCookie = (location: LocationCookieT) => {
  const cookies = new Cookies();
  cookies.set('abouteat_latitude', location.latitude, {
    path: '/',
    expires: new Date(Date.now() + 2592000000),
  });
  cookies.set('abouteat_longitute', location.longitute, {
    path: '/',
    expires: new Date(Date.now() + 2592000000),
  });
  cookies.set('abouteat_address', location.address, {
    path: '/',
    expires: new Date(Date.now() + 2592000000),
  });
};

export const getLocationCookie = (): LocationCookieT | undefined => {
  const cookies = new Cookies();
  let locationCookie: LocationCookieT | undefined = undefined;
  const latitude = cookies.get('abouteat_latitude');
  const longitute = cookies.get('abouteat_longitute');
  const address = cookies.get('abouteat_address');
  if (latitude && longitute && address) {
    locationCookie = { latitude, longitute, address };
    return locationCookie;
  }
  return locationCookie;
};

export const removeLocationCookie = () => {
  const cookies = new Cookies();
  cookies.remove('abouteat_latitude', { path: '/' });
  cookies.remove('abouteat_longitute', { path: '/' });
  cookies.remove('abouteat_address', { path: '/' });
};
