import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Container = styled('section')(({ theme }) => ({
  padding: '16px',
  border: `2px dashed ${theme.palette.muiBorder.main}`,
  width: '100%',
  display: 'inline-block',
  borderRadius: '4px',
  margin: '8px 0px 4px',
  cursor: 'pointer',
}));

export const Label = styled(Typography)(({ theme }) => ({
  color: theme.palette.muiInputText.main,
}));

export const ThumbsContainer = styled('aside')(() => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: '16px',
}));

export const Thumb = styled('div')(({ theme }) => ({
  display: 'inline-flex',
  border: `1px solid ${theme.palette.muiBorder.main}`,
  borderRadius: '4px',
  marginBottom: '8px',
  marginRight: '8px',
  width: '100px',
  height: '100px',
  padding: '4px',
  boxSizing: 'border-box',
}));

export const ThumbInner = styled('div')(() => ({
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden',
}));

export const Img = styled('img')(() => ({
  display: 'block',
  width: 'auto',
  height: '100%',
}));
