import React from 'react';

import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { StarOutlineRounded, StarRate } from '@mui/icons-material';
import {
  Avatar,
  Box,
  Grid,
  Typography,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import { useGetMenuByIdQuery } from '../../app/api/menuAPI';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { setToastNotification } from '../../app/slices/appSlice';
import {
  Container,
  CarouselSlide,
  StyledMenuImg,
  TitleContainer,
  FavoriteIconButton,
} from './booking.styles';
import { BookingMenuConfig, BookingCheckout } from '../../components';
import { theme } from '../../theme';
import { CheckoutFormT } from '../../components/BookingCheckout';
import { SchedulingPostT, useCreateSchedulingMutation } from '../../app/api/schedulingAPI';
import { useGetChefByIdQuery } from '../../app/api/chefAPI';
import { LocationT } from '../../components/GoogleAutoComplete';
import { getLocationCookie, removeLocationCookie } from '../../app/slices/locationSlice';
import { getMaxNumberOfGuests } from '../../utils/pricesByNumberFunctions';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteOutlinedIcon from '@mui/icons-material/FavoriteOutlined';
import {
  useCreateFavoriteMutation,
  useGetFavoritesQuery,
  useRemoveFavoriteMutation,
} from '../../app/api/favoriteAPI';
import { getToken } from '../../app/slices/userSlice';

export type OptionItemT = {
  option_item_id: string;
  option_item_name: string;
  option_item_value?: number;
  selected_quantity: number;
  config_id: string;
  config_min_quantity: number;
};

export type OptionT = {
  option_id: string;
  option_items: OptionItemT[];
};

function Booking() {
  const navigate = useNavigate();
  const locationRouter = useLocation();
  const { menuId } = useParams();
  const dispatch = useAppDispatch();
  const token = useAppSelector(getToken);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [createScheduling, { data: schedulingData, isSuccess: isSuccessCreate }] =
    useCreateSchedulingMutation();
  const { data: menu } = useGetMenuByIdQuery(menuId);
  const { data: chef } = useGetChefByIdQuery(menu?.chef_user_id?._id, {
    skip: !menu?.chef_user_id?._id,
  });
  const { data: favorites } = useGetFavoritesQuery(undefined, {
    skip: !token,
  });
  const [createFavorite, { isSuccess: isSuccessCreateFavorite }] = useCreateFavoriteMutation();
  const [removeFavorite, { isSuccess: isSuccessRemoveFavorite }] = useRemoveFavoriteMutation();

  const dietaryConfig = menu?.configurations.find((cf) => cf.dietary_requirements === true);
  const pricesPerPersonSorted = menu?.prices_per_person;

  const carouselImages = menu
    ? menu?.album_pics?.length
      ? // eslint-disable-next-line no-unsafe-optional-chaining
        [menu.picture_url, ...menu?.album_pics]
      : [menu?.picture_url]
    : [];
  const [optionsSelection, setOptionsSelection] = React.useState<OptionT[]>([]);
  const [latitude, setLatitude] = React.useState<number | undefined>();
  const [longitute, setLongitute] = React.useState<number | undefined>();
  const [address, setAddress] = React.useState<string | undefined>();
  const [isAlmbumOpen, setIsAlmbumOpen] = React.useState<boolean>(false);
  const [confirmModalData, setConfirmModalData] = React.useState<
    { checkout: CheckoutFormT; location: LocationT } | undefined
  >(undefined);

  React.useEffect(() => {
    const locationCookies = getLocationCookie();
    if (locationCookies) {
      setLatitude(parseFloat(locationCookies.latitude || '') || undefined);
      setLongitute(parseFloat(locationCookies.longitute || '') || undefined);
      setAddress(locationCookies.address || undefined);
    }
  }, []);

  React.useEffect(() => {
    if (isSuccessCreate) {
      navigate('/payment', { state: { from: locationRouter.pathname } });
      removeLocationCookie();
      dispatch(
        setToastNotification({
          type: 'success',
          open: true,
          message: 'Agendamento enviado, agora é só aguardar o pagamento ser efetivado!',
        }),
      );
      if (schedulingData?.payment_url) {
        window.location.href = schedulingData?.payment_url;
      }
    }
  }, [isSuccessCreate]);

  React.useEffect(() => {
    if (isSuccessCreateFavorite) {
      dispatch(
        setToastNotification({
          type: 'success',
          open: true,
          message: 'Menu adicionado aos favoritos com sucesso!',
        }),
      );
    }
    if (isSuccessRemoveFavorite) {
      dispatch(
        setToastNotification({
          type: 'success',
          open: true,
          message: 'Menu removido dos favoritos com sucesso!',
        }),
      );
    }
  }, [isSuccessCreateFavorite, isSuccessRemoveFavorite]);

  const handleSelection = (option: OptionItemT, configId: string) => {
    const config = optionsSelection?.find((os) => os.option_id === configId);
    if (config) {
      if (config.option_items.find((oi) => oi.option_item_id === option.option_item_id)) {
        const newSelection = [];
        for (let index = 0; index < optionsSelection.length; index++) {
          const ps = optionsSelection[index];
          if (ps.option_id === configId) {
            newSelection.push({
              ...ps,
              option_items: ps.option_items
                .map((psoi) =>
                  psoi.option_item_id === option.option_item_id
                    ? { ...psoi, selected_quantity: option.selected_quantity }
                    : psoi,
                )
                .filter((pf) => pf.selected_quantity > 0),
            });
          } else {
            newSelection.push(ps);
          }
        }
        setOptionsSelection(newSelection.filter((ns) => ns.option_items.length > 0));
      } else {
        const newSelection = [];
        for (let index = 0; index < optionsSelection.length; index++) {
          const ps = optionsSelection[index];
          if (ps.option_id === configId) {
            newSelection.push({ ...ps, option_items: [...ps.option_items, option] });
          } else {
            newSelection.push(ps);
          }
        }
        setOptionsSelection(newSelection.filter((ns) => ns.option_items.length > 0));
      }
    } else {
      setOptionsSelection([...optionsSelection, { option_id: configId, option_items: [option] }]);
    }
  };

  const handleSubmitBooking = (checkout: CheckoutFormT, location: LocationT) => {
    if (!menuId) {
      return;
    }

    const options = [];
    for (let index = 0; index < optionsSelection.length; index++) {
      const option = optionsSelection[index];
      options.push({
        option_id: option.option_id,
        option_items: option.option_items.map(({ option_item_id, selected_quantity }) => ({
          option_item_id,
          selected_quantity,
        })),
      });
    }
    const parsedObj: SchedulingPostT = {
      menu_id: menuId,
      event_date: `${checkout.date} ${checkout.time}`,
      address: checkout.address,
      address_complement: checkout.address_complement,
      latitude: String(location.lat),
      longitude: String(location.long),
      observation: checkout.observation,
      number_of_guests: checkout.number_of_guests,
      options,
      coupon_id: checkout.coupon_id,
    };
    if (!checkout.observation) delete parsedObj.observation;
    if (!checkout.number_of_guests) delete parsedObj.number_of_guests;
    if (!checkout.address_complement) delete parsedObj.address_complement;
    createScheduling(parsedObj);
  };

  const handleFavoriteClick = (menuId?: string) => {
    if (!menuId) {
      return;
    }
    if (token) {
      if (favorites?.menus?.find((menu) => menu === menuId)) {
        removeFavorite({ menu_id: menuId });
      } else {
        createFavorite({ menu_id: menuId });
      }
    } else {
      dispatch(
        setToastNotification({
          type: 'warning',
          open: true,
          message: 'Para favoritar um menu é preciso estar logado!',
        }),
      );
      navigate(`/sign-in/booking/${menuId}`);
    }
  };

  return (
    <Container size={{ isMobile: Boolean(isMobile) }}>
      <Grid container>
        {carouselImages.length ? (
          <Grid item xs={12} md={12}>
            <Carousel
              onClickItem={() => setIsAlmbumOpen(true)}
              showThumbs={false}
              autoPlay={true}
              infiniteLoop={true}
              showStatus={false}
            >
              {carouselImages?.map((ap) => (
                <CarouselSlide key={ap} size={{ isMobile: Boolean(isMobile) }}>
                  <StyledMenuImg size={{ isMobile: Boolean(isMobile) }} src={ap} />
                </CarouselSlide>
              ))}
            </Carousel>
          </Grid>
        ) : null}
        <Grid item xs={15} display='flex' justifyContent='center' marginTop={1} gap={1}>
          <Typography
            variant='h4'
            color={menu?.is_catering ? 'secondary' : 'primary'}
            fontWeight='bold'
            textAlign='center'
          >
            {menu?.name}
          </Typography>
          <FavoriteIconButton
            aria-label='favorite'
            onClick={() => {
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              handleFavoriteClick(menuId!);
            }}
          >
            {favorites?.menus?.find((el) => {
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              return el === menuId!;
            }) ? (
              <FavoriteOutlinedIcon color={'primary'} />
            ) : (
              <FavoriteBorderIcon color={'primary'} />
            )}
          </FavoriteIconButton>
        </Grid>
        <Grid item xs={12} display='flex' justifyContent='center' marginBottom={1}>
          <Typography textAlign='center'>{menu?.description}</Typography>
        </Grid>
        <Grid paddingX={3} item xs={12} md={7} mt={2}>
          {menu?.configurations
            ?.filter((cf) => cf.dietary_requirements === false)
            ?.map((con) => (
              <BookingMenuConfig
                key={con._id}
                configuration={con}
                isCatering={menu.is_catering}
                option_items={
                  optionsSelection?.find((os) => os.option_id === con._id)?.option_items || []
                }
                setSelectedOption={(option: OptionItemT) => handleSelection(option, con._id)}
              />
            ))}
        </Grid>
        <Grid paddingX={3} item xs={12} md={5} mt={2} paddingLeft={0} paddingRight={0}>
          {menu && (
            <BookingCheckout
              isCatering={menu?.is_catering}
              chefId={menu?.chef_user_id?.chef_id}
              menuId={menuId || ''}
              configurations={menu.configurations}
              deliveryRate={menu.delivery_rate}
              maxPerson={getMaxNumberOfGuests(menu.prices_per_person)}
              minPerson={menu.min_person}
              menuPrices={
                Array.isArray(pricesPerPersonSorted)
                  ? pricesPerPersonSorted
                      ?.slice()
                      .sort((a, b) => Number(a.number_of_guests) - Number(b.number_of_guests))
                  : undefined
              }
              latParam={latitude}
              longParam={longitute}
              addressParam={address}
              option_items={
                optionsSelection?.find((os) => os.option_id === dietaryConfig?._id)?.option_items ||
                []
              }
              setSelectedOption={(option: OptionItemT) =>
                dietaryConfig && handleSelection(option, dietaryConfig?._id)
              }
              selectedOptions={optionsSelection}
              onSubmit={(checkout: CheckoutFormT, location: LocationT) =>
                setConfirmModalData({ checkout, location })
              }
            />
          )}
        </Grid>
        {chef && (
          <Grid item xs={12} md={7} mt={2} paddingX={3}>
            <TitleContainer>
              <Typography
                variant='h5'
                fontWeight='bold'
                color={menu?.is_catering ? 'secondary' : 'primary'}
              >
                Quem prepara
              </Typography>
            </TitleContainer>
            <Box display='flex' alignItems='center' mb={2}>
              <Avatar
                title={chef?.name}
                alt={`Logo chef ${chef?.name}`}
                src={chef?.chef_id?.picture_url}
                sx={{ width: 80, height: 80 }}
              >
                {chef?.name?.charAt(0)}
              </Avatar>
              <Box ml={2}>
                <Typography variant='h6' color={menu?.is_catering ? 'secondary' : 'primary'}>
                  {chef?.name}
                </Typography>
                <Typography>{chef?.chef_id?.bio}</Typography>
              </Box>
            </Box>
            <TitleContainer>
              <Typography
                variant='h5'
                fontWeight='bold'
                marginRight={1}
                color={menu?.is_catering ? 'secondary' : 'primary'}
              >
                Avaliações
              </Typography>
              <StarRate fontSize='medium' />
              <Typography variant='h6'>
                {chef &&
                chef.chef_id &&
                chef.chef_id.evaluation_summary &&
                chef.chef_id.evaluation_summary.total_evaluation > 0 &&
                chef.scheduling_evaluations.total > 0
                  ? (
                      chef?.chef_id?.evaluation_summary.total_evaluation /
                      chef?.scheduling_evaluations?.total
                    ).toFixed(1)
                  : '-'}
              </Typography>
              <Typography variant='h6' marginLeft={1}>
                {chef?.scheduling_evaluations?.preview?.length &&
                  ` (${chef?.scheduling_evaluations?.preview?.length} coment.)`}
              </Typography>
            </TitleContainer>
            {chef?.scheduling_evaluations?.preview.map((item) => {
              return (
                <Box
                  key={Math.random()}
                  display='flex'
                  flexDirection='row'
                  mb={1}
                  mt={1}
                  minWidth={300}
                >
                  <Box
                    display='flex'
                    alignItems='center'
                    justifyContent='flex-start'
                    flexDirection='column'
                    flexWrap='wrap'
                  >
                    <Avatar alt='User Avatar' sx={{ width: 45, height: 45 }}>
                      {item.client_id.name.charAt(0)}
                    </Avatar>
                    <Box display='flex' alignItems='center'>
                      <Typography color='primary' variant='body1' fontWeight='bold'>
                        {item.evaluation}
                      </Typography>
                      <StarOutlineRounded color='error' fontSize='small' titleAccess='Teste' />
                    </Box>
                  </Box>

                  <Box ml={2} display='flex' alignItems='baseline'>
                    <Typography fontWeight='bold'>{item.client_id.name}</Typography>
                    {item.comment && (
                      <Typography variant='caption' flexWrap={'wrap'}>
                        : {item?.comment?.substring(0, 300)}
                      </Typography>
                    )}
                  </Box>
                </Box>
              );
            })}
          </Grid>
        )}
      </Grid>
      {carouselImages.length > 0 && isAlmbumOpen && (
        <Dialog
          open={isAlmbumOpen}
          onClose={() => {
            setIsAlmbumOpen(false);
          }}
        >
          <Carousel dynamicHeight={true} showThumbs={false} autoPlay={true} infiniteLoop={true}>
            {carouselImages?.map((ap) => (
              <Box key={ap}>
                <img src={ap} />
              </Box>
            ))}
          </Carousel>
        </Dialog>
      )}
      <Dialog
        open={Boolean(confirmModalData)}
        onClose={() => {
          setConfirmModalData(undefined);
        }}
        aria-labelledby='confirm-dialog'
        aria-describedby='confirm-dialog'
      >
        <DialogTitle id='confirm-dialog-title'>Revise seu pedido</DialogTitle>
        <DialogContent>
          <DialogContentText id='confirm-dialog-description'>
            Lembre-se de revisar cada detalhe do seu pedido antes de agendar ou realizar o
            pagamento. Para realizar alterações posteriormente, será necessário cancelar o
            agendamento e solicita-lo novamente.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmModalData(undefined)} color='secondary'>
            Fechar
          </Button>
          <Button
            onClick={() =>
              confirmModalData &&
              handleSubmitBooking(confirmModalData?.checkout, confirmModalData?.location)
            }
            autoFocus
          >
            Agendar
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default Booking;
