import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControlLabel,
  Switch,
} from '@mui/material';

import { CRUDTemplate } from '../../../components';
import { setToastNotification } from '../../../app/slices/appSlice';
import { useAppDispatch } from '../../../app/hooks';
import { useGetContactsQuery, useUpdateContactMutation } from '../../../app/api/contactAPI';

export type ContactFormT = {
  _id?: string;
  name: string;
  email: string;
  message: string;
  read?: boolean;
  created_at: Date;
  updated_at?: Date;
};

function Contacts() {
  const dispatch = useAppDispatch();
  const { data: contacts } = useGetContactsQuery(undefined);
  const [updateContact, { isSuccess: isSuccessUpdate }] = useUpdateContactMutation();
  const [contactToEdit, setContactToEdit] = React.useState<ContactFormT | undefined>(undefined);
  const [isModalOpen, setModalOpen] = React.useState<boolean>(false);

  const resetFields = () => {
    reset({
      read: false,
    });
  };

  React.useEffect(() => {
    if (isSuccessUpdate) {
      setModalOpen(false);
      resetFields();
      dispatch(
        setToastNotification({
          type: 'success',
          open: true,
          message: 'Solicitação de contato atualizado com sucesso!',
        }),
      );
    }
  }, [isSuccessUpdate]);

  const validationSchema = Yup.object().shape({
    read: Yup.bool(),
  });

  const { handleSubmit, reset } = useForm<ContactFormT>({
    resolver: yupResolver(validationSchema),
  });

  const handleEditClick = (id: string) => {
    const selectedContact = contacts?.find((c) => c._id === id);
    if (selectedContact) {
      reset(selectedContact);
      setContactToEdit({
        _id: selectedContact._id,
        name: selectedContact.name,
        email: selectedContact.email,
        message: selectedContact.message,
        read: selectedContact.read,
        created_at: selectedContact.created_at,
        updated_at: selectedContact.updated_at,
      } as ContactFormT);
      setModalOpen(true);
    }
  };

  const handleCancelModal = () => {
    setModalOpen(false);
    setContactToEdit(undefined);
    resetFields();
  };

  const handleSubmitForm = () => {
    if (contactToEdit) {
      updateContact(contactToEdit);
    }
  };

  return (
    <>
      <CRUDTemplate
        title='Solicitações de contato'
        hasAdd={false}
        onAddClick={() => setModalOpen(true)}
        onEditClick={(id: string) => handleEditClick(id)}
        hasEdit={true}
        columns={[
          {
            field: 'read',
            headerName: 'Lido?',
            type: 'boolean',
            maxWidth: 100,
          },
          {
            field: 'name',
            headerName: 'Nome do cliente',
          },
          {
            field: 'email',
            headerName: 'Email do cliente',
          },
          {
            field: 'message',
            headerName: 'Mensagem enviada',
          },
          {
            field: 'created_at',
            renderCell: (cellValue) => new Date(cellValue.value).toLocaleString(),
            headerName: 'Data do envio do contato',
          },
        ]}
        rows={contacts || []}
      />
      <Dialog
        open={isModalOpen}
        onClose={(event: object, reason: string) => {
          if (reason !== 'backdropClick') {
            handleCancelModal();
          }
        }}
      >
        <DialogTitle>Solicitação de contato</DialogTitle>
        <DialogContent>
          <FormControlLabel
            control={
              <Switch
                onChange={() => {
                  setContactToEdit({
                    ...contactToEdit,
                    read: !(contactToEdit?.read ?? false),
                  } as ContactFormT);
                }}
                checked={contactToEdit?.read ?? false}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            label='Lido?'
          />
          <TextField
            disabled
            id='name'
            label='Nome do cliente'
            fullWidth
            size='small'
            margin='normal'
            InputLabelProps={{ shrink: true }}
            value={contactToEdit?.name}
          />
          <TextField
            disabled
            id='email'
            label='Email do cliente'
            fullWidth
            size='small'
            margin='normal'
            InputLabelProps={{ shrink: true }}
            value={contactToEdit?.email}
          />
          <TextField
            disabled
            id='created_at'
            label='Data do envio'
            fullWidth
            size='small'
            margin='normal'
            InputLabelProps={{ shrink: true }}
            value={contactToEdit ? new Date(contactToEdit.created_at).toLocaleString() : ''}
          />
          <TextField
            disabled
            id='message'
            label='Mensagem enviada'
            multiline
            maxRows={4}
            minRows={2}
            fullWidth
            size='small'
            margin='normal'
            InputLabelProps={{ shrink: true }}
            value={contactToEdit?.message}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelModal} color='secondary'>
            Cancelar
          </Button>
          <Button onClick={handleSubmit(handleSubmitForm)} color='primary'>
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Contacts;
