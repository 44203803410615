import React from 'react';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { TextField, Button, Avatar } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { setToastNotification } from '../../../app/slices/appSlice';
import { Container, FormContainer } from './AdminProfile.styles';
import { getMe } from '../../../app/slices/userSlice';
import { useChangePasswordMutation } from '../../../app/api/userAPI';

export type UpdateteAdminFormT = {
  name: string;
  email: string;
  password: string;
};

function AdminProfile() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const me = useAppSelector(getMe);
  const [changePassword, { isSuccess, isLoading }] = useChangePasswordMutation();

  React.useEffect(() => {
    reset({ name: me?.name, email: me?.email, password: undefined });
  }, [me]);

  React.useEffect(() => {
    if (isSuccess) {
      reset({ name: me?.name, email: me?.email, password: undefined });
      dispatch(
        setToastNotification({
          type: 'success',
          open: true,
          message: 'Senha alterada com sucesso!',
        }),
      );
    }
  }, [isSuccess]);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Nome é obrigatório'),
    email: Yup.string().required('Email é obrigatório').email('Email inválido'),
    password: Yup.string().required('Senha é obrigatória'),
  });

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
  } = useForm<UpdateteAdminFormT>({
    defaultValues: me ? { name: me?.name, email: me?.email, password: undefined } : {},
    resolver: yupResolver(validationSchema),
  });

  const handleSubmitForm = (formValues: UpdateteAdminFormT) => {
    changePassword({ password: formValues.password });
  };

  const handleNavigateBack = () => navigate(-1);

  return (
    <Container>
      <FormContainer elevation={7}>
        <Avatar alt='User Avatar' sx={{ width: 80, height: 80, marginBottom: 2 }}>
          {me?.name?.charAt(0)}
        </Avatar>
        <TextField
          disabled
          id='name'
          label='Nome'
          fullWidth
          size='small'
          margin='normal'
          InputLabelProps={{ shrink: true }}
          defaultValue={getValues('name')}
          autoComplete='off'
        />
        <TextField
          disabled
          id='email'
          label='Email'
          fullWidth
          size='small'
          margin='normal'
          InputLabelProps={{ shrink: true }}
          defaultValue={me?.email}
          {...register('email')}
          error={errors.email ? true : false}
          helperText={errors.email ? String(errors.email.message) : null}
        />

        <TextField
          required
          id='password'
          label='Senha'
          type='password'
          fullWidth
          size='small'
          margin='normal'
          InputLabelProps={{ shrink: true }}
          autoComplete='off'
          inputProps={{
            autocomplete: 'new-password',
            form: {
              autocomplete: 'off',
            },
          }}
          {...register('password')}
          error={errors.password ? true : false}
          helperText={errors.password ? String(errors.password.message) : null}
        />
        {isLoading ? (
          <LoadingButton
            size='large'
            sx={{ marginTop: '15px' }}
            loading
            variant='contained'
            color='primary'
            fullWidth
          >
            Atualizar
          </LoadingButton>
        ) : (
          <Button
            size='large'
            sx={{ marginTop: '15px' }}
            variant='contained'
            color='primary'
            onClick={handleSubmit(handleSubmitForm)}
            fullWidth
          >
            Atualizar
          </Button>
        )}
        <Button
          size='large'
          sx={{ marginTop: '15px' }}
          variant='text'
          color='secondary'
          onClick={handleNavigateBack}
          fullWidth
        >
          Voltar
        </Button>
      </FormContainer>
    </Container>
  );
}

export default AdminProfile;
