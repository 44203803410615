import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

interface SizePropsI {
  isMobile: boolean;
}

export const Container = styled(Box)<{ size: SizePropsI }>(({ size }) => ({
  padding: size.isMobile ? '100px 30px 120px' : '100px 100px 120px',
}));

export const FilterContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  marginBottom: '50px',
  borderBottom: `1px solid ${theme.palette.muiBorder.main}`,
  padding: '40px 0px',
}));

export const ChartContainer = styled(Box)(({ theme }) => ({
  borderRadius: '4px',
  padding: '20px',
  height: 'auto',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  position: 'relative',
  margin: '20px',
  border: `1px solid ${theme.palette.muiBorder.main}`,
}));
