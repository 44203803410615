export const chefQuestions = [
  {
    question: 'O que está incluso no agendamento',
    answer:
      'O chef será responsável pela compra dos ingredientes, preparar, servir e depois deixar a cozinha limpa.',
  },
  {
    question: 'Tempo de duração',
    answer:
      'O horário selecionado no agendamento é o horário em que são servidas as refeições, o chef chegará com a antecedência necessária para servir no horário que foi selecionado no agendamento. A duração de seu agendamento pode variar entre 2 horas a 4 horas.',
  },
  {
    question: 'Forma de pagamento',
    answer:
      'Você não precisa se preocupar com o pagamento no dia de seu evento, esse momento será apenas de aproveitar! O pagamento é realizado no momento do agendamento e pode ser concluído de maneira segura via cartão de crédito ou depósito bancário.',
  },
  {
    question: 'O que é preciso de infra-estrutura',
    answer:
      'O chef precisará dos equipamentos básicos para preparar seu menu, será necessário fogão, geladeira e forno. Alguns utensílios mais específicos fazem parte do que levaremos para o evento e outros podem ser de sua responsabilidade. Junto da confirmação do agendamento, notificaremos caso exista algum utensílio que deva providenciar para a execução do menu pelo chef.',
  },
  {
    question: 'Pratos, talheres, copos, etc',
    answer:
      'O chef não levará pratos, talheres e louças, portanto é necessário que no local possuam o suficiente de acordo com o número de convidados para que todos estejam bem servidos. Disponibilizamos opções de aluguel de louças no agendamento para otimizar a sua experiência.',
  },
  {
    question: 'Preparação da mesa',
    answer:
      'O chef não põe a mesa. Idealmente, isso é feito antes da chegada do chef, para que você não precise interrompê-lo durante a preparação e para que você possa decorar a mesa de acordo com as suas preferências.',
  },
  {
    question: 'Quantidade de comida',
    answer:
      'Os menus são elaborados para atender com folga cada pessoa - cada prato possui suas particularidades, alguns são mais leves outros mais encorpados, tudo depende da proposta do evento. É importante a seleção correta do número de participantes, para que não faltem ingredientes e todos fiquem satisfeitos.',
  },
  {
    question: 'Preciso aumentar o número de convidados e/ou pratos. Como posso fazer isso?',
    answer:
      'Você pode fazer isso se a sua reserva estiver a mais de 72 horas, basta entrar em contato conosco para adequarmos a sua experiência.',
  },
  {
    question: 'Preciso reduzir o número de convidados. Como proceder?',
    answer:
      'Se você deseja reduzir um convidado de sua reserva, entre em contato conosco para adequarmos a sua experiência. Se o seu evento estiver a mais de 6 dias, você terá direito a um reembolso para o(s) convidado(s) removido(s). Se faltarem 6 a 4 dias para o seu evento, você terá direito a um reembolso de crédito para o convidado removido. Se faltarem menos de 72 horas para o seu evento, não oferecemos nenhum reembolso em dinheiro ou crédito.',
  },
  {
    question: 'Política de cancelamento',
    answer:
      'Após um agendamento, o mesmo pode ser cancelado com total reembolso em até 2 horas, após esse limite será estabelecida a política abaixo. Se faltarem mais de 6 dias para o seu evento, ofereceremos um reembolso total. Se houver entre 6 a 4 dias restantes para o seu evento, você tem direito ao reembolso de 50% do valor do pedido. Se o seu evento estiver a menos de 72 horas, não oferecemos reembolso.',
  },
  {
    question: 'O que acontece se um chef cancelar minha reserva?',
    answer:
      'No caso de o chef cancelar a sua reserva, procuraremos encontrar outro chef com um menu semelhante e que seja de seu agrado. Caso não possamos providenciar outro chef, você será elegível para um reembolso de 100% e ainda poderá reagendar a reserva sem custos, ofereceremos 110% do valor do pedido como crédito.',
  },
];

export const cateringQuestions = [
  {
    question: 'Que tipos de pagamento são aceitos?',
    answer:
      'Aceitamos todos os principais cartões de crédito (Visa, Mastercard, American Express, Discover) e também boleto bancário. Utilizamos a plataforma do MercadoPago para segurança e conforto.',
  },
  {
    question: 'Quando sou cobrado pelo pedido?',
    answer:
      'Autorizamos e processamos o pagamento quando você faz um pedido, assim no dia do evento você não precisará se preocupar com nada, será necessário apenas receber a sua encomenda. Se você tiver de cancelar um pedido dentro do prazo estabelecido pelo estabelecimento escolhido, entre em contato com a equipe Abouteat para que possamos estornar o valor pago.',
  },
  {
    question: 'Existe alguma taxa de cancelamento?',
    answer:
      'Não de nós, embora alguns restaurantes possam cobrar por cancelamentos de última hora. Verifique o menu do restaurante na Abouteat antes de concluir o seu pedido para consultar uma política de cancelamento (nem todos os restaurantes listam uma). Precisando de suporte, entre em contato com a equipe Abouteat!',
  },
  {
    question: 'Posso alterar a minha encomenda?',
    answer:
      'É possível fazer alterações em sua encomenda, embora os restaurantes precisem de tempo suficiente para acomodar suas alterações. Para isso, entre em contato com a equipe Abouteat o quanto antes informando as alterações que deseja realizar para que possamos consultar o restaurante se terá tempo hábil para efetuá-las e te atualizá-lo o mais breve possível.',
  },
  {
    question: 'Posso agendar os pedidos com antecedência?',
    answer:
      'Sim, tão organizado quanto você gostaria! Mantemos todos os seus pedidos organizados e enviamos lembretes para o restaurante.',
  },
  {
    question: 'Como adiciono instruções ou solicitações especiais?',
    answer:
      'Se for específico para um item do menu (sem azeitonas, molho ao lado etc.), clique no item ao fazer seu pedido e escolha Adicionar instruções especiais. Para outras solicitações, entre em contato conosco e coordenaremos com o restaurante para você.',
  },
  {
    question: 'Posso rastrear os meus pedidos?',
    answer:
      'Mantemos você informado por e-mail e texto à medida que seu pedido avança. Ainda não há rastreamento de entrega em tempo real, mas está em andamento!',
  },
  {
    question: 'Posso buscar em vez disso?',
    answer: 'É claro. Você pode mudar de entrega para retirada ao fazer o pedido:',
  },
  {
    question: 'Quanto custa a entrega?',
    answer:
      'Os restaurantes definem seus próprios preços de entrega. Elas serão mostradas dentro de cada menu de acordo com as preferências e quantidades escolhidas.',
  },
];
