import React from 'react';

import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { TextField, Button, RadioGroup, Radio, FormControlLabel } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useNavigate, useParams } from 'react-router-dom';

import { useLoginMutation } from '../../app/api/userAPI';
import Logo from '../../assets/images/LogoShort.png';
import { Container, FormContainer, StyledLogo } from './signin.styles';
import { useAppSelector } from '../../app/hooks';
import { getMe } from '../../app/slices/userSlice';

export type LoginFormT = {
  email: string;
  password: string;
  user_type: string;
};

function SignIn() {
  const navigate = useNavigate();
  const { userType, redirectTo } = useParams();
  const [login, { isLoading, isSuccess }] = useLoginMutation();
  const me = useAppSelector(getMe);

  React.useEffect(() => {
    if (isSuccess && me?.user_type) {
      navigate(
        redirectTo
          ? `${me?.user_type === 'admin' ? '/admin' : ''}/${redirectTo.replaceAll('_', '/')}`
          : '/',
      );
    }
  }, [isSuccess, me]);

  const validationSchema = Yup.object().shape({
    email: Yup.string().required('Email é obrigatório').email('Email inválido'),
    password: Yup.string().required('Senha é obrigatória'),
    user_type: Yup.string().required('"Entrar como" é obrigatório'),
  });

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<LoginFormT>({
    resolver: yupResolver(validationSchema),
  });

  const handleSubmitForm = (formValues: LoginFormT) => {
    login(formValues);
  };

  const handleNavigateBack = () => navigate(-1);

  const handleNavigateToSignUp = () => navigate('/sign-up');

  return (
    <Container>
      <FormContainer elevation={7}>
        <StyledLogo src={Logo} alt='Logo' />
        <TextField
          required
          id='email'
          label='Email'
          fullWidth
          size='small'
          margin='normal'
          InputLabelProps={{ shrink: true }}
          {...register('email')}
          error={errors.email ? true : false}
          helperText={errors.email ? String(errors.email.message) : null}
        />
        <TextField
          required
          id='password'
          label='Senha'
          type='password'
          fullWidth
          size='small'
          margin='normal'
          InputLabelProps={{ shrink: true }}
          {...register('password')}
          error={errors.password ? true : false}
          helperText={errors.password ? String(errors.password.message) : null}
        />
        <Controller
          name='user_type'
          defaultValue={userType === 'admin' ? 'admin' : 'client'}
          rules={{ required: true }}
          control={control}
          render={({ field }) => {
            if (userType === 'admin') {
              return (
                <RadioGroup row {...field}>
                  <FormControlLabel value='admin' control={<Radio />} label='Sou Administrador' />
                </RadioGroup>
              );
            }
            return (
              <RadioGroup row {...field}>
                <FormControlLabel value='client' control={<Radio />} label='Sou Cliente' />
                <FormControlLabel value='chef' control={<Radio />} label='Sou Chef' />
              </RadioGroup>
            );
          }}
        />

        {isLoading ? (
          <LoadingButton
            size='large'
            sx={{ marginTop: '15px' }}
            loading
            variant='contained'
            color='primary'
            fullWidth
          >
            Entrar
          </LoadingButton>
        ) : (
          <Button
            size='large'
            sx={{ marginTop: '15px' }}
            variant='contained'
            color='primary'
            onClick={handleSubmit(handleSubmitForm)}
            fullWidth
          >
            Entrar
          </Button>
        )}
        <Button
          size='large'
          sx={{ marginTop: '15px' }}
          variant='outlined'
          color='primary'
          onClick={handleNavigateToSignUp}
          fullWidth
        >
          Criar Conta
        </Button>
        <Button
          size='large'
          sx={{ marginTop: '15px' }}
          variant='text'
          color='secondary'
          onClick={handleNavigateBack}
          fullWidth
        >
          Cancelar
        </Button>
      </FormContainer>
    </Container>
  );
}

export default SignIn;
