import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Avatar,
  Box,
  Typography,
  IconButton,
  Input,
  FormControlLabel,
  Switch,
  Radio,
  RadioGroup,
} from '@mui/material';
import InputMask from 'react-input-mask';

import { CRUDTemplate } from '../../../components';
import {
  useCreateChefMutation,
  useGetChefsQuery,
  useUpdateChefMutation,
  useUploadImageMutation,
} from '../../../app/api/chefAPI';
import { setToastNotification } from '../../../app/slices/appSlice';
import { useAppDispatch } from '../../../app/hooks';
import { cnpjFormat, cpfFormat, phoneFormat } from '../../../utils/formats';
import GoogleAutoComplete, { LocationT } from '../../../components/GoogleAutoComplete';
import { formatBytes } from '../../../utils/formatBytes';

export type ChefFormT = {
  name: string;
  email: string;
  password?: string | undefined;
  phone: string;
  document: string;
  chef_type: string;
  bio: string;
  title: string;
  active?: boolean;
  bank_info: {
    pix?: string;
    bank_acc?: string;
    bank_agency?: string;
    bank_type?: string;
  };
  attendance_radius_km: number;
  address?: string;
};

function Chef() {
  const [location, setlocation] = React.useState<LocationT | undefined>();

  const dispatch = useAppDispatch();
  const { data: chefs } = useGetChefsQuery(undefined);
  const [createChef, { isSuccess: isSuccessCreate, data: chefCreated }] = useCreateChefMutation();
  const [updateChef, { isSuccess: isSuccessUpdate }] = useUpdateChefMutation();
  const [updateImage, { isSuccess: isSuccessPhoto }] = useUploadImageMutation();

  const [isModalOpen, setModalOpen] = React.useState<boolean>(false);
  const [chefToEdit, setChefToEdit] = React.useState<string | undefined>(undefined);

  const [picture, setPicture] = React.useState<string | undefined>(undefined);
  const [pictureFile, setPictureFile] = React.useState<File>();
  const [typeBank, setTypeBank] = React.useState('');

  const chefsMap = chefs?.map((item) => {
    return {
      ...item,
      ...item.chef_id,
    };
  });

  const defaultFields = {
    name: '',
    email: '',
    phone: '',
    document: '',
    chef_type: 'Pessoa Física',
    bio: '',
    title: '',
    bank_info: {
      pix: '',
      bank_acc: '',
      bank_agency: '',
      bank_type: '',
    },
    attendance_radius_km: 0,
    address: '',
    active: false,
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Nome é obrigatório'),
    email: Yup.string().required('Email é obrigatório'),
    phone: Yup.string().required('Celular é obrigatório'),
    password: Yup.lazy(() => {
      if (chefToEdit) {
        return Yup.string();
      } else {
        return Yup.string().required('Senha é obrigatória');
      }
    }),
    document: Yup.string().required('Documento é obrigatório'),
    chef_type: Yup.string().required('Tipo do Chef é obrigatório'),
    bio: Yup.string().required('Biografia é obrigatório'),
    title: Yup.string().required('Titulo é obrigatório'),
    active: Yup.boolean(),
    attendance_radius_km: Yup.number()
      .positive('Raio tem que ser maior que 0')
      .required('Raio de atendimento do chef é obrigatório')
      .typeError('Raio de atendimento do chef é obrigatório'),
    bank_info: Yup.lazy(() => {
      if (typeBank === 'Pix') {
        return Yup.object({
          pix: Yup.string().required('Pix é obrigatório'),
        });
      } else {
        return Yup.object({
          bank_acc: Yup.string().required('Conta é obrigatório'),
          bank_type: Yup.string().required('Banco é obrigatório'),
          bank_agency: Yup.string().required('Agencia é obrigatório'),
        });
      }
    }),
    address: Yup.string().required('Endereço é obrigatório'),
  });

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    control,
    watch,
    formState: { errors },
    reset,
  } = useForm<ChefFormT>({
    resolver: yupResolver(validationSchema),
  });

  const uploadPicture = () => {
    if (pictureFile) {
      const formData = new FormData();
      formData.append('picture', pictureFile);
      const _id = chefToEdit || chefCreated?._id;
      updateImage({ _id, picture: formData });
    }
  };

  React.useEffect(() => {
    if (isSuccessCreate) {
      if (pictureFile) {
        uploadPicture();
      } else {
        setModalOpen(false);
        defaultResetFields();
        dispatch(
          setToastNotification({
            type: 'success',
            open: true,
            message: 'Chef criado com sucesso!',
          }),
        );
      }
    }
  }, [isSuccessCreate]);

  React.useEffect(() => {
    if (isSuccessUpdate) {
      if (pictureFile) {
        uploadPicture();
      } else {
        setModalOpen(false);
        defaultResetFields();
        dispatch(
          setToastNotification({
            type: 'success',
            open: true,
            message: 'Chef atualizado com sucesso!',
          }),
        );
      }
    }
  }, [isSuccessUpdate]);

  React.useEffect(() => {
    if (isSuccessPhoto) {
      setModalOpen(false);
      defaultResetFields();
      dispatch(
        setToastNotification({
          type: 'success',
          open: true,
          message: chefToEdit ? 'Chef atualizado com sucesso!' : 'Chef criado com sucesso!',
        }),
      );
    }
  }, [isSuccessPhoto]);

  React.useEffect(() => {
    setTypeBank(getValues('bank_info')?.pix ? 'Pix' : 'Conta Bancária');
  }, [watch('bank_info')?.pix]);

  const defaultResetFields = () => {
    reset(defaultFields);
    setPicture(undefined);
    setTypeBank('');
  };

  const handleAddressSelection = (address?: string, location?: LocationT) => {
    if (address) {
      setValue('address', address);
    }
    if (location) {
      setlocation(location);
    }
  };

  React.useEffect(() => {
    if (chefToEdit) {
      const chef = chefsMap?.find((item) => item._id === chefToEdit);
      if (getValues('chef_type') === chef?.chef_type) {
        setValue('document', chef?.document);
        return;
      }
    }

    setValue('document', '');
  }, [watch('chef_type')]);

  React.useEffect(() => {
    defaultResetFields();
  }, []);

  const formatBankInfos = (formValues: ChefFormT) => {
    if (typeBank === 'Pix') {
      delete formValues.bank_info.bank_acc;
      delete formValues.bank_info.bank_agency;
      delete formValues.bank_info.bank_type;
    } else {
      delete formValues.bank_info.pix;
    }
    return formValues;
  };

  const handleEditClick = async (id: string) => {
    const selectedChef = chefsMap?.find((chef) => chef.chef_id._id?.toString() === id.toString());

    if (selectedChef) {
      const {
        name,
        email,
        phone,
        active,
        chef_id: {
          document,
          bank_info,
          bio,
          chef_type,
          picture_url,
          title,
          attendance_radius_km,
          location_address,
        },
      } = selectedChef;
      reset({
        name,
        email,
        phone,
        document,
        chef_type,
        bio,
        title,
        bank_info,
        attendance_radius_km: attendance_radius_km ?? 0,
        active,
        address: location_address,
        password: undefined,
      });
      setPicture(picture_url);
      setChefToEdit(selectedChef._id);
      setModalOpen(true);
    }
  };

  const handleCancelModal = () => {
    setModalOpen(false);
    setChefToEdit(undefined);
    defaultResetFields();
    setPicture(undefined);
  };

  const handleInputFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const limitFileSize = process.env.REACT_APP_LIMIT_FILE_SIZE || 10485760;

    const file =
      event && event.target && event.target.files && event.target.files.length > 0
        ? event.target.files
        : null;

    if (!file) {
      return;
    }
    const reader = new FileReader();
    reader.onload = (e) => {
      setPicture(e.target?.result?.toString() ?? '');
    };
    if (file[0].size > Number(limitFileSize)) {
      dispatch(
        setToastNotification({
          type: 'error',
          open: true,
          message: `Tamanho máximo da imagem: ${formatBytes(Number(limitFileSize), 2)}`,
        }),
      );
    } else {
      reader.readAsDataURL(file[0]);
      setPictureFile(file[0]);
      // Clean input
      event.currentTarget.value = '';
    }
  };

  const handleSubmitForm = (formValues: ChefFormT) => {
    if (chefToEdit) {
      const address = formValues.address;
      const values = formValues;
      delete values.address;

      updateChef({
        ...formatBankInfos(values),
        phone: values.phone.replace(/[^\d]+/g, ''),
        document: values.document.replace(/[^\d]+/g, ''),
        _id: chefToEdit,
        password: values.password ? values.password : undefined,
        location_address: address ?? undefined,
        latitude: location?.lat ?? undefined,
        longitude: location?.long ?? undefined,
      });
    } else {
      const address = formValues.address;
      const values = formValues;
      delete values.active;
      delete values.address;
      createChef({
        ...formatBankInfos(values),
        phone: values.phone.replace(/[^\d]+/g, ''),
        document: values.document.replace(/[^\d]+/g, ''),
        location_address: address ?? undefined,
        latitude: location?.lat ?? undefined,
        longitude: location?.long ?? undefined,
      });
    }
  };

  return (
    <>
      <CRUDTemplate
        title='Chef'
        onAddClick={() => setModalOpen(true)}
        onEditClick={(id: string) => handleEditClick(id)}
        hasEdit={true}
        columns={[
          {
            field: 'name',
            headerName: 'Nome',
          },
          {
            field: 'email',
            headerName: 'Email',
          },
          {
            field: 'phone',
            headerName: 'Celular',
            renderCell: (cellValue) => {
              const cellPhone = phoneFormat(cellValue.value);
              return cellPhone;
            },
          },
          {
            field: 'document',
            headerName: 'Documento',
            renderCell: (cellValue) => {
              let documentFormat = '';
              if (cellValue.row.chef_type === 'Pessoa Física') {
                documentFormat = cpfFormat(cellValue.value);
              } else {
                documentFormat = cnpjFormat(cellValue.value);
              }
              return documentFormat;
            },
          },
          {
            field: 'chef_type',
            headerName: 'Tipo do Chefe',
          },
          {
            field: 'bio',
            headerName: 'Biografia',
          },
          {
            field: 'title',
            headerName: 'Titulo',
          },
          {
            field: 'active',
            headerName: 'Ativo',
            type: 'boolean',
          },
        ]}
        rows={chefsMap || []}
      />
      <Dialog
        open={isModalOpen}
        onClose={(event: object, reason: string) => {
          if (reason !== 'backdropClick') {
            handleCancelModal();
          }
        }}
        sx={{
          '.pac-container': {
            'z-index': 1051,
          },
        }}
      >
        <DialogTitle>{chefToEdit ? 'Editar' : 'Adicionar'} Chef</DialogTitle>
        <DialogContent>
          <Box display='flex' justifyContent='center' alignItems='center'>
            <IconButton color='primary' aria-label='upload picture' component='label'>
              <Input
                hidden
                style={{ display: 'none' }}
                onChange={handleInputFile}
                id='icon-button-file'
                type='file'
                inputProps={{ accept: 'image/*' }}
              />
              <Avatar
                alt='User Avatar'
                src={picture}
                sx={{ width: 80, height: 80, marginBottom: 2 }}
              >
                {getValues('name') ? getValues('name')?.charAt(0) : 'Chef'}
              </Avatar>
            </IconButton>
          </Box>
          <Box display='flex' flexDirection='row' gap={2} width='100%'>
            <TextField
              required
              id='name'
              label='Nome'
              fullWidth
              size='small'
              margin='normal'
              placeholder='Chef Dário'
              InputLabelProps={{ shrink: true }}
              {...register('name')}
              error={errors.name ? true : false}
              helperText={errors.name ? String(errors.name.message) : null}
            />
            <Controller
              name='phone'
              control={control}
              defaultValue=''
              render={({ field: { onChange, value } }) => (
                <InputMask mask='(99) 99999-9999' value={value} onChange={onChange}>
                  <TextField
                    required
                    id='phone'
                    label='Telefone'
                    fullWidth
                    size='small'
                    margin='normal'
                    type='tel'
                    InputLabelProps={{ shrink: true }}
                    error={errors.phone ? true : false}
                    helperText={errors.phone ? String(errors.phone.message) : null}
                  />
                </InputMask>
              )}
            />
          </Box>
          <Box display='flex' flexDirection='row' gap={2} width='100%'>
            <TextField
              required
              id='email'
              label='Email'
              fullWidth
              size='small'
              margin='normal'
              placeholder='exemplo@gmail.com'
              autoComplete='off'
              inputProps={{
                autocomplete: 'new-password',
                form: {
                  autocomplete: 'off',
                },
              }}
              InputLabelProps={{ shrink: true }}
              {...register('email')}
              error={errors.email ? true : false}
              helperText={errors.email ? String(errors.email.message) : null}
            />
            <TextField
              required
              id='password'
              label='Senha'
              type='password'
              fullWidth
              size='small'
              margin='normal'
              autoComplete='off'
              inputProps={{
                autocomplete: 'new-password',
                form: {
                  autocomplete: 'off',
                },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              {...register('password')}
              error={errors.password ? true : false}
              helperText={errors.password ? String(errors.password.message) : null}
            />
          </Box>
          <Box display='flex' flexDirection='row' gap={2} width='100%' alignItems='center'>
            <Controller
              name='chef_type'
              defaultValue={getValues('chef_type')}
              rules={{ required: true }}
              control={control}
              render={({ field }) => {
                return (
                  <RadioGroup row {...field}>
                    <FormControlLabel
                      value='Pessoa Física'
                      control={<Radio />}
                      label='Pessoa Física'
                    />
                    <FormControlLabel
                      value='Pessoa Jurídica'
                      control={<Radio />}
                      label='Pessoa Jurídica'
                    />
                  </RadioGroup>
                );
              }}
            />
            <Controller
              name='document'
              defaultValue={getValues('document')}
              control={control}
              render={({ field: { onChange, value } }) => {
                const dynamicMask = () => {
                  if (getValues('chef_type') === 'Pessoa Física') {
                    return '999.999.999-99';
                  } else {
                    return '99.999.999/9999-99';
                  }
                };
                return (
                  <InputMask mask={dynamicMask()} value={value} onChange={onChange}>
                    <TextField
                      id='document'
                      label='Documento'
                      size='small'
                      sx={{ width: '57%' }}
                      type='text'
                      margin='normal'
                      InputLabelProps={{ shrink: true }}
                      error={errors.document ? true : false}
                      helperText={errors.document ? String(errors.document.message) : null}
                    />
                  </InputMask>
                );
              }}
            />
          </Box>
          <TextField
            required
            id='title'
            label='Titulo'
            fullWidth
            size='small'
            margin='normal'
            placeholder='Masterchef 2022'
            InputLabelProps={{ shrink: true }}
            {...register('title')}
            error={errors.title ? true : false}
            helperText={errors.title ? String(errors.title.message) : null}
          />
          <TextField
            required
            id='bio'
            label='Biografia'
            fullWidth
            multiline
            maxRows={4}
            minRows={2}
            size='small'
            margin='normal'
            placeholder='Olá meu nome é Dàrio...'
            InputLabelProps={{ shrink: true }}
            {...register('bio')}
            error={errors.bio ? true : false}
            helperText={errors.bio ? String(errors.bio.message) : null}
          />
          <GoogleAutoComplete
            required
            fullWidth
            shrink
            size='small'
            margin='normal'
            defaultValue={getValues('address') ?? ''}
            label='Endereço'
            error={errors.address ? true : false}
            helperText={errors.address ? String(errors.address.message) : undefined}
            setAddress={handleAddressSelection}
          />
          <TextField
            required
            id='attendance_radius_km'
            label='Raio de atendimento do chef (km)'
            type='number'
            size='small'
            margin='normal'
            sx={{ width: '40%' }}
            InputLabelProps={{ shrink: true }}
            {...register('attendance_radius_km')}
            error={errors.attendance_radius_km ? true : false}
            helperText={
              errors.attendance_radius_km ? String(errors.attendance_radius_km.message) : null
            }
          />
          <Box display='flex' alignItems='center' justifyContent='center'>
            <Typography m={2} alignSelf='center' display='flex' justifyContent='center'>
              Informações bancárias
            </Typography>
            <RadioGroup
              row
              aria-labelledby='bank_info-radio-buttons-group'
              defaultValue={getValues('bank_info')?.pix ? 'Pix' : 'Conta Bancária'}
              value={typeBank}
              onChange={(e) => {
                setTypeBank(e.target.value);
              }}
            >
              <>
                <FormControlLabel value='Pix' control={<Radio />} label='Pix' />
                <FormControlLabel
                  value='Conta Bancária'
                  control={<Radio />}
                  label='Conta Bancária'
                />
              </>
            </RadioGroup>
          </Box>
          <Box display='flex' flexDirection='column' gap={2} alignItems='center'>
            {typeBank === 'Pix' ? (
              <TextField
                id='bank_info.pix'
                label='Chave Pix'
                margin='normal'
                size='small'
                defaultValue={typeBank === 'Pix' ? getValues('bank_info.pix') : ''}
                placeholder='Chave pix'
                InputLabelProps={{ shrink: true }}
                {...register('bank_info.pix')}
                error={errors.bank_info?.pix ? true : false}
                helperText={errors.bank_info?.pix ? String(errors.bank_info?.pix.message) : null}
              />
            ) : (
              <Box display='flex' flexDirection='row' gap={0.5} alignItems='center'>
                <Controller
                  name='bank_info.bank_agency'
                  control={control}
                  defaultValue={typeBank !== 'Pix' ? getValues('bank_info.bank_agency') : ''}
                  render={({ field: { onChange, value } }) => (
                    <InputMask mask='9999' value={value} onChange={onChange}>
                      <TextField
                        id='bank_info.bank_agency'
                        label='Agência'
                        size='small'
                        margin='normal'
                        sx={{ maxWidth: '100px' }}
                        InputLabelProps={{ shrink: true }}
                        error={errors.bank_info?.bank_agency ? true : false}
                        helperText={
                          errors.bank_info?.bank_agency
                            ? String(errors.bank_info?.bank_agency.message)
                            : null
                        }
                      />
                    </InputMask>
                  )}
                />
                <Controller
                  name='bank_info.bank_acc'
                  control={control}
                  defaultValue={getValues('bank_info.bank_acc') ?? undefined}
                  render={({ field: { onChange, value } }) => (
                    <InputMask mask='99999999-9' value={value} onChange={onChange}>
                      <TextField
                        id='bank_info.bank_acc'
                        label='Conta bancária'
                        size='small'
                        margin='normal'
                        sx={{ maxWidth: '150px' }}
                        InputLabelProps={{ shrink: true }}
                        error={errors.bank_info?.bank_acc ? true : false}
                        helperText={
                          errors.bank_info?.bank_acc
                            ? String(errors.bank_info?.bank_acc.message)
                            : null
                        }
                      />
                    </InputMask>
                  )}
                />
                <Controller
                  name='bank_info.bank_type'
                  control={control}
                  defaultValue={getValues('bank_info.bank_type') ?? undefined}
                  render={({ field: { onChange, value } }) => (
                    <InputMask mask='9999' value={value} onChange={onChange}>
                      <TextField
                        id='bank_info.bank_type'
                        label='Banco'
                        placeholder=''
                        size='small'
                        margin='normal'
                        sx={{ maxWidth: '100px' }}
                        InputLabelProps={{ shrink: true }}
                        error={errors.bank_info?.bank_type ? true : false}
                        helperText={
                          errors.bank_info?.bank_type
                            ? String(errors.bank_info?.bank_type.message)
                            : null
                        }
                      />
                    </InputMask>
                  )}
                />
              </Box>
            )}
          </Box>
          {chefToEdit && (
            <Box display='flex' justifyContent='flex-end' mt={1}>
              <Controller
                name='active'
                defaultValue={getValues('active')}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <FormControlLabel
                    control={
                      <Switch
                        onChange={onChange}
                        checked={value ?? false}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    }
                    label='Ativo'
                  />
                )}
              />
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelModal} color='secondary'>
            Cancelar
          </Button>
          <Button onClick={handleSubmit(handleSubmitForm)} color='primary'>
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Chef;
