import { styled } from '@mui/material/styles';
import { Box, Divider, Paper } from '@mui/material';
import { FavoriteBorder } from '@mui/icons-material';

interface SizePropsI {
  small: boolean;
  medium: boolean;
}

export const Container = styled(Box)(() => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const FormContainer = styled(Paper)<{ size: SizePropsI }>(({ theme, size }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  flexDirection: 'column',
  borderRadius: '10px',
  padding: size.small ? '70px 25px' : '50px',
  overflowY: size.small ? 'auto' : 'hidden',
  height: size.small ? '100%' : 'auto',
  backgroundColor: theme.palette.white.main,
  maxHeight: size.small ? 'auto' : '95%',
}));

export const FavoriteIcon = styled(FavoriteBorder)(({ theme }) => ({
  borderRadius: '50%',
  padding: 2,
  backgroundColor: theme.palette.grey[300],
}));

export const DividerInfos = styled(Divider)<{ size: SizePropsI }>(({ theme, size }) => ({
  backgroundColor: theme.palette.primary.main,
  width: size.small ? 350 : size.medium ? 500 : 550,
  marginBottom: 20,
}));
