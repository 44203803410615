import React from 'react';
import { Box, Grid, Typography, useMediaQuery } from '@mui/material';
import { theme } from '../../../theme';
import { ImpactText } from '../landingPage.styles';
import StarIcon from '@mui/icons-material/Star';
import { Carousel } from 'react-responsive-carousel';
import { CarouselItem } from './customers.style';

import Av1 from '../../../assets/images/avaliacoes/avaliacao 1.png';
import Av2 from '../../../assets/images/avaliacoes/avaliacao 2.png';
import Av3 from '../../../assets/images/avaliacoes/avaliacao 3.png';
import Av4 from '../../../assets/images/avaliacoes/avaliacao 4.png';
import Av5 from '../../../assets/images/avaliacoes/avaliacao 5.png';
import Av6 from '../../../assets/images/avaliacoes/avaliacao 6.png';
import Av7 from '../../../assets/images/avaliacoes/avaliacao 7.png';
import Av8 from '../../../assets/images/avaliacoes/avaliacao 8.png';

const avaliacoes = [Av1, Av2, Av3, Av4, Av5, Av6, Av7, Av8];

function Customers() {
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box sx={{ padding: { xs: 0, md: 10 } }}>
      <Grid container>
        <Grid item xs={12} sx={{ textAlign: 'center' }}>
          <ImpactText variant={isMobile ? 'h4' : 'h3'}>O que nossos clientes dizem</ImpactText>
          <Typography paddingTop={2}>
            {[...Array(5)].map((_i, x) => (
              <StarIcon key={x} sx={{ color: theme.palette.gold.main, fontSize: '30px' }} />
            ))}
          </Typography>
          <Carousel 
              infiniteLoop={true}
              autoFocus={true}
              centerMode={true}
              centerSlidePercentage={isMobile? 100 : 50}
              emulateTouch={true}
              dynamicHeight={true}
              showThumbs={false}
              showIndicators={false}
              >
            {avaliacoes.map((avaliacao, i) => (
              <CarouselItem key={i}>
                <img src={avaliacao} />
              </CarouselItem>
            ))}
          </Carousel>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Customers;
