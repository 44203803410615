import { Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Rating,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import React from 'react';
import { useCreateScheduleRatingMutation } from '../../app/api/scheduleRatingAPI';
import { theme } from '../../theme';

interface RattingScheduleModalI {
  open: boolean;
  onClose: () => void;
  scheduleId: string;
}

export const RatingScheduleModal = ({ open, onClose, scheduleId }: RattingScheduleModalI) => {
  const smallSize = useMediaQuery(theme.breakpoints.down('sm'));

  const [evaluation, setEvaluation] = React.useState<number>(0);
  const [comment, setComment] = React.useState<string | undefined>(undefined);

  const [createRating, { isLoading, isSuccess }] = useCreateScheduleRatingMutation(undefined);

  const handleClose = () => {
    setEvaluation(0);
    setComment(undefined);
    onClose();
  };

  const handleSaveRating = () => {
    createRating({
      scheduling_id: scheduleId,
      evaluation,
      comment,
    });
  };

  React.useEffect(() => {
    if (isSuccess) {
      handleClose();
    }
  }, [isSuccess]);

  return (
    <Dialog
      open={open}
      onClose={(event: object, reason: string) => {
        if (reason !== 'backdropClick') {
          handleClose();
        }
      }}
      fullWidth
      maxWidth='sm'
    >
      <Box display='flex' alignItems='center' justifyContent='center' flexDirection='row'>
        <DialogTitle
          align='center'
          variant={smallSize ? 'h5' : 'h4'}
          color='primary'
          display='flex'
        >
          Avaliação do agendamento
        </DialogTitle>
        <Box
          display='flex'
          justifyContent='flex-end'
          alignItems='flex-end'
          position='relative'
          left={smallSize ? -10 : 30}
          bottom={3}
        >
          <IconButton onClick={handleClose}>
            <Close fontSize='small' color='error' />
          </IconButton>
        </Box>
      </Box>
      <DialogContent
        sx={{
          sm: {
            marginX: 2,
            paddingX: 2,
          },
          xl: {
            marginX: 5,
            paddingX: 5,
          },
        }}
      >
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          flexDirection='column'
          gap={1}
        >
          <Box
            display='flex'
            justifyContent='center'
            flexDirection='column'
            alignItems='center'
            width={300}
            gap={2}
          >
            <Typography component='legend' variant='body1'>
              Como foi a sua experiencia?
            </Typography>
            <Rating
              name='simple-controlled'
              value={evaluation}
              onChange={(event, newValue) => {
                setEvaluation(newValue ?? 0);
              }}
            />
            <TextField
              id='bio'
              label='Comentário'
              fullWidth
              multiline
              value={comment}
              onChange={(e) => {
                setComment(e.target.value);
              }}
              maxRows={4}
              minRows={2}
              size='small'
              margin='normal'
              placeholder=''
              InputLabelProps={{ shrink: true }}
            />
          </Box>
          <Box display='flex' alignSelf='flex-end' gap={2}>
            <Button
              size='large'
              sx={{ marginTop: '15px' }}
              color='primary'
              fullWidth
              onClick={handleClose}
            >
              Cancelar
            </Button>
            {isLoading ? (
              <LoadingButton
                size='large'
                sx={{ marginTop: '15px' }}
                loading
                variant='contained'
                color='primary'
                fullWidth
              >
                Salvar
              </LoadingButton>
            ) : (
              <Button
                size='large'
                sx={{ marginTop: '15px' }}
                variant='contained'
                color='primary'
                onClick={handleSaveRating}
                fullWidth
              >
                Salvar
              </Button>
            )}
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
