import React from 'react';

import { Box, Grid, Typography, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import PropComment from '../../../assets/images/popupInfo/13.png';
import CateringHeroBG from '../../../assets/images/CateringHeroBG.jpg';
import Effect from '../../../assets/images/effect.png';
import { theme } from '../../../theme';
import {
  CheckedIcon,
  ImgBackground,
  ImpactText,
  PopImage,
  StyledButton,
  StyledImg,
} from '../landingPage.styles';

function CateringHero() {
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();

  const handleNavigateToExplore = () => navigate('/catering', { state: { openModal: true } });

  return (
    <Box sx={{ paddingLeft: { xs: 0, md: 10 }, paddingRight: { xs: 0, md: 5 } }}>
      <Grid container>
        <Grid item xs={12} md={5}>
          <ImgBackground size={{ isMobile: Boolean(isMobile) }}>
            <Box sx={{
              position: 'absolute',
              zIndex: 2,
              width: isMobile ? '100%' : '70%'
            }}>
              <PopImage size={{ isMobile: Boolean(isMobile) }} src={PropComment} />
            </Box>
            <StyledImg
              src={CateringHeroBG}
              alt='catering hero bg'
              size={{ isMobile: Boolean(isMobile) }}
            />
            <Box sx={{
              position: 'absolute',
              zIndex: 1,
              width: { xs: 0, md: '100%'},
              top: 0,
              transform: 'scaleX(-1)'
            }}>
              <img src={Effect} width={'100%'} />
            </Box>
          </ImgBackground>
        </Grid>
        <Grid
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            backgroundColor: theme.palette.white.main,
            paddingBottom: 10,
            paddingTop: isMobile ? 10 : 5,
            paddingX: isMobile ? 0 : 20,
          }}
          item
          xs={12}
          md={7}
        >
          <Box>
            <ImpactText variant={isMobile ? 'h4' : 'h3'}>
              Catering corporativo e buffets para sua empresa
            </ImpactText>
            <Typography variant='subtitle1' color='rgb(87,106,122)'>
              <CheckedIcon /> Economize tempo e receba de nossa concierge orçamentos
              personalizados. Aceite o que mais se adequar ao seu evento
            </Typography>
            <StyledButton variant='outlined' color='primary' onClick={handleNavigateToExplore}>
              Solicite Já
            </StyledButton>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default CateringHero;
