import React from 'react';

import { Box, Grid, useMediaQuery } from '@mui/material';

import BuffetBG from '../../../assets/images/corporate-buffet.jpg';
import CanapesBG from '../../../assets/images/corporate-catering-canapes.jpg';
import { theme } from '../../../theme';
import { ImpactText, StyledImg } from '../catering.style';

function Pictures() {
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box sx={{ paddingX: { xs: 0, md: 10 }, marginTop: { xs: 0, md: -10 } }}>
      <Grid container>
        <Grid item xs={12}>
          <ImpactText
            variant={isMobile ? 'h6' : 'h5'}
            sx={{ textAlign: { xs: 'center', md: 'left' } }}
          >
            Brunch, Coffee Breaks, Happy Hours,
            <br />Workshops, Reuniões, Confraternizações
          </ImpactText>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            paddingRight: { xs: 0, md: 5 },
            paddingBottom: { xs: 2, md: 0 },
          }}
        >
          <StyledImg
            src={CanapesBG}
            alt='Corporate canapes bg'
            size={{ isMobile: Boolean(isMobile) }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <StyledImg
            src={BuffetBG}
            alt='Corporate buffet bg'
            size={{ isMobile: Boolean(isMobile) }}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default Pictures;
