import { Box, Link } from '@mui/material';
import { StyledTypographyIcons } from './footer.styles';
import * as MUIcon from '@mui/icons-material';
import { theme } from '../../theme';

interface FooterItemProps {
  name?: string;
  iconName: keyof typeof MUIcon | string;
  redirectUrl?: string;
  onClick?: () => void;
}

export const FooterItem = ({ name, iconName, onClick }: FooterItemProps) => {
  const Icon = MUIcon[iconName as keyof typeof MUIcon];

  return (
    <Link underline='none' onClick={onClick} sx={{ cursor: 'pointer' }}>
      <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
        <Icon fontSize='medium' sx={{ color: theme.palette.black.main }} />
        <StyledTypographyIcons textAlign='center'>{name}</StyledTypographyIcons>
      </Box>
    </Link>
  );
};
