import React from 'react';

import { Snackbar, Alert, CircularProgress } from '@mui/material';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  isAppLoading,
  getToastNotification,
  resetToastNotification,
} from '../../app/slices/appSlice';
import { Header, Footer } from '../../components';
import { LoaderWrapper } from './wrapper.styles';
import { getToken, getTokensCookie, setTokens } from '../../app/slices/userSlice';
import { useGetMeQuery } from '../../app/api/userAPI';
import { WhatsappIcon } from '../WhatsappIcon';

export type WrapperT = {
  children?: React.ReactNode;
  showHeader?: boolean;
  showFooter?: boolean;
  footerPosition?: 'relative' | 'fixed' | 'absolute' | 'sticky' | 'static';
};

const Wrapper = ({
  children,
  showHeader = false,
  showFooter = false,
  footerPosition = 'relative',
}: React.PropsWithChildren<WrapperT>) => {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(isAppLoading);
  const { token: cookieToken, refresh_token } = getTokensCookie();
  const reduxToken = useAppSelector(getToken);
  const token = reduxToken;
  const { open: isToastOpen, message, type } = useAppSelector(getToastNotification);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const getMe = useGetMeQuery(undefined, {
    skip: !token,
  });
  const isSomeQueryPending = useAppSelector((state) =>
    Object.values(state.api.queries).some((query) => query?.status === 'pending'),
  );

  const isSomeRequestPending = useAppSelector((state) =>
    Object.values(state.api.mutations).some((query) => query?.status === 'pending'),
  );

  // When the user refreshes the page, if he/she was logged in, the app gets the token from cookie and applies to redux
  React.useEffect(() => {
    if (cookieToken && refresh_token && !reduxToken) {
      dispatch(setTokens({ token: cookieToken, refresh_token }));
    }
  }, [cookieToken, dispatch]);

  const handleToastNotification = () => dispatch(resetToastNotification());

  return (
    <>
      {showHeader && <Header />}
      {children}
      <WhatsappIcon/>
      {showFooter && <Footer position={footerPosition} />}
      {isToastOpen && (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={isToastOpen}
          autoHideDuration={6000}
          onClose={handleToastNotification}
          sx={{ zIndex: 2000 }}
        >
          <Alert onClose={handleToastNotification} severity={type}>
            {message}
          </Alert>
        </Snackbar>
      )}
      {(isSomeRequestPending || isSomeQueryPending || isLoading) && (
        <LoaderWrapper>
          <CircularProgress color='primary' size={60} />
        </LoaderWrapper>
      )}
    </>
  );
};

export default Wrapper;
