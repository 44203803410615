import { Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { StyledAppBar, StyledLinkHelpPages } from './footer.styles';
import { FooterItem } from './FooterItem';

export interface FooterProps {
  position?: 'relative' | 'fixed' | 'absolute' | 'sticky' | 'static';
}

function Footer({ position = 'relative' }: FooterProps) {
  const navigate = useNavigate();

  const helpPages = [
    { name: 'Como Funciona', navigate: '/how-it-works' },
    { name: 'Contato', navigate: '/contact' },
    { name: 'Termos de Serviço', navigate: '/terms' },
  ];

  const iconsPages = [
    { name: 'Explore', navigate: '/explore', iconName: 'DinnerDining' },
    { name: 'Favoritos', navigate: '/favorites', iconName: 'FavoriteBorderOutlined' },
    { name: 'Agenda', navigate: '/schedule', iconName: 'TodayRounded' },
    { name: 'Perfil', navigate: '/client-profile', iconName: 'AccountCircleRounded' },
  ];

  const handleMenuOptionClick = (navigateTo: string) => {
    navigate(navigateTo);
  };

  return (
    <StyledAppBar position={position}>
      <Grid
        container
        spacing={3}
        direction='row'
        justifyContent='center'
        alignItems='center'
        wrap='wrap'
      >
        <Grid item xs={6}>
          <Grid container spacing={2} direction='row' wrap='wrap'>
            {iconsPages.map((page) => (
              <Grid item xs key={page.name}>
                <FooterItem
                  onClick={() => handleMenuOptionClick(page.navigate)}
                  name={page.name}
                  iconName={page.iconName}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>

        <Grid item xs='auto' display='flex' flexDirection='column' gap={0.5}>
          {helpPages.map((page) => (
            <StyledLinkHelpPages
              key={page.name}
              onClick={() => handleMenuOptionClick(page.navigate)}
              underline='none'
            >
              <Typography textAlign='left' variant='subtitle2'>
                {page.name}
              </Typography>
            </StyledLinkHelpPages>
          ))}
        </Grid>

        <Grid item xs={1.5} display='flex' justifyContent='center'>
          <FooterItem
            iconName='Instagram'
            onClick={() =>
              window.open('https://www.instagram.com/abouteatbr/', '_blank', 'noreferrer')
            }
          />
        </Grid>
      </Grid>
    </StyledAppBar>
  );
}
export default Footer;
