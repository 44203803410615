import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import { formatBytes } from '../../utils/formatBytes';
import { Container, Label, ThumbsContainer, Thumb, ThumbInner, Img } from './inputFile.styles';

export type InputFileT = {
  maxFiles: number;
  label: string;
  defaultImages?: string[];
  files?: (File & { preview: string })[];
  setFiles: Dispatch<SetStateAction<(File & { preview: string })[] | undefined>>;
};

function InputFile({ maxFiles, label, files, setFiles, defaultImages }: InputFileT) {
  const [errors, setErrors] = useState<string | null>(null);

  const limitFileSize = process.env.REACT_APP_LIMIT_FILE_SIZE || 10485760;

  const { getRootProps, getInputProps } = useDropzone({
    maxSize: Number(limitFileSize),
    multiple: Boolean(maxFiles > 1),
    maxFiles,
    accept: {
      'image/*': [],
    },
    onDrop: (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      const fileToSet = acceptedFiles.map((file) => {
        return Object.assign(file, {
          preview: URL.createObjectURL(file),
        });
      });
      setFiles(fileToSet);
      fileRejections.forEach((file) => {
        file.errors.forEach((err) => {
          if (err.code === 'file-too-large') {
            setErrors(`Tamanho máximo da imagem: ${formatBytes(Number(limitFileSize), 2)}`);
          }
        });
      });
    },
  });

  useEffect(() => {
    return () => {
      if (Array.isArray(files)) {
        return files.forEach((file) => URL.revokeObjectURL(file.preview));
      }
    };
  }, []);

  const thumbs =
    Array.isArray(files) &&
    files.map((file) => (
      <Thumb key={file.preview}>
        <ThumbInner>
          <Img
            src={file.preview}
            onLoad={() => {
              URL.revokeObjectURL(file.preview);
            }}
          />
        </ThumbInner>
      </Thumb>
    ));

  const defaultThumbs = defaultImages?.map((di) => (
    <Thumb key={di}>
      <ThumbInner>
        <Img src={`${di}?random=${new Date().getSeconds()}`} />
      </ThumbInner>
    </Thumb>
  ));

  return (
    <Container>
      <div {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        <Label>{label}</Label>
        <Label variant='caption'>Máximo de imagens: {maxFiles} </Label>
        <br />
        <Label variant='caption'>{errors}</Label>
      </div>
      <ThumbsContainer>
        {Array.isArray(files) && files?.length ? thumbs : defaultThumbs}
      </ThumbsContainer>
    </Container>
  );
}

export default InputFile;
