import { BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Mutex } from 'async-mutex';
import { setToastNotification } from './slices/appSlice';
import { getTokensCookie, logout, setTokens, TokensT } from './slices/userSlice/index';

declare module '@reduxjs/toolkit/query' {
  interface FetchBaseQueryError {
    status: number | string;
    data: {
      message: string;
    };
  }
}

const baseUrl =
  process.env.REACT_APP_API || 'https://e2uwagqim8.execute-api.us-east-1.amazonaws.com/hmg/v.01';

const mutex = new Mutex();

const baseQuery = fetchBaseQuery({
  baseUrl,
  prepareHeaders: (headers, { endpoint }) => {
    const { token } = getTokensCookie();
    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }
    const UPLOAD_ENDPOINTS = ['uploadImage', 'uploadMenuImages'];
    if (!UPLOAD_ENDPOINTS.includes(endpoint)) {
      headers.set('content-type', 'application/json;charset=UTF-8');
    }

    return headers;
  },
});

const baseQueryWithErrorHandling: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  await mutex.waitForUnlock();
  let result = await baseQuery(args, api, extraOptions);
  if (result.error) {
    if (result.error.status === 401 || result.error.status === 'FETCH_ERROR') {
      if (!mutex.isLocked()) {
        const release = await mutex.acquire();
        try {
          const { token, refresh_token } = getTokensCookie();
          if (token && refresh_token) {
            const refreshResult = await baseQuery(
              {
                url: '/token/refresh',
                method: 'POST',
                body: {
                  token,
                  refresh_token,
                },
              },
              api,
              extraOptions,
            );
            if (refreshResult.data) {
              const tokens = refreshResult.data as TokensT;
              await api.dispatch(setTokens(tokens));
              result = await baseQuery(args, api, extraOptions);
            } else {
              api.dispatch(logout());
              window.location.href = '/sign-in';
            }
          } else {
            window.location.href = `/sign-in/client/${window.location.pathname
              .replaceAll('/', '_')
              .substring(1)}`;
          }
        } finally {
          // release must be called once the mutex should be released again.
          release();
        }
      } else {
        // wait until the mutex is available without locking it
        await mutex.waitForUnlock();
        result = await baseQuery(args, api, extraOptions);
      }
    } else {
      const message = result.error.data.message;
      console.log('API Error', message);
      api.dispatch(
        setToastNotification({
          type: 'error',
          open: true,
          message,
        }),
      );
    }
  }
  return result;
};

export const apiBase = createApi({
  reducerPath: 'api',
  baseQuery: baseQueryWithErrorHandling,
  tagTypes: [
    'User',
    'Auth',
    'Category',
    'ShowCaseCategory',
    'Chef',
    'Favorite',
    'Schedule',
    'ScheduleEvaluation',
    'Scheduling',
    'Item',
    'Menu',
    'Contact',
    'Dashboard',
    'SystemSetting',
    'Coupon',
  ],
  refetchOnFocus: false,
  refetchOnReconnect: true,
  refetchOnMountOrArgChange: true,
  endpoints: () => ({}),
});
