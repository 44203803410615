import React, { useEffect } from 'react';
import { CRUDTemplate } from '../../../components';

import {
  SchedulingT,
  useGetSchedulingQuery,
  useRemoveSchedulingMutation,
} from '../../../app/api/schedulingAPI';
import { formatStatusIcon, ScheduleDetailModal } from '../../../components/ScheduleDetailModal';
import { formatDate } from '../../../utils/formats';
import { Autocomplete, Box, TextField } from '@mui/material';
import { useGetAllUsersQuery, UserListT } from '../../../app/api/userAPI';
import { useAppDispatch } from '../../../app/hooks';
import { setToastNotification } from '../../../app/slices/appSlice';

type FilterFormT = {
  keySearchMap: 'client_id' | 'chef_user_id';
  user: UserListT;
};

function AppointmentsPerClient() {
  const dispatch = useAppDispatch();

  const { data: users } = useGetAllUsersQuery(undefined);
  const { data: appointments } = useGetSchedulingQuery(undefined);
  const [removeAppointments, { isSuccess: isSuccessRemove }] = useRemoveSchedulingMutation();

  const [detailOpen, setDetailOpen] = React.useState(false);
  const [cellData, setCellData] = React.useState<SchedulingT | undefined>();

  const [rowsMap, setRowMap] = React.useState<SchedulingT[] | undefined>();

  const [clients, setClients] = React.useState<UserListT[] | undefined>([]);
  const [client, setClient] = React.useState<UserListT | undefined>();

  const [chefs, setChefs] = React.useState<UserListT[] | undefined>([]);
  const [chef, setChef] = React.useState<UserListT | undefined>();

  useEffect(() => {
    setClients(users?.filter((user) => user.user_type === 'client'));
    setChefs(users?.filter((user) => user.user_type === 'chef'));
  }, [users]);

  useEffect(() => {
    const mapInfo = appointments?.map((item) => {
      return {
        ...item,
        is_catering: item.menu_id.is_catering,
      };
    });
    let filterMap;

    if (chef && client) {
      filterMap = mapInfo?.filter(
        (info) => info.client_id._id === client._id && info.chef_user_id._id === chef._id,
      );
    } else {
      const filterType: FilterFormT | null = client
        ? { keySearchMap: 'client_id', user: client }
        : chef
        ? { keySearchMap: 'chef_user_id', user: chef }
        : null;
      if (filterType) {
        filterMap = mapInfo?.filter(
          (info) => info[filterType?.keySearchMap]._id === filterType.user?._id,
        );
      }
    }
    setRowMap(filterMap ? filterMap : mapInfo);
  }, [appointments, client, chef]);

  useEffect(() => {
    if (isSuccessRemove) {
      dispatch(
        setToastNotification({
          type: 'success',
          open: true,
          message: 'Agendamento cancelado com sucesso!',
        }),
      );
    }
  }, [isSuccessRemove]);

  return (
    <>
      <CRUDTemplate
        title='Agendamentos'
        searchComponent={
          <Box display='flex' justifyContent='center' gap={2}>
            <Autocomplete
              options={chefs as UserListT[]}
              getOptionLabel={(option) => option.name}
              onChange={(event, newValue) => {
                setChef(newValue as UserListT);
              }}
              filterOptions={(users, state) => {
                if (!state.inputValue || state.inputValue.trim() === '') {
                  return users;
                } else {
                  const regexSearch = new RegExp(
                    state.inputValue.normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
                    'gi',
                  );

                  return users.filter((user) => {
                    const normalizedName = user.name
                      .normalize('NFD')
                      .replace(/[\u0300-\u036f]/g, '');

                    return regexSearch.test(normalizedName);
                  });
                }
              }}
              size='small'
              sx={{
                width: 250,
              }}
              renderInput={(params) => <TextField {...params} label='Chef' />}
              renderOption={(props, option: UserListT) => <li {...props}>{option.name}</li>}
            />
            <Autocomplete
              options={clients as UserListT[]}
              getOptionLabel={(option) => option.name}
              onChange={(_, newValue) => {
                setClient(newValue as UserListT);
              }}
              size='small'
              sx={{
                width: 250,
              }}
              filterOptions={(users, state) => {
                if (!state.inputValue || state.inputValue.trim() === '') {
                  return users;
                } else {
                  const regexSearch = new RegExp(
                    state.inputValue.normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
                    'gi',
                  );

                  return users.filter((user) => {
                    const normalizedName = user.name
                      .normalize('NFD')
                      .replace(/[\u0300-\u036f]/g, '');

                    return regexSearch.test(normalizedName);
                  });
                }
              }}
              renderInput={(params) => <TextField {...params} label='Cliente' />}
              renderOption={(props, option: UserListT) => <li {...props}>{option.name}</li>}
            />
          </Box>
        }
        onCellDoubleClick={(row) => {
          setCellData(row as SchedulingT);
          setDetailOpen(true);
        }}
        onDeleteClick={async (row) => {
          if (row) {
            await removeAppointments({ _id: row });
          }
        }}
        stateDeleteAccept={['Aguardando Pagamento', 'Agendado', 'Erro no pagamento']}
        deleteMessage='Cancelar o agendamento não fará o estorno, somente mudança de estado!'
        hasDelete={true}
        hasAdd={false}
        hasEdit={false}
        columns={[
          {
            field: '_id',
            headerName: 'Identificador',
            renderCell: (cellValue) => cellValue.value,
            maxWidth: 250,
          },
          {
            field: 'chef_name',
            headerName: 'Chef',
            maxWidth: 150,
          },
          {
            field: 'client_name',
            headerName: 'Cliente',
            maxWidth: 150,
          },
          {
            field: 'number_of_guests',
            headerName: 'Convidados',
            maxWidth: 100,
            align: 'center',
          },
          {
            field: 'total',
            headerName: 'Total',
            renderCell: (cellValue) => `R$ ${String(cellValue.value.toFixed(2)).replace('.', ',')}`,
            maxWidth: 100,
            align: 'center',
          },
          {
            field: 'formatted_event_date',
            headerName: 'Data/Hora que será servido',
            minWidth: 200,
          },
          {
            field: 'menu_name',
            headerName: 'Menu',
          },
          {
            field: 'address',
            headerName: 'Endereço',
            minWidth: 250,
          },
          {
            field: 'is_catering',
            headerName: 'Catering',
            type: 'boolean',
            maxWidth: 100,
          },
          {
            field: 'scheduling_state',
            headerName: 'Estado',
            renderCell: (cellValue) => {
              return formatStatusIcon(cellValue.value);
            },
          },
        ]}
        rows={
          rowsMap?.map((item) => {
            return {
              ...item,
              chef_name: item.chef_user_id.name,
              client_name: item.client_id.name,
              menu_name: item.menu_id.name,
              formatted_event_date: `${formatDate(
                item?.event_date ?? '',
                'dd/MM/yyyy',
              )} - ${formatDate(item?.event_date ?? '', 'HH:mm')}`,
            };
          }) || []
        }
      />
      <ScheduleDetailModal
        isAdmin
        data={cellData}
        open={detailOpen}
        onClose={() => {
          setDetailOpen(false);
        }}
      />
    </>
  );
}

export default AppointmentsPerClient;
