import React from 'react';
import {
  Box,
  Button,
  FormControlLabel,
  IconButton,
  Switch,
  Tab,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { theme } from '../../theme';
import { Container, FormContainer } from './availabilityConfig.styles';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Add, Delete } from '@mui/icons-material';
import {
  AvailabilityConfigT,
  useCreateScheduleMutation,
  useGetScheduleQuery,
} from '../../app/api/scheduleAPI';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { getMe } from '../../app/slices/userSlice';
import { setToastNotification } from '../../app/slices/appSlice';

function AvailabilityConfig() {
  const dispatch = useAppDispatch();
  const me = useAppSelector(getMe);

  const { data: schedule, isLoading } = useGetScheduleQuery(me?.chef_id?._id ?? '', {
    skip: !me?._id,
  });
  const [createSchedule, { isSuccess: isSuccessCreate }] = useCreateScheduleMutation();

  const smallSize = useMediaQuery(theme.breakpoints.down('sm'));
  const mediumSize = useMediaQuery(theme.breakpoints.down('md'));

  const [tabSelect, setTabSelect] = React.useState('1');
  const [activeDays, setActiveDays] = React.useState<number[]>([]);

  const validationSchema = Yup.object().shape({
    days: Yup.array(
      Yup.object().shape({
        day: Yup.string().required(),
        slots: Yup.array(
          Yup.object({
            start: Yup.string().when('end', {
              is: (end: string) => end,
              then: Yup.string().required('Data inicial é obrigatório'),
            }),
            end: Yup.string().test('minLengh', 'Deve ser maior que o inicio', (val, context) => {
              if (val) {
                const [startHour, startMinute] = context.parent.start.split(':');
                const [endHour, endMinute] = val.split(':');
                const endTime = new Date();
                const startTime = new Date();

                endTime.setHours(Number(endHour));
                endTime.setMinutes(Number(endMinute));
                startTime.setHours(Number(startHour));
                startTime.setMinutes(Number(startMinute));

                if (endTime < startTime) {
                  return false;
                }
              }
              return true;
            }),
            max_schedule_per_slot: Yup.number()
              .transform((value) => (isNaN(value) || value === '' ? null : value))
              .nullable()
              .positive('Deve ser positivo')
              .integer('Deve ser inteiro')
              .optional()
              .typeError('O valor deve ser um número válido'),
          }),
        ),
      }),
    ),
  });

  const defaultValues = [
    { day: 1, slots: [{ start: '', end: '' }] },
    { day: 2, slots: [{ start: '', end: '' }] },
    { day: 3, slots: [{ start: '', end: '' }] },
    { day: 4, slots: [{ start: '', end: '' }] },
    { day: 5, slots: [{ start: '', end: '' }] },
    { day: 6, slots: [{ start: '', end: '' }] },
    { day: 0, slots: [{ start: '', end: '' }] },
  ];

  const days = [
    { day: '1', weekDay: 'Segunda' },
    { day: '2', weekDay: 'Terça' },
    { day: '3', weekDay: 'Quarta' },
    { day: '4', weekDay: 'Quinta' },
    { day: '5', weekDay: 'Sexta' },
    { day: '6', weekDay: 'Sabado' },
    { day: '0', weekDay: 'Domingo' },
  ];

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm<AvailabilityConfigT>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      days: defaultValues,
    },
  });

  const { fields: Days } = useFieldArray({
    control,
    name: 'days',
  });

  const handleSaveDaysConfig = (formValues: AvailabilityConfigT) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const mapForm: any = formValues.days
      .map((config) => {
        if (activeDays.includes(Number(config.day))) {
          const slots = config?.slots
            ?.map((el) => {
              if (el.end && el.start) {
                return el;
              }
            })
            .filter((el) => el);

          if (slots?.length) {
            return {
              day: config.day,
              slots,
            };
          }
        }
      })
      .filter((el) => el);
    createSchedule({ days: mapForm as AvailabilityConfigT });
  };

  React.useEffect(() => {
    if (schedule?.days) {
      setActiveDays(schedule?.days.map((day) => day.day));
      const mappingReset = defaultValues.map((el) => {
        const index = schedule?.days?.findIndex((map) => map?.day === el.day);
        if (schedule?.days) {
          if (index !== -1) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            return schedule?.days?.[index!];
          }
        }
        return el;
      });
      reset({
        days: mappingReset,
      });
    }
  }, [schedule, isLoading]);

  React.useEffect(() => {
    if (isSuccessCreate) {
      dispatch(
        setToastNotification({
          type: 'success',
          open: true,
          message: 'Configuração de agenda salva com sucesso!',
        }),
      );
    }
  }, [isSuccessCreate]);

  const renderConfigs = (day: number, isActive = false) => {
    const index = Days.findIndex((el) => el.day === day);
    const { fields, remove, append, replace } = useFieldArray({
      control,
      name: `days.${index}.slots`,
    });

    if (!isActive) {
      return <></>;
    }

    return fields?.map((field, indexField) => {
      return (
        <Box
          key={field.id}
          display='flex'
          flexWrap='wrap'
          alignItems='center'
          justifyContent='center'
        >
          <Box
            display='flex'
            flexWrap='wrap'
            flexDirection={'row'}
            alignItems='center'
            justifyContent='center'
            mt={2}
            mb={2}
            minWidth={smallSize ? 250 : 300}
            maxWidth={300}
          >
            <Box
              display='flex'
              width='100%'
              flexDirection={'column'}
              gap={2}
              alignItems='center'
              justifyContent='center'
            >
              <TextField
                id='start'
                label='Inicio'
                type='time'
                size='small'
                fullWidth
                InputLabelProps={{ shrink: true }}
                {...register(`days.${index}.slots.${indexField}.start`)}
                error={errors?.days?.[index]?.slots?.[indexField]?.start ? true : false}
                helperText={
                  errors?.days?.[index]?.slots?.[indexField]?.start
                    ? String(errors?.days?.[index]?.slots?.[indexField]?.start?.message)
                    : null
                }
              />
              <TextField
                id='end'
                label='Fim'
                type='time'
                size='small'
                fullWidth
                InputLabelProps={{ shrink: true }}
                {...register(`days.${index}.slots.${indexField}.end`)}
                error={errors?.days?.[index]?.slots?.[indexField]?.end ? true : false}
                helperText={
                  errors?.days?.[index]?.slots?.[indexField]?.end
                    ? String(errors?.days?.[index]?.slots?.[indexField]?.end?.message)
                    : null
                }
              />
              <TextField
                id='max_schedule_per_slot'
                label='Número máximo de agendamentos'
                type='number'
                size='small'
                fullWidth
                InputLabelProps={{ shrink: true }}
                {...register(`days.${index}.slots.${indexField}.max_schedule_per_slot`)}
                error={
                  errors?.days?.[index]?.slots?.[indexField]?.max_schedule_per_slot ? true : false
                }
                helperText={
                  errors?.days?.[index]?.slots?.[indexField]?.max_schedule_per_slot
                    ? String(
                        errors?.days?.[index]?.slots?.[indexField]?.max_schedule_per_slot?.message,
                      )
                    : null
                }
              />
            </Box>
            {!smallSize && fields?.length === indexField + 1 && (
              <IconButton
                color='primary'
                sx={{ mt: 2 }}
                onClick={() => {
                  append({ start: '', end: '' });
                }}
              >
                <Add />
                <Typography variant='caption' color='red'>
                  Adicionar horário
                </Typography>
              </IconButton>
            )}
          </Box>
          <Box display={'flex'} alignItems='center' justifyContent='center' alignSelf='baseline'>
            <IconButton
              color='primary'
              sx={{ top: 40 }}
              component='label'
              onClick={() => {
                if (fields?.length == 1) {
                  replace({ start: '', end: '' });
                } else {
                  remove(indexField);
                }
              }}
            >
              <Delete />
            </IconButton>
          </Box>
          {smallSize && fields?.length === indexField + 1 && (
            <IconButton
              color='primary'
              sx={{ mt: 2 }}
              onClick={() => {
                append({ start: '', end: '' });
              }}
            >
              <Add />
              <Typography variant='caption' color='red'>
                Adicionar horário
              </Typography>
            </IconButton>
          )}
        </Box>
      );
    });
  };

  return (
    <Container sx={{ padding: { xs: '0', md: '100px 0 5px' } }}>
      <FormContainer size={{ small: smallSize, medium: mediumSize }} elevation={7}>
        <Box display='flex' flexDirection='row' gap={1} mb={3} flexWrap='wrap'>
          <Typography variant={smallSize ? 'h5' : 'h4'} color='red'>
            Configuração da sua agenda
          </Typography>
        </Box>

        <TabContext value={tabSelect}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: 'divider',
              width: '100%',
            }}
          >
            <TabList
              variant='scrollable'
              scrollButtons='auto'
              allowScrollButtonsMobile
              onChange={(_, newValue) => {
                setTabSelect(newValue);
              }}
            >
              {days.map((day) => {
                return <Tab key={day.day} label={day.weekDay} value={day.day} />;
              })}
            </TabList>
          </Box>
          {days.map((mapDay) => {
            const activeDay = activeDays.includes(Number(mapDay.day));
            return (
              <TabPanel key={mapDay.day} value={mapDay.day.toString()}>
                <Box
                  sx={{
                    overflowY: 'auto',
                    height: smallSize ? '100%' : '500px',
                    width: smallSize ? '300px' : '500px',
                  }}
                  display='flex'
                  flexDirection={'column'}
                >
                  <Box display='flex' alignItems='left' mb={2}>
                    <FormControlLabel
                      control={
                        <Switch
                          onChange={(e, checked) => {
                            if (checked) {
                              setActiveDays([...activeDays, Number(mapDay.day)]);
                            } else {
                              setActiveDays(
                                activeDays.filter((active) => active !== Number(mapDay.day)),
                              );
                            }
                          }}
                          checked={activeDay}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      }
                      label='Atenderei esse dia'
                    />
                  </Box>
                  {renderConfigs(Number(mapDay.day), activeDay)}
                </Box>
                <Box display='flex' alignItems='flex-end' justifyContent='right'>
                  <Button variant='contained' onClick={handleSubmit(handleSaveDaysConfig)}>
                    Salvar
                  </Button>
                </Box>
              </TabPanel>
            );
          })}
        </TabContext>
      </FormContainer>
    </Container>
  );
}

export default AvailabilityConfig;
