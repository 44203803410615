import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  useMediaQuery,
  Button,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import { Img, Thumb, ThumbInner } from '../InputFile/inputFile.styles';

import {
  CalendarMonth,
  Cancel,
  CheckCircle,
  Close,
  HourglassEmpty,
  MonetizationOn,
} from '@mui/icons-material';
import {
  OptionItemT,
  OptionsInfoT,
  SchedulingT,
  useLazyGetSchedulingPaymentUrlQuery,
  useRemoveSchedulingMutation,
} from '../../app/api/schedulingAPI';
import React from 'react';
import { formatDate, phoneFormat } from '../../utils/formats';
import { ListScheduleItem } from './ListScheduleItem';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { getMe } from '../../app/slices/userSlice';
import { theme } from '../../theme';
import { setToastNotification } from '../../app/slices/appSlice';

interface ScheduleDetailModalI {
  data?: SchedulingT;
  open: boolean;
  onClose: () => void;
  isAdmin?: boolean;
}

export const formatStatusIcon = (scheduling_state: string) => {
  switch (scheduling_state) {
    case 'Finalizado':
      return (
        <Box display='flex' alignItems='center' justifyContent='center'>
          <CheckCircle color='success' sx={{ mb: 0.5, mr: 1 }} fontSize='small' />
          <Typography variant='caption'>Finalizado</Typography>
        </Box>
      );
    case 'Agendado':
      return (
        <Box display='flex' alignItems='center' justifyContent='center'>
          <CalendarMonth color='success' sx={{ mb: 0.5, mr: 1 }} fontSize='small' />
          <Typography variant='caption'>Agendado</Typography>
        </Box>
      );
    case 'Aguardando Pagamento':
      return (
        <Box display='flex' alignItems='center' justifyContent='center'>
          <HourglassEmpty color='warning' sx={{ mb: 0.5, mr: 1 }} fontSize='small' />
          <Typography variant='caption'>Aguardando pagamento</Typography>
        </Box>
      );
    case 'Erro no pagamento':
      return (
        <Box display='flex' alignItems='center' justifyContent='center'>
          <MonetizationOn color='error' sx={{ mb: 0.5, mr: 1 }} fontSize='small' />
          <Typography variant='caption'>Erro no pagamento</Typography>
        </Box>
      );
    case 'Cancelado':
      return (
        <Box display='flex' alignItems='center' justifyContent='center'>
          <Cancel color='error' sx={{ mb: 0.5, mr: 1 }} fontSize='small' />
          <Typography variant='caption'>Cancelado</Typography>
        </Box>
      );

    default:
      return scheduling_state;
  }
};

export const ScheduleDetailModal = ({ data, open, onClose, isAdmin }: ScheduleDetailModalI) => {
  const [getPaymentUrl, { data: paymentUrlData, isSuccess }] =
    useLazyGetSchedulingPaymentUrlQuery();
  const me = useAppSelector(getMe);
  const dispatch = useAppDispatch();
  const smallSize = useMediaQuery(theme.breakpoints.down('sm'));
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [aditionalValue, setAditionalValue] = React.useState<number>(0);
  const [confirmModalData, setConfirmModalData] = React.useState<boolean>(false);

  const [removeAppointments, { isSuccess: isSuccessRemove }] = useRemoveSchedulingMutation();

  const calcAditionals = (scheduling: SchedulingT) => {
    const addValue = scheduling.options.reduce((acc: number, cur: OptionsInfoT) => {
      const totalSelected = cur.option_items.reduce((accItem: number, curItem: OptionItemT) => {
        return (accItem += curItem.selected_quantity);
      }, 0);
      if (!cur.min_quantity || totalSelected > cur.min_quantity) {
        let count = 0;
        return (acc += cur.option_items.reduce((accOptionItem, curOptionItem) => {
          count += curOptionItem.selected_quantity;
          if (count > (cur.min_quantity ?? 0)) {
            return (accOptionItem += curOptionItem.value * curOptionItem.selected_quantity);
          }
          return accOptionItem;
        }, 0));
      }
      return acc;
    }, 0);

    setAditionalValue(addValue);
  };

  const handleClickCodyLink = () => {
    if (paymentUrlData?.payment_url) {
      window.location.href = paymentUrlData.payment_url;
    }
  };

  const copyPaymentUrlToClipboard = async (payment_url: string) => {
    if (navigator.clipboard) {
      return await navigator.clipboard
        .writeText(payment_url)
        .then(() => {
          return true;
        })
        .catch(() => {
          return false;
        });
    }
  };

  const onClickContinueRemove = () => {
    if (data?._id) {
      removeAppointments({ _id: data._id });
    }
  };

  React.useEffect(() => {
    if (!open && isSuccess && paymentUrlData && paymentUrlData.payment_url) {
      copyPaymentUrlToClipboard(paymentUrlData?.payment_url || '').then((result) => {
        if (result) {
          dispatch(
            setToastNotification({
              type: 'success',
              open: true,
              message: 'Link do pagamento copiado com sucesso!',
            }),
          );
        } else {
          dispatch(
            setToastNotification({
              type: 'error',
              open: true,
              message: 'Erro ao copiar o link de pagamento!',
            }),
          );
        }
      });
      dispatch(
        setToastNotification({
          type: 'success',
          open: true,
          message: 'Link do de pagamento copiado com sucesso!',
        }),
      );
    }
  }, [isSuccess, paymentUrlData]);

  React.useEffect(() => {
    if (open) {
      if (data && !data?.menu_id.is_catering) {
        calcAditionals(data);
      }
      if (
        data?._id &&
        ['Aguardando Pagamento', 'Erro no pagamento'].includes(data.scheduling_state)
      ) {
        getPaymentUrl(data?._id);
      }
    }
  }, [open, data]);

  React.useEffect(() => {
    if (isSuccessRemove) {
      dispatch(
        setToastNotification({
          type: 'success',
          open: true,
          message: 'Agendamento cancelado com sucesso!',
        }),
      );
    }
    setConfirmModalData(false);
    onClose();
  }, [isSuccessRemove]);

  return (
    <Dialog
      open={open}
      onClose={(event: object, reason: string) => {
        if (reason !== 'backdropClick') {
          onClose();
        }
      }}
      fullWidth
      maxWidth='sm'
    >
      <Box display='flex' alignItems='center' justifyContent='center' flexDirection='row'>
        <DialogTitle
          align='center'
          variant={smallSize ? 'h5' : 'h4'}
          color='primary'
          display='flex'
        >
          Detalhes do agendamento
        </DialogTitle>
        <Box
          display='flex'
          justifyContent='flex-end'
          alignItems='flex-end'
          position='relative'
          left={smallSize ? -10 : 30}
          bottom={3}
        >
          <IconButton onClick={onClose}>
            <Close fontSize='small' color='error' />
          </IconButton>
        </Box>
      </Box>
      <DialogContent
        sx={{
          sm: {
            marginX: 2,
            paddingX: 2,
          },
          xl: {
            marginX: 5,
            paddingX: 5,
          },
        }}
      >
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          flexDirection='column'
          gap={1}
        >
          <Box display='flex' justifyContent='center' alignItems='center'>
            <Thumb>
              <ThumbInner>
                <Img src={data?.menu_id.picture_url} />
              </ThumbInner>
            </Thumb>
          </Box>

          <Typography variant='body1' color='primary' fontWeight='bold'>
            {data?.menu_id.name}{' '}
            {!data?.menu_id.is_catering && `para ${data?.number_of_guests} Pessoas`}
          </Typography>

          {isAdmin || me?.user_type === 'chef' ? (
            <Box
              display='flex'
              justifyContent='center'
              alignItems='center'
              flexDirection={smallSize ? 'column' : 'row'}
              gap={1}
            >
              <Typography variant='caption' color='primary' fontWeight='bold'>
                Cliente:
              </Typography>
              <Typography variant='caption'>{data?.client_id.name}</Typography>
              <Typography variant='caption'>{data?.client_id.email}</Typography>
              <Typography variant='caption'>{phoneFormat(data?.client_id.phone)}</Typography>
            </Box>
          ) : null}

          {isAdmin || me?.user_type === 'client' ? (
            <Box
              display='flex'
              justifyContent='center'
              alignItems='center'
              flexDirection={isMobile ? 'column' : 'row'}
              gap={1}
            >
              <Typography variant='caption' color='primary' fontWeight='bold'>
                Chef:
              </Typography>
              <Typography variant='caption'>{data?.chef_user_id.name}</Typography>
              <Typography variant='caption'>{data?.chef_user_id.email}</Typography>
              <Typography variant='caption'>{phoneFormat(data?.chef_user_id.phone)}</Typography>
            </Box>
          ) : null}

          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            flexDirection={isMobile ? 'column' : 'row'}
            gap={1}
          >
            <Typography variant='caption' color='primary' fontWeight='bold'>
              Local:
            </Typography>
            <Typography variant='caption'>
              {data?.address}
              {data?.address_complement && ` - ${data?.address_complement}`}
            </Typography>
          </Box>

          {data && ['Aguardando Pagamento', 'Erro no pagamento'].includes(data.scheduling_state) ? (
            <Box
              display='flex'
              justifyContent='center'
              alignItems='center'
              flexDirection='row'
              gap={1}
            >
              <Button variant='outlined' onClick={handleClickCodyLink}>
                Realizar pagamento
              </Button>

              {'Aguardando Pagamento' === data.scheduling_state ? (
                <Button variant='contained' onClick={() => setConfirmModalData(true)}>
                  Cancelar agendamento
                </Button>
              ) : (
                true
              )}
            </Box>
          ) : (
            true
          )}

          <Box display='flex' justifyContent='flex-end' alignItems='center' mt={1} mb={2}>
            {formatStatusIcon(data?.scheduling_state ?? '')}
          </Box>
        </Box>

        <Box alignItems='center' alignSelf='center' justifyContent={'center'} mb={2}>
          <Box display='flex' justifyContent='center' alignItems='center' flexDirection='column'>
            <ListScheduleItem data={data} aditionalValue={aditionalValue} />
          </Box>
        </Box>

        <Box
          display='flex'
          justifyContent='space-around'
          alignItems='center'
          flexDirection={isMobile ? 'column' : 'row'}
          gap={3}
        >
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            flexDirection='column'
            mt={2}
          >
            <Typography variant='caption' color='primary' fontWeight='bold'>
              Data e hora que será servido
            </Typography>
            <Box textAlign='end'>
              <Typography variant='caption'>
                {formatDate(data?.event_date ?? '', 'dd/MM/yyyy')} -{' '}
                {formatDate(data?.event_date ?? '', 'HH:mm')}
              </Typography>
            </Box>
          </Box>
          {data?.observation ? (
            <Box
              display='flex'
              justifyContent='center'
              alignItems='center'
              flexDirection='column'
              mt={2}
              width={250}
            >
              <Typography variant='caption' color='primary' fontWeight='bold'>
                Observações:
              </Typography>
              <Box display='flex' justifyContent='center' alignItems='center'>
                <Typography variant='caption'>{data?.observation}</Typography>
              </Box>
            </Box>
          ) : (
            true
          )}
        </Box>
      </DialogContent>

      <Dialog
        open={Boolean(confirmModalData)}
        onClose={() => {
          setConfirmModalData(false);
        }}
        aria-labelledby='confirm-dialog'
        aria-describedby='confirm-dialog'
      >
        <DialogTitle id='confirm-dialog-title'>Tem certeza que deseja cancelar?</DialogTitle>
        <DialogContent>
          <DialogContentText id='confirm-dialog-description'>
            Antes de cancelar, tenha certeza disso. Essa ação é irreversível.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmModalData(false)} color='secondary'>
            Fechar
          </Button>
          <Button onClick={() => confirmModalData && onClickContinueRemove()} autoFocus>
            Continuar
          </Button>
        </DialogActions>
      </Dialog>
    </Dialog>
  );
};
