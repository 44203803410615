import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

export const ImpactText = styled(Typography)(({ theme }) => ({
  color: theme.palette.black.main,
  lineHeight: '50px',
}));

export const StyledImg = styled('img')(() => ({
  width: '500px',
}));

export const ChefStepsTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.white.main,
  fontWeight: '700',
  lineHeight: '50px',
}));

export const ChefSteps = styled(Typography)(({ theme }) => ({
  color: theme.palette.white.main,
  fontWeight: 'normal',
}));

export const StyledChefLi = styled('li')(({ theme }) => ({
  color: theme.palette.white.main,
}));

export const CateringStepsTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontWeight: '700',
  lineHeight: '50px',
}));

export const CateringSteps = styled(Typography)(({ theme }) => ({
  color: theme.palette.black.main,
  fontWeight: 'normal',
}));

export const StyledCateringLi = styled('li')(({ theme }) => ({
  color: theme.palette.black.main,
}));

export const StyledOl = styled('ol')(() => ({
  paddingLeft: '20px',
}));

export const QuestionsTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.black.main,
  textAlign: 'center',
  fontWeight: '700',
  lineHeight: '50px',
}));

export const QuestionsSubTitle = styled(Typography)(() => ({
  fontWeight: 'normal',
  lineHeight: '50px',
  textAlign: 'center',
}));

export const Question = styled(Typography)(() => ({
  lineHeight: '40px',
  marginTop: '10px',
  fontWeight: '700',
}));

export const Answer = styled(Typography)(({ theme }) => ({
  color: theme.palette.black.main,
  fontWeight: 'normal',
  lineHeight: '30px',
}));
