import React from 'react';
import MenuCard from '../MenuCard';

import { CarouselWrapper, CarouselItem } from './categoryMenuCarousel.styles';

type CategoryCarouselPropsT = {
  items: {
    menuId: string;
    menuImage: string;
    menuName: string;
    menuPrice?: number;
    isFavorite: boolean;
    chefId: string;
    chefImage: string;
    chefName: string;
    chefRating: string;
    chefExperiences: number;
  }[];
  onChefClick: (chefId: string) => void;
  onFavoriteClick: (menuId: string) => void;
  onMenuClick: (menuId: string) => void;
  onShareClick: (menuId: string) => void;
  isFavoritePage?: boolean;
};

const CategoryMenuCarousel = ({
  items,
  onChefClick,
  onFavoriteClick,
  onMenuClick,
  onShareClick,
  isFavoritePage = false,
}: CategoryCarouselPropsT) => {
  return (
    <CarouselWrapper favorite={{ isFavorite: isFavoritePage }}>
      {items.map((item) => (
        <CarouselItem key={item.menuId}>
          <MenuCard
            menuId={item.menuId}
            menuImage={item.menuImage}
            menuName={item.menuName}
            menuPrice={item.menuPrice}
            isFavorite={item.isFavorite}
            chefId={item.chefId}
            chefImage={item.chefImage}
            chefName={item.chefName}
            chefRating={item.chefRating}
            chefExperiences={item.chefExperiences}
            onChefClick={onChefClick}
            onFavoriteClick={onFavoriteClick}
            onMenuClick={onMenuClick}
            onShareClick={onShareClick}
          />
        </CarouselItem>
      ))}
    </CarouselWrapper>
  );
};

export default CategoryMenuCarousel;
