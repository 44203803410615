import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';

type BookingChefMenuItemT = {
  label: string;
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  shouldShowPrice: boolean;
  disabled?: boolean;
  price?: number;
  optionalDescription?: string;
  isMobile: boolean;
};

const BookingChefMenuItem = ({
  label,
  checked,
  onChange,
  shouldShowPrice,
  disabled,
  price,
  optionalDescription,
  isMobile,
}: BookingChefMenuItemT) => {
  return (
    <Box display='flex' alignItems='center' marginTop={isMobile ? 2.5 : 1.5}>
      <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            onChange={onChange}
            inputProps={{ 'aria-label': 'controlled' }}
            disabled={disabled}
          />
        }
        label={
          <>
            <b>{label}</b>
            {`${optionalDescription ? ` - ${optionalDescription}` : ''}`}
          </>
        }
      />
      {shouldShowPrice && (
        <Typography color='primary' ml={1} minWidth='fit-content'>
          {`R$ ${String(price?.toFixed(2))?.replace('.', ',')}`} pp
        </Typography>
      )}
    </Box>
  );
};

export default BookingChefMenuItem;
