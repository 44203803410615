import { apiBase } from '../../service';

export type ItemT = {
  _id?: string;
  name: string;
  description: string;
  active?: boolean;
};

export const ItemService = apiBase.injectEndpoints({
  endpoints: (builder) => ({
    createItem: builder.mutation<ItemT, ItemT>({
      query: (item) => ({
        url: '/item',
        method: 'POST',
        body: item,
      }),
      invalidatesTags: ['Item'],
    }),
    getItems: builder.query<ItemT[], undefined>({
      query: () => ({
        url: '/item',
      }),
      providesTags: ['Item'],
    }),
    updateItem: builder.mutation<ItemT, ItemT>({
      query: ({ _id, name, description, active }) => ({
        url: `/item/${_id}`,
        method: 'PUT',
        body: { name, description, active },
      }),
      invalidatesTags: ['Item'],
    }),
  }),
});

export const { useCreateItemMutation, useGetItemsQuery, useUpdateItemMutation } = ItemService;
