import * as React from 'react';

import { Toolbar, IconButton, Typography, Container, Avatar, MenuItem, Menu } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate, Link as RouterLink } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import Logo from '../../assets/images/LogoWhite.png';

import {
  StyledAppBar,
  StyledBoxMobileMenu,
  StyledBoxWebMenu,
  StyledButton,
  StyledLoginTypography,
  StyledLogo,
  StyledUserAvatar,
} from './header.styles';
import { theme } from '../../theme';
import { getMe, getToken, logout } from '../../app/slices/userSlice';
import { setLoading } from '../../app/slices/appSlice';
import { removeLocationCookie } from '../../app/slices/locationSlice';

function Header() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const token = useAppSelector(getToken);
  const me = useAppSelector(getMe);
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElNavUser, setAnchorElNavUser] = React.useState<null | HTMLElement>(null);

  const loggedOffPages = [
    { name: 'Explorar Menus', navigate: '/explore' },
    { name: 'Catering', navigate: '/catering' },
    { name: 'Como Funciona', navigate: '/how-it-works' },
    { name: 'Contato', navigate: '/contact' },
    { name: 'Termos de Serviço', navigate: '/terms' },
  ];

  const clientPages = [
    { name: 'Explorar Menus', navigate: '/explore' },
    { name: 'Catering', navigate: '/catering' },
    { name: 'Agenda', navigate: '/schedule' },
    { name: 'Como Funciona', navigate: '/how-it-works' },
    { name: 'Contato', navigate: '/contact' },
    { name: 'Termos de Serviço', navigate: '/terms' },
  ];

  const chefPages = [
    { name: 'Configurar Agenda', navigate: '/availability-config' },
    { name: 'Agenda', navigate: '/schedule' },
    { name: 'Contato', navigate: '/contact' },
    { name: 'Termos de Serviço', navigate: '/terms' },
  ];

  const adminPages = [
    { name: 'Dashboard', navigate: '/admin' },
    { name: 'Agendamentos', navigate: '/admin/appointments-per-client' },
    { name: 'Categorias', navigate: '/admin/category' },
    { name: 'Chefes', navigate: '/admin/chef' },
    { name: 'Cupom', navigate: '/admin/discount-coupon' },
    { name: 'Menus', navigate: '/admin/menu' },
    { name: 'Itens', navigate: '/admin/item' },
    { name: 'Contatos', navigate: '/admin/contact' },
    { name: 'Config do Sistema', navigate: '/admin/system-setting' },
  ];

  const pages = (() => {
    if (me) {
      switch (me.user_type) {
        case 'admin':
          return adminPages;
        case 'chef':
          return chefPages;
        case 'client':
          return clientPages;
        default:
          return loggedOffPages;
      }
    } else {
      return loggedOffPages;
    }
  })();

  const userOptions = !me
    ? ['Entrar', 'Cadastrar']
    : me.user_type === 'client'
    ? ['Perfil', 'Meus Agendamentos', 'Favoritos', 'Sair']
    : ['Perfil', 'Sair'];

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
    setAnchorElNavUser(null);
  };

  const handleMenuOptionClick = () => {
    setAnchorElNav(null);
  };

  const handleUserClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNavUser(event.currentTarget);
  };

  const handleMenuUserOptionClick = (option: string) => {
    switch (option) {
      case 'Entrar':
        navigate('/sign-in');
        break;
      case 'Cadastrar':
        navigate('/sign-up');
        break;
      case 'Perfil':
        if (me?.user_type === 'client') {
          navigate('/client-profile');
        } else if (me?.user_type === 'chef') {
          navigate('/chef-own-profile');
        } else if (me?.user_type === 'admin') {
          navigate('/admin-profile');
        }
        break;
      case 'Meus Agendamentos':
        navigate('/schedule');
        break;
      case 'Favoritos':
        navigate('/favorites');
        break;
      case 'Sair':
        dispatch(setLoading(true));
        navigate('/');
        setTimeout(() => {
          dispatch(logout());
          dispatch(setLoading(false));
        }, 1000);
        break;
      default:
        break;
    }
    removeLocationCookie();
    handleCloseNavMenu();
  };

  return (
    <StyledAppBar position='fixed'>
      <Container maxWidth='xl'>
        <Toolbar disableGutters>
          <StyledBoxMobileMenu sx={{ display: { xs: 'flex', md: 'none' } }}>
            <Menu
              id='menu-appbar'
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
            >
              {pages.map((page) => (
                <RouterLink
                  key={page.name}
                  to={page.navigate}
                  style={{ textDecoration: 'none', color: theme.palette.muiTypographyText.main }}
                >
                  <MenuItem onClick={() => handleMenuOptionClick()}>
                    <Typography textAlign='center'>{page.name}</Typography>
                  </MenuItem>
                </RouterLink>
              ))}
            </Menu>
            <IconButton
              size='large'
              aria-label='account of current user'
              aria-controls='menu-appbar'
              aria-haspopup='true'
              onClick={handleOpenNavMenu}
              color='inherit'
            >
              <MenuIcon />
            </IconButton>
          </StyledBoxMobileMenu>
          <RouterLink
            to='/'
            style={{ textDecoration: 'none', color: theme.palette.muiTypographyText.main }}
          >
            <StyledLogo src={Logo} alt='Logo' />
          </RouterLink>
          <StyledBoxWebMenu sx={{ display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page) => (
              <RouterLink key={page.name} to={page.navigate} style={{ textDecoration: 'none' }}>
                <StyledButton onClick={() => handleMenuOptionClick()}>{page.name}</StyledButton>
              </RouterLink>
            ))}
          </StyledBoxWebMenu>
          <StyledUserAvatar>
            <IconButton onClick={handleUserClick}>
              {me?.name && token ? (
                <Avatar alt='User Avatar'>{me?.name.charAt(0)}</Avatar>
              ) : (
                <Avatar alt='User Avatar' />
              )}
              <StyledLoginTypography textAlign='center'>
                {me?.name && token ? `Olá, ${me?.name.split(' ')[0]}` : 'Fazer login'}
              </StyledLoginTypography>
            </IconButton>
          </StyledUserAvatar>
          <Menu
            id='menu-user'
            anchorEl={anchorElNavUser}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            open={Boolean(anchorElNavUser)}
            onClose={handleCloseNavMenu}
          >
            {userOptions.map((option) => (
              <MenuItem key={option} onClick={() => handleMenuUserOptionClick(option)}>
                <Typography textAlign='center'>{option}</Typography>
              </MenuItem>
            ))}
          </Menu>
        </Toolbar>
      </Container>
    </StyledAppBar>
  );
}
export default Header;
