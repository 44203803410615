import { apiBase } from '../../service';
import { MenuForCategoryT } from '../categoryAPI';

export type FavoriteT = {
  _id?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  menus?: MenuForCategoryT[] | any[];
};

export const FavoriteService = apiBase.injectEndpoints({
  endpoints: (builder) => ({
    createFavorite: builder.mutation<FavoriteT, { menu_id: string }>({
      query: ({ menu_id }) => ({
        url: '/favorite',
        method: 'POST',
        body: { menu_id },
      }),
      invalidatesTags: ['Favorite', 'ShowCaseCategory', 'Category'],
    }),
    removeFavorite: builder.mutation<FavoriteT, { menu_id: string }>({
      query: ({ menu_id }) => ({
        url: '/favorite',
        method: 'DELETE',
        body: { menu_id },
      }),
      invalidatesTags: ['Favorite', 'ShowCaseCategory', 'Category'],
    }),
    getFavorites: builder.query<FavoriteT, { populated: boolean } | undefined>({
      query: (data) => {
        return {
          url: `/favorite${data?.populated ? '?populate=true' : ''}`,
        };
      },
      providesTags: ['Favorite'],
    }),
  }),
});

export const { useCreateFavoriteMutation, useRemoveFavoriteMutation, useGetFavoritesQuery } =
  FavoriteService;
