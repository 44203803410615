import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { ScrollContainer } from 'react-indiana-drag-scroll';

type CarouselItemT = {
  isFavorite?: boolean;
};

export const CarouselWrapper = styled(ScrollContainer)<{ favorite: CarouselItemT }>(
  ({ favorite }) => ({
    width: '100%',
    display: favorite.isFavorite ? 'flex' : '-webkit-box',
    justifyContent: 'flex-start',
    alignItems: 'center',
    overflowX: !favorite.isFavorite ? 'auto' : 'hidden',
    overflowY: 'hidden',
    flexWrap: favorite.isFavorite ? 'wrap' : 'nowrap',
  }),
);

export const CarouselItem = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '10px 20px',
  flexDirection: 'column',
  cursor: 'pointer',
}));
