import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles/createPalette' {
  interface Palette {
    primaryTitle: Palette['primary'];
    black: Palette['primary'];
    white: Palette['primary'];
    greyAE: Palette['primary'];
    yellow: Palette['primary'];
    translucent: Palette['primary'];
    muiBorder: Palette['primary'];
    muiInputText: Palette['primary'];
    muiTypographyText: Palette['primary'];
    gold: Palette['primary'];
    creamBg: Palette['primary'];
  }
  interface PaletteOptions {
    primaryTitle: PaletteOptions['primary'];
    black: PaletteOptions['primary'];
    white: PaletteOptions['primary'];
    greyAE: PaletteOptions['primary'];
    yellow: PaletteOptions['primary'];
    translucent: PaletteOptions['primary'];
    muiBorder: PaletteOptions['primary'];
    muiInputText: PaletteOptions['primary'];
    muiTypographyText: PaletteOptions['primary'];
    gold: PaletteOptions['primary'];
    creamBg: PaletteOptions['primary'];
  }
}

export const theme = createTheme({
  palette: {
    primary: {
      main: '#AC3C49',
      contrastText: '#ffffff',
    },
    primaryTitle: {
      main: '#CF1C45',
    },
    secondary: {
      main: '#0A3BF3',
      contrastText: '#ffffff',
    },
    black: {
      main: '#000000',
      contrastText: '#ffffff',
    },
    white: {
      main: '#ffffff',
      contrastText: '#000000',
    },
    greyAE: {
      main: '#919191',
      contrastText: '#000000',
    },
    yellow: {
      main: '#FEFE50',
      contrastText: '#000000',
    },
    translucent: {
      main: '#ffffffb3',
      contrastText: '#000000',
    },
    muiBorder: {
      main: 'rgba(0, 0, 0, 0.23)',
      contrastText: '#000000',
    },
    muiInputText: {
      main: 'rgba(0, 0, 0, 0.6)',
      contrastText: '#ffffff',
    },
    muiTypographyText: {
      main: '#1A2027',
    },
    gold: {
      main: '#E3C260',
    },
    creamBg: {
      main: '#f9f5f2'
    }
  },
});
