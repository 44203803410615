interface BodyTermMapProps {
  text?: string;
  obs?: BodyTermMapProps[];
}

interface TermMapProps {
  clause: string;
  body: BodyTermMapProps[];
}

export const termMap: TermMapProps[] = [
  {
    clause: '1. Obrigações da Abouteat',
    body: [
      {
        text: `a) Providenciar a compra de todos os gêneros alimentícios necessários ao preparo e
    fornecimento das refeições;`,
      },
      {
        text: 'b) Chegar ao local especificado com a devida antecedência em relação ao horário selecionado no agendamento;',
      },
      {
        text: 'c) preparar e distribuir as refeições aos convidados do CONTRATANTE;',
      },
      {
        text: 'd) Zelar pela conservação de todos os bens cedidos pelo CONTRATANTE;',
      },
      {
        text: 'e) Promover a limpeza da cozinha e utensílios, louças, talheres dentre outros utilizados na preparação das refeições;',
      },
      {
        text: 'f) Assegurar que toda a equipe envolvida na prestação dos serviços trabalhe com excelência e esteja adequadamente trajada;',
      },
    ],
  },
  {
    clause: '2. Obrigações do contratante',
    body: [
      {
        text: 'a) Fornecer toda a infra-estrutura necessária para realização do evento contratado;',
      },
      {
        text: `b) Dimensionar corretamente o número de convidados, considerando que a quantidade de comida levada será de acordo com este número.
        Se forem menos convidados do que o contratado, não haverá devolução de valor, uma vez que toda a preparação já terá sido feita.
        Se forem mais convidados, o contratante assume toda responsabilidade pela eventual falta de comida.
        Além disso, a Abouteat reserva-se ao direito de cobrar o equivalente aos convidados extras, em função da sobrecarga de trabalho da equipe.`,
      },
      {
        text: `c) Caso o evento possua louça alugada e haja quebra de itens,
        o contratante será responsabilizado pela reposição ou pagamento dos mesmos.`,
      },
      {
        text: 'd) O pagamento de valores adicionais como louças quebradas ou número extra de convidados deve ser feito em até 7 dias úteis após o evento, na seguinte conta: 92413632-6, agencia: 0001, banco: 0260 * Nu Pagamentos S.A Instituição de Pagamento',
      },
      {
        text: 'e) No agendamento, considerar o horário que deseja ser servido para o agendamento. Com isso o chefe chegará com a devida antecedência ao horário selecionado para realizar o serviço.',
      },
    ],
  },
  {
    clause: '3. Preços e formas de pagamento',
    body: [
      {
        text: 'a) A contratação do serviço pode ser feita via cartão de crédito ou depósito em conta;',
      },
      {
        text: 'b) O pagamento deve ser feito integralmente a Abouteat.',
      },
    ],
  },
  {
    clause: '4. Confirmação de reserva',
    body: [
      {
        text: 'a) Após envio do pedido de reserva pelo cliente, entraremos em contato para confirmar o evento',
      },
      {
        text: 'b) Caso não seja possível o atendimento para determinado evento, o pedido é cancelado e é estornada a cobrança.',
      },
    ],
  },
  {
    clause: '5. Duração do evento',
    body: [
      {
        text: 'a) O tempo estimado de presença da equipe no local do evento é de 2h a 4h, variando de acordo com tipo de menu escolhido.',
      },
    ],
  },
  {
    clause: '6. Cancelamentos',
    body: [
      {
        text: 'a) Caso ocorra algum imprevisto e seja necessário cancelar um serviço contratado, utilizaremos as regras a seguir:',
      },
      {
        text: `- Os agendamentos podem ser cancelados com estorno de 100% em até 2h após a confirmação de pagamento na Abouteat.
            Após as 2 horas, a política adotada será: Se cancelado pelo contratante, a contratada estornará 100% do valor já pago,
            desde que a solicitação ocorra com mais de 6 dias corridos de antecedência do evento. Se houver entre 6 a 4 dias restantes para o evento,
            a contratante tem direito a reembolso de 50% do valor do pedido. Se o evento estiver a menos de 72 horas, a contratante não será reembolsada.`,
      },
      {
        text: `- No caso do chef escolhido pela contratante ter de cancelar a reserva,
            a contratada oferece providenciar outro chef com um menu semelhante e que a contratante esteja de acordo.
            Caso a contratada não possa providenciar outro chef a tempo ou a contratante não esteja de acordo com a alteração,
            a contratada reembolsará 100% a contratante, oferecerá um agendamento gratuito dentro da plataforma Abouteat e também um cupom de desconto de 10%
            como crédito para utilização na plataforma Abouteat.`,
      },
    ],
  },
  {
    clause: '7. Atrasos',
    body: [
      {
        text: `- No caso do chef escolhido pela contratante ter algum imprevisto que acarrete em atraso,
            entraremos em contato com a contratante informando a previsão atualizada de horário de chegada.
            Caso o chefe informe que acarretará em um atraso para servir a refeição superior a 30 minutos do horário selecionado no agendamento,
            a contratante pode solicitar o cancelamento com 100% de reembolso.`,
      },
      {
        text: `- No caso do chef chegar ao local indicado com a antecedência necessária para realizar o serviço considerando servir no horário do agendamento,
            e a contratante não se encontrar ou não autorizar a sua entrada para início dos preparativos,
            o chefe poderá aguardar até 30 minutos pela liberação e o atraso para servir a refeição será de responsabilidade da contratante,
            isentando o chef e a abouteat de qualquer ônus. Caso passem os 30 minutos e não seja liberada a sua entrada,
            o chef poderá ir embora e não haverá reembolso do agendamento.`,
      },
    ],
  },
  {
    clause: '8. Discriminação e assédio',
    body: [
      {
        text: `A abouteat é contra qualquer conduta, ação ou omissão que resulte em situações constrangedoras, humilhantes, vexatórias ou discriminatórias.
            Denúncias de casos de discriminação, assédio sexual e de assédio moral poderão ser levadas a Abouteat para análise, encaminhamento e indicação, conforme o caso,
            de comissão de apuração. Havendo a comprovação da denúncia, a respeito de má conduta direcionada ao chefe,
            o mesmo poderá se dar ao direito de não concluir o serviço e deixar o ambiente do fato ocorrido,
            não serão feitos reembolsos a contratante nesses casos comprovados e a Abouteat se reserva o direito de não aceitar mais agendamentos do contratante em questão.
            Havendo a comprovação da denúncia, a respeito de má conduta do chefe direcionada a contratante,
            o mesmo poderá se dar ao direito de interromper o serviço e solicitar a saída do chefe do ambiente do fato ocorrido,
            nesses casos comprovados a Abouteat realizará o reembolso do agendamento e afastará o chefe envolvido da plataforma.`,
      },
    ],
  },
];
