import { apiBase } from '../../service';
import { PricesPerPersonI } from '../menuAPI';

export type MenuForCategoryT = {
  _id?: string;
  name: string;
  prices_per_person: PricesPerPersonI[];
  picture_url: string;
  chef_user_id: {
    _id: string;
    name: string;
    email: string;
    chef_id: {
      _id: string;
      picture_url: string;
    };
  };
};

export type CategoryT = {
  _id?: string;
  title: string;
  sub_title: string;
  icon_name: string;
  order: number;
  is_showcase?: boolean;
  active?: boolean;
  menus?: MenuForCategoryT[];
};

export type CategoryMutationT = {
  _id?: string;
  title: string;
  sub_title: string;
  icon_name: string;
  order: number;
  is_showcase?: boolean;
  active?: boolean;
  menus?: string[];
};

export type GetCategoryQueryParamsT = {
  locationLat?: string;
  locationLong?: string;
  search?: string;
};

export const CategoryService = apiBase.injectEndpoints({
  endpoints: (builder) => ({
    createCategory: builder.mutation<CategoryMutationT, CategoryMutationT>({
      query: ({ title, sub_title, icon_name, order, is_showcase, active, menus }) => ({
        url: '/category',
        method: 'POST',
        body: { title, sub_title, icon_name, order, is_showcase, active, menus },
      }),
      invalidatesTags: ['Category'],
    }),
    getAllCategories: builder.query<CategoryT[], undefined>({
      query: () => ({
        url: '/category?all=true',
      }),
      providesTags: ['Category'],
    }),
    getCategories: builder.query<CategoryT[], GetCategoryQueryParamsT | undefined>({
      query: (queryParams) => {
        let obj = {};
        if (queryParams?.locationLat) obj = { ...obj, locationLat: queryParams?.locationLat };
        if (queryParams?.locationLong) obj = { ...obj, locationLong: queryParams?.locationLong };
        if (queryParams?.search) obj = { ...obj, search: queryParams?.search };
        return {
          url: '/category',
          params: obj,
        };
      },
      providesTags: ['Category'],
    }),
    getShowCaseCategories: builder.query<CategoryT[], GetCategoryQueryParamsT | undefined>({
      query: (queryParams) => {
        let obj = {};
        if (queryParams?.locationLat) obj = { ...obj, locationLat: queryParams?.locationLat };
        if (queryParams?.locationLong) obj = { ...obj, locationLong: queryParams?.locationLong };
        if (queryParams?.search) obj = { ...obj, search: queryParams?.search };
        return {
          url: '/category?showcase=true',
          params: obj,
        };
      },
      providesTags: ['ShowCaseCategory'],
    }),
    getCategoriesCatering: builder.query<CategoryT[], GetCategoryQueryParamsT | undefined>({
      query: (queryParams) => {
        let obj = {};
        if (queryParams?.locationLat) obj = { ...obj, locationLat: queryParams?.locationLat };
        if (queryParams?.locationLong) obj = { ...obj, locationLong: queryParams?.locationLong };
        if (queryParams?.search) obj = { ...obj, search: queryParams?.search };
        return {
          url: '/category?is_catering=true',
          params: obj,
        };
      },
      providesTags: ['Category'],
    }),
    getShowCaseCategoriesCatering: builder.query<CategoryT[], GetCategoryQueryParamsT | undefined>({
      query: (queryParams) => {
        let obj = {};
        if (queryParams?.locationLat) obj = { ...obj, locationLat: queryParams?.locationLat };
        if (queryParams?.locationLong) obj = { ...obj, locationLong: queryParams?.locationLong };
        if (queryParams?.search) obj = { ...obj, search: queryParams?.search };
        return {
          url: '/category?showcase=true&is_catering=true',
          params: obj,
        };
      },
      providesTags: ['ShowCaseCategory'],
    }),
    updateCategory: builder.mutation<CategoryMutationT, CategoryMutationT>({
      query: ({ _id, title, sub_title, icon_name, order, is_showcase, active, menus }) => ({
        url: `/category/${_id}`,
        method: 'PUT',
        body: { title, sub_title, icon_name, order, is_showcase, active, menus },
      }),
      invalidatesTags: ['Category'],
    }),
  }),
});

export const {
  useCreateCategoryMutation,
  useGetAllCategoriesQuery,
  useLazyGetCategoriesQuery,
  useLazyGetShowCaseCategoriesQuery,
  useLazyGetCategoriesCateringQuery,
  useLazyGetShowCaseCategoriesCateringQuery,
  useUpdateCategoryMutation,
} = CategoryService;
