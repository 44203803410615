import React from 'react';

import { Box, Typography, useMediaQuery } from '@mui/material';
import BookingCateringMenuItem from '../BookingCateringMenuItem';
import BookingChefMenuItem from '../BookingChefMenuItem';

import { TitleContainer } from './bookingMenuConfig.styles';
import { MenuByIdConfigurationT } from '../../app/api/menuAPI';
import { OptionItemT } from '../../pages/Booking';
import { theme } from '../../theme';

type BookingMenuConfigT = {
  configuration: MenuByIdConfigurationT;
  isCatering?: boolean;
  option_items: OptionItemT[];
  setSelectedOption: (option: OptionItemT) => void;
};

const BookingMenuConfig = ({
  configuration,
  isCatering,
  option_items,
  setSelectedOption,
}: BookingMenuConfigT) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [sumSelectedQuantity, setSumSelectedQuantity] = React.useState<number>(0);

  React.useEffect(() => {
    let value = 0;
    for (let index = 0; index < option_items.length; index++) {
      const item = option_items[index];
      value = value + item.selected_quantity;
    }
    setSumSelectedQuantity(value);
  }, [option_items]);

  const findOption = (itemId: string) => {
    const op = option_items?.find((os) => os.option_item_id === itemId);
    return op ? op.selected_quantity : 0;
  };

  const handleSelectItem = (
    option_item_id: string,
    option_item_name: string,
    config_id: string,
    config_min_quantity: number,
    option_item_value?: number,
  ) => {
    if (findOption(option_item_id)) {
      setSelectedOption({
        option_item_id,
        option_item_name,
        selected_quantity: 0,
        option_item_value,
        config_min_quantity,
        config_id,
      });
    } else {
      setSelectedOption({
        option_item_id,
        option_item_name,
        selected_quantity: 1,
        option_item_value,
        config_min_quantity,
        config_id,
      });
    }
  };

  const shouldShowPrice = (option_item_id: string) => {
    const item = configuration.config_items.find((ci) => ci._id === option_item_id);
    if (!item) {
      return false;
    }
    if (item.value === undefined) {
      return false;
    }
    if (!configuration.min_quantity) {
      return true;
    }
    if (sumSelectedQuantity < configuration.min_quantity) {
      return false;
    }
    if (sumSelectedQuantity === configuration.max_quantity && !findOption(option_item_id)) {
      return false;
    }
    const optionItem = option_items.find((oi) => oi.option_item_id === option_item_id);
    if (optionItem) {
      const position = option_items.indexOf(optionItem) + 1;
      if (configuration.min_quantity < position) {
        return true;
      } else {
        return false;
      }
    }
    return true;
  };

  return (
    <Box marginY={2}>
      <TitleContainer>
        <Typography variant='h5' fontWeight='bold'>
          {configuration.name}
        </Typography>
      </TitleContainer>
      <Typography marginBottom={1}>{configuration.description}</Typography>
      {isCatering ? (
        <Box>
          {configuration.config_items.map((ci) => (
            <BookingCateringMenuItem
              key={ci._id}
              label={ci.item_id.name}
              optionalDescription={ci.optional_description}
              isMobile={isMobile}
              value={findOption(ci._id)}
              onChange={(value: number) =>
                setSelectedOption({
                  option_item_id: ci._id,
                  option_item_name: ci.item_id.name,
                  config_min_quantity: configuration.min_quantity || 0,
                  config_id: configuration._id,
                  option_item_value: ci?.value,
                  selected_quantity: value,
                })
              }
              maxValue={ci.max_quantity ?? 100}
              maxGeneralValue={
                configuration.max_quantity ? configuration.max_quantity - sumSelectedQuantity : 100
              }
              price={ci.value || 0}
            />
          ))}
        </Box>
      ) : (
        <Box>
          {configuration.config_items.map((ci) => (
            <BookingChefMenuItem
              key={ci._id}
              label={ci.item_id.name}
              optionalDescription={ci.optional_description}
              isMobile={isMobile}
              checked={Boolean(findOption(ci._id))}
              onChange={() =>
                handleSelectItem(
                  ci._id,
                  ci.item_id.name,
                  configuration._id,
                  configuration.min_quantity || 0,
                  ci.value,
                )
              }
              price={ci.value}
              shouldShowPrice={shouldShowPrice(ci._id)}
              disabled={
                configuration.max_quantity !== undefined &&
                configuration.min_quantity !== undefined &&
                ((!findOption(ci._id) &&
                  sumSelectedQuantity >= (configuration.min_quantity || 0) &&
                  ci.value === (null || undefined)) ||
                  (!findOption(ci._id) && sumSelectedQuantity >= (configuration.max_quantity || 0)))
              }
            />
          ))}
        </Box>
      )}
    </Box>
  );
};

export default BookingMenuConfig;
