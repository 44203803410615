import React from 'react';

import { CRUDTemplate } from '../../../components';
import { setToastNotification } from '../../../app/slices/appSlice';
import { useAppDispatch } from '../../../app/hooks';
import {
  MenuT,
  useCreateMenuMutation,
  useGetMenusQuery,
  useUpdateMenuMutation,
  useUploadMenuImagesMutation,
} from '../../../app/api/menuAPI';
import { useGetItemsQuery } from '../../../app/api/itemAPI';
import MenuModal, { MenuFormT } from './MenuModal';
import { useGetChefsQuery } from '../../../app/api/chefAPI';

function Menu() {
  const dispatch = useAppDispatch();
  const { data: items } = useGetItemsQuery(undefined);
  const { data: chefs } = useGetChefsQuery(undefined);
  const { data: menus } = useGetMenusQuery(undefined);
  const [createMenu, { isSuccess: isSuccessCreate, data: createdMenu }] = useCreateMenuMutation();
  const [updateMenu, { isSuccess: isSuccessUpdate }] = useUpdateMenuMutation();
  const [updateImages, { isSuccess: isSuccessImages }] = useUploadMenuImagesMutation();
  const [isModalOpen, setModalOpen] = React.useState<boolean>(false);
  const [menuToEdit, setMenuToEdit] = React.useState<MenuT | undefined>(undefined);
  const [pictureFile, setPictureFile] = React.useState<(File & { preview: string })[]>();
  const [albumpictureFile, setAlbumPictureFile] = React.useState<(File & { preview: string })[]>();

  const handleCancelModal = () => {
    setModalOpen(false);
    setMenuToEdit(undefined);
    setPictureFile(undefined);
    setAlbumPictureFile(undefined);
  };

  const uploadImages = async (needsUnwrap = false) => {
    const formData = new FormData();
    if (pictureFile?.length) {
      formData.append('picture', pictureFile[0]);
    }
    if (albumpictureFile?.length) {
      for (let i = 0; i < albumpictureFile.length; i++) {
        formData.append('album_picture', albumpictureFile[i]);
      }
    }
    const _id = menuToEdit ? menuToEdit._id : createdMenu?._id;
    const obj = { _id, form: formData };
    if (needsUnwrap) {
      await updateImages(obj).unwrap();
    }
    updateImages(obj);
  };

  React.useEffect(() => {
    if (isSuccessCreate) {
      if (pictureFile?.length || albumpictureFile?.length) {
        uploadImages();
      } else {
        dispatch(
          setToastNotification({
            type: 'success',
            open: true,
            message: 'Menu criado com sucesso!',
          }),
        );
        handleCancelModal();
      }
    }
  }, [isSuccessCreate]);

  React.useEffect(() => {
    if (isSuccessUpdate) {
      dispatch(
        setToastNotification({
          type: 'success',
          open: true,
          message: 'Menu atualizado com sucesso!',
        }),
      );
      handleCancelModal();
    }
  }, [isSuccessUpdate]);

  React.useEffect(() => {
    if (isSuccessImages) {
      if (menuToEdit) {
        dispatch(
          setToastNotification({
            type: 'success',
            open: true,
            message: 'Menu atualizado com sucesso!',
          }),
        );
      } else {
        dispatch(
          setToastNotification({
            type: 'success',
            open: true,
            message: 'Menu criado com sucesso!',
          }),
        );
        handleCancelModal();
      }
    }
  }, [isSuccessImages]);

  const handleEditClick = (id: string) => {
    const selectedMenu = menus?.find((me) => me._id === id);
    if (selectedMenu) {
      setMenuToEdit(selectedMenu);
      setModalOpen(true);
    }
  };

  const handleSubmitForm = async (formValues: MenuFormT) => {
    const values = formValues;
    delete values.price_per_person;
    delete values.max_person;
    if (values?.min_person !== undefined && values?.min_person <= 0) {
      delete values.min_person;
    }
    if (values.is_catering) {
      delete values.prices_per_person;
      delete values.min_person;
    } else {
      delete values.delivery_rate;
      for (let index = 0; index < values.configurations.length; index++) {
        for (let indexJ = 0; indexJ < values.configurations[index].config_items.length; indexJ++) {
          delete values.configurations[index].config_items[indexJ].max_quantity;
        }
      }
    }
    if (menuToEdit) {
      if (pictureFile?.length || albumpictureFile?.length) {
        await uploadImages(true);
      }
      updateMenu({
        ...values,
        _id: menuToEdit._id,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        chef_user_id: values.chef_user_id.value!,
        configurations: values.configurations.map((config) => {
          return {
            ...config,
            config_items: config.config_items.map((item) => {
              return {
                ...item,
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                item_id: item.item_id.value!,
              };
            }),
          };
        }),
      });
    } else {
      delete values.active;

      createMenu({
        ...values,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        chef_user_id: values.chef_user_id.value!,
        configurations: values.configurations.map((config) => {
          return {
            ...config,
            config_items: config.config_items.map((item) => {
              return {
                ...item,
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                item_id: item.item_id.value!,
              };
            }),
          };
        }),
      });
    }
  };

  return (
    <>
      <CRUDTemplate
        title='Menu'
        onAddClick={() => setModalOpen(true)}
        onEditClick={(id: string) => handleEditClick(id)}
        hasEdit={true}
        columns={[
          {
            field: 'name',
            headerName: 'Nome',
          },
          {
            field: 'description',
            headerName: 'Descrição',
          },
          {
            field: 'chef_name',
            headerName: 'Chef',
          },
          {
            field: 'is_catering',
            headerName: 'Catering',
            type: 'boolean',
          },
          {
            field: 'active',
            headerName: 'Ativo',
            type: 'boolean',
          },
        ]}
        rows={
          menus?.map((item) => {
            return {
              ...item,
              chef_name: item.chef_user_id.name,
            };
          }) || []
        }
      />
      <MenuModal
        isOpen={isModalOpen}
        menuToEdit={menuToEdit}
        onClose={handleCancelModal}
        onSubmit={handleSubmitForm}
        chefs={chefs || []}
        items={items?.filter((item) => item.active) || []}
        pictureFile={pictureFile}
        setPictureFile={setPictureFile}
        albumpictureFile={albumpictureFile}
        setAlbumPictureFile={setAlbumPictureFile}
        isSuccess={isSuccessUpdate || isSuccessCreate}
      />
    </>
  );
}

export default Menu;
