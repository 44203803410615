import { styled } from '@mui/material/styles';
import { Box, Paper } from '@mui/material';
import LinesBG from '../../assets/images/LinesBG.png';

export const Container = styled(Box)(() => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundImage: `url(${LinesBG})`,
}));

export const FormContainer = styled(Paper)(({ theme }) => ({
  minWidth: '350px',
  minHeight: '450px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  borderRadius: '10px',
  padding: '30px 50px',
  backgroundColor: theme.palette.white.main,
}));

export const StyledLogo = styled('img')(() => ({
  width: '200px',
}));
