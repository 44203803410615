import React from 'react';
import { BoxBottomRight, StyledImg } from './whatsappIcon.styles';
import { Link, useMediaQuery } from '@mui/material';
import { theme } from '../../theme';
import WhatsappPng from '../../assets/images/whatsappLogo.png';

export const WhatsappIcon = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <BoxBottomRight size={{ isMobile: Boolean(isMobile) }}>
      <Link
        href='https://wa.me/5513996485920'
        underline='none'
        target='_blank'
        sx={{ cursor: 'pointer' }}
      >
        <StyledImg src={WhatsappPng} alt='whatsapp png' size={{ isMobile: Boolean(isMobile) }} />
      </Link>
    </BoxBottomRight>
  );
};
