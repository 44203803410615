import { Box, Typography } from '@mui/material';
import { SchedulingT } from '../../../app/api/schedulingAPI';
import { EndInfos, ItemsWrapper, TotalInfo } from './listScheduleItem.styles';
import { getPriceByNumberOfGuest } from '../../../utils/pricesByNumberFunctions';

interface ListScheduleItemI {
  data?: SchedulingT;
  aditionalValue: number;
}

export const ListScheduleItem = ({ data, aditionalValue }: ListScheduleItemI) => {
  const normalizePrice = (valueNumber?: number) => {
    return String(valueNumber?.toFixed(2)).replace('.', ',');
  };

  const priceObject = getPriceByNumberOfGuest({
    pricesPerPerson: data?.prices_per_person,
    numberOfGuestToSearch: data?.number_of_guests ?? 0,
  });

  return (
    <>
      <ItemsWrapper>
        {data?.options.map((option) => {
          return (
            <Box
              key={option.name}
              display='flex'
              justifyContent='center'
              alignItems='center'
              flexDirection='column'
              width={300}
              gap={0.5}
              borderBottom={1}
              mb={1}
              sx={{
                ':last-child': {
                  borderBottom: 0.5,
                },
              }}
            >
              <Box mt={1}>
                <Typography variant='caption' color='primary' fontWeight='bold'>
                  {option.name}
                </Typography>
              </Box>
              {option.option_items.map((item, index) => {
                return (
                  <Box
                    key={item.item_id._id}
                    display='flex'
                    width='100%'
                    justifyContent='space-between'
                    flexDirection='row'
                    mb={1}
                  >
                    <Box>
                      <Typography variant='caption'>
                        {item.selected_quantity}x {item.item_id.name}
                      </Typography>
                    </Box>

                    <Box>
                      <Box
                        display='flex'
                        width='100%'
                        flexDirection='column'
                        justifyContent='right'
                        alignItems='flex-end'
                      >
                        <Typography variant='caption'>
                          {!data?.menu_id.is_catering
                            ? index + 1 > option.min_quantity || !option.min_quantity
                              ? `+ R$ ${item.value ? normalizePrice(item.value) : '0,00'}`
                              : ''
                            : `R$ ${item.value ? normalizePrice(item.value) : '0,00'}`}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                );
              })}
            </Box>
          );
        })}
      </ItemsWrapper>
      <Box display='flex' justifyContent='flex-end' flexDirection='column'>
        {!data?.menu_id.is_catering && (
          <>
            <EndInfos>
              <Typography variant='caption' color='primary' fontWeight='bold'>
                Valor por pessoa:
              </Typography>
              <Box display='flex' justifyContent='flex-end' alignItems='center'>
                <Typography variant='caption'>R$ {normalizePrice(priceObject?.price)}</Typography>
              </Box>
            </EndInfos>
            <EndInfos>
              <Typography variant='caption' color='primary' fontWeight='bold'>
                Adicionais:
              </Typography>
              <Box display='flex' justifyContent='flex-end' alignItems='center'>
                <Typography variant='caption'>R$ {normalizePrice(aditionalValue)}</Typography>
              </Box>
            </EndInfos>
          </>
        )}
        {data?.delivery_rate && data?.menu_id.is_catering && (
          <EndInfos>
            <Typography variant='caption' color='primary' fontWeight='bold'>
              Taxa de entrega:
            </Typography>
            <Box display='flex' justifyContent='flex-end' alignItems='center'>
              <Typography variant='caption'>R$ {normalizePrice(data?.delivery_rate)}</Typography>
            </Box>
          </EndInfos>
        )}
        {!data?.menu_id.is_catering && data && priceObject?.price && (
          <EndInfos>
            <Typography variant='caption' color='primary' fontWeight='bold'>
              SubTotal x Convidados:
            </Typography>
            <Box display='flex' justifyContent='flex-end' alignItems='center'>
              <Typography variant='caption'>
                R$ {normalizePrice(priceObject.price + aditionalValue)} x {data.number_of_guests}
              </Typography>
            </Box>
          </EndInfos>
        )}
        {data && data?.coupon?.code && (
          <EndInfos>
            <Typography variant='caption' color='primary' fontWeight='bold'>
              Cupom:
            </Typography>
            <Box display='flex' justifyContent='flex-end' alignItems='center'>
              <Typography variant='caption'>
                {data?.coupon?.code} ({data?.coupon?.percentage}% OFF)
              </Typography>
            </Box>
          </EndInfos>
        )}
        <TotalInfo width={300}>
          <Typography variant='caption' color='primary' fontWeight='bold'>
            Total:
          </Typography>
          <Box display='flex' justifyContent='flex-end' alignItems='center'>
            <Typography variant='caption' fontWeight='bold'>
              R$ {normalizePrice(data?.total)}
            </Typography>
          </Box>
        </TotalInfo>
      </Box>
    </>
  );
};
