import { styled } from '@mui/material/styles';
import { Box, IconButton } from '@mui/material';

interface SizePropsI {
  isMobile: boolean;
}

export const Container = styled(Box)<{ size: SizePropsI }>(({ size }) => ({
  padding: size.isMobile ? '100px 30px 50px' : '100px 100px 50px',
  minHeight: '90vh',
}));

export const CarouselSlide = styled(Box)<{ size: SizePropsI }>(({ size }) => ({
  maxHeight: size.isMobile ? '150px' : '250px',
}));

export const StyledMenuImg = styled('img')<{ size: SizePropsI }>(({ size }) => ({
  height: size.isMobile ? '150px' : '250px',
  width: 'auto',
  objectFit: 'cover',
}));

export const TitleContainer = styled(Box)(({ theme }) => ({
  borderBottom: `2px solid ${theme.palette.black.main}`,
  padding: '5px 0',
  marginBottom: '10px',
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
}));

export const FavoriteIconButton = styled(IconButton)(({ theme }) => ({
  position: 'initial',
  top: '15px',
  right: '15px',
  backgroundColor: theme.palette.translucent.main,
}));
