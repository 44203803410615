import React, { ReactElement } from 'react';

import {
  Grid,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Paper,
  useMediaQuery,
} from '@mui/material';
import {
  DataGrid,
  GridActionsCellItem,
  GridCellParams,
  GridColDef,
  GridRowParams,
} from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import { Container } from './CRUDTemplate.styles';
import { useAppDispatch } from '../../app/hooks';
import { setLoading } from '../../app/slices/appSlice';
import { theme } from '../../theme';
import { ptBRGrid } from '../../utils/dataGrid/dataGridTranslation';

type CRUDTemplatePropsT = {
  title: string;
  columns: GridColDef[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rows: any[];
  searchComponent?: ReactElement;
  onAddClick?: () => void;
  onEditClick?: (id: string) => void;
  onDeleteClick?: (id: string) => void;
  onCellDoubleClick?: (row: object) => void;
  stateDeleteAccept?: string[];
  deleteMessage?: string;
  hasDelete?: boolean;
  hasAdd?: boolean;
  hasEdit?: boolean;
  loading?: boolean;
};

const CRUDTemplate = ({
  title,
  columns,
  rows,
  searchComponent,
  onCellDoubleClick,
  onAddClick,
  onEditClick,
  onDeleteClick,
  deleteMessage = 'Verifique e tenha certeza antes de realizar a exclusão do item selecionado',
  hasDelete = false,
  stateDeleteAccept,
  hasAdd = true,
  hasEdit = false,
  loading = false,
}: CRUDTemplatePropsT) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const dispatch = useAppDispatch();
  const [deleteId, setDeleteId] = React.useState<string>('');

  const rowsWithId = rows.map((r, index) => ({ ...r, id: index }));

  const readyToGridActions = (params: GridRowParams) => {
    if (!hasEdit && !hasDelete) return [];
    const actions = [];
    if (hasEdit) {
      actions.push(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <GridActionsCellItem
          icon={<EditIcon />}
          label='Editar'
          onClick={() => (onEditClick ? onEditClick(params.row._id || '') : null)}
          key={React.useId()}
        />,
      );
    }
    if (hasDelete) {
      actions.push(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <GridActionsCellItem
          disabled={
            stateDeleteAccept ? !stateDeleteAccept?.includes(params.row.scheduling_state) : false
          }
          icon={
            stateDeleteAccept ? (
              !stateDeleteAccept?.includes(params.row.scheduling_state) ? (
                <></>
              ) : (
                <DeleteIcon />
              )
            ) : (
              <DeleteIcon />
            )
          }
          label='Excluir'
          onClick={() => setDeleteId(params.row._id || '')}
          key={React.useId()}
        />,
      );
    }
    return actions;
  };

  const columnsWithActions = [
    ...columns.map((c) => ({ ...c, flex: 1 })),
    {
      field: 'actions',
      headerName: 'Ações',
      type: 'actions',
      flex: 1,
      maxWidth: 100,
      getActions: (params: GridRowParams) => readyToGridActions(params),
    },
  ];

  React.useEffect(() => {
    if (loading) {
      dispatch(setLoading(true));
    } else {
      dispatch(setLoading(false));
    }
  }, [loading]);

  const handleDeleteClick = async () => {
    if (hasDelete && onDeleteClick) {
      await onDeleteClick(deleteId);
      setDeleteId('');
    }
  };

  return (
    <Container size={{ isMobile: Boolean(isMobile) }}>
      <Grid container>
        <Grid item xs={6}>
          <Typography variant='h4'>{title}</Typography>
        </Grid>
        <Grid item xs={6} display='flex' justifyContent='flex-end'>
          {hasAdd && (
            <Button
              size='small'
              variant='contained'
              color='primary'
              onClick={() => (onAddClick ? onAddClick() : null)}
            >
              Adicionar
            </Button>
          )}
        </Grid>
      </Grid>
      {searchComponent}
      <Grid container mt={2}>
        <Grid item xs={12}>
          <Paper>
            <DataGrid
              localeText={ptBRGrid}
              rows={rowsWithId}
              columns={columnsWithActions}
              disableSelectionOnClick
              pageSize={10}
              rowsPerPageOptions={[10]}
              autoHeight
              autoPageSize
              onCellDoubleClick={(cell: GridCellParams) => {
                onCellDoubleClick ? onCellDoubleClick(cell.row) : null;
              }}
            />
          </Paper>
          <Dialog
            open={Boolean(deleteId)}
            onClose={(event: object, reason: string) => {
              if (reason !== 'backdropClick') {
                setDeleteId('');
              }
            }}
            aria-labelledby='delete-dialog'
            aria-describedby='delete-dialog'
          >
            <DialogTitle id='delete-dialog-title'>Tem certeza?</DialogTitle>
            <DialogContent>
              <DialogContentText id='delete-dialog-description'>{deleteMessage}</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setDeleteId('')} color='secondary'>
                Cancelar
              </Button>
              <Button onClick={() => handleDeleteClick()} autoFocus>
                Deletar
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
      </Grid>
    </Container>
  );
};

export default CRUDTemplate;
