import React from 'react';

import { ReactComponent as FreeIcon } from '../../../assets/svgs/free.svg';
import { ReactComponent as CheckMarkIcon } from '../../../assets/svgs/check-mark.svg';
import { ReactComponent as CustomerServiceIcon } from '../../../assets/svgs/customer-service.svg';
import { ReactComponent as FoodIcon } from '../../../assets/svgs/food.svg';
import { Box, Grid, SvgIcon, Typography, useMediaQuery } from '@mui/material';
import { theme } from '../../../theme';
import { ImpactText } from '../landingPage.styles';

function Features() {
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const iconCss = {
    marginTop: isMobile ? 3 : 10,
    width: isMobile ? '60px' : '120px',
    height: isMobile ? '60px' : '120px',
    '*, path': {
      fill: theme.palette.gold.main,
    },
  };

  return (
    <Box sx={{ paddingX: { xs: 0, md: 10 }, paddingTop: { xs: 0, md: 10 } }}>
      <Grid container>
        <Grid item xs={12} sx={{ textAlign: 'center' }}>
          <ImpactText variant={isMobile ? 'h4' : 'h3'}>Por que contratar a AboutEat?</ImpactText>
        </Grid>
        <Grid item xs={6} md={3} paddingX={1}>
          <SvgIcon component={FreeIcon} inheritViewBox sx={iconCss} />
          <ImpactText variant={isMobile ? 'h6' : 'h5'}>Prática, rápida, gratuita</ImpactText>
          <Typography color='rgb(87,106,122)'>
            Desfrute de segurança e praticidade sem mensalidades e planos, a AboutEat é um site
            livre.
          </Typography>
        </Grid>
        <Grid item xs={6} md={3} paddingX={1}>
          <SvgIcon component={CustomerServiceIcon} inheritViewBox sx={iconCss} />
          <ImpactText variant={isMobile ? 'h6' : 'h5'}>Urgências</ImpactText>
          <Typography color='rgb(87,106,122)'>
            Precisa agendar um evento urgente? Nossa equipe busca a melhor opção para lhe atender.
          </Typography>
        </Grid>
        <Grid item xs={6} md={3} paddingX={1}>
          <SvgIcon component={CheckMarkIcon} inheritViewBox sx={iconCss} />
          <ImpactText variant={isMobile ? 'h6' : 'h5'}>Comida de qualidade</ImpactText>
          <Typography color='rgb(87,106,122)'>
            Oferecemos uma curadoria de profissionais de qualidade e segurança.
          </Typography>
        </Grid>
        <Grid item xs={6} md={3} paddingX={1}>
          <SvgIcon component={FoodIcon} inheritViewBox sx={iconCss} />
          <ImpactText variant={isMobile ? 'h6' : 'h5'}>Restrições alimentares</ImpactText>
          <Typography color='rgb(87,106,122)'>
            Menus com opções livres de glúten, lactose. Menus veganos e vegetarianos.
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Features;
