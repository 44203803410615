import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AlertColor } from '@mui/material/Alert';

import { RootState } from '../../store';

export type ToastNotificationT = {
  open: boolean;
  message: string;
  type: AlertColor;
};

export interface AppStateI {
  isLoading: boolean;
  toastNotification: ToastNotificationT;
}

const initialState: AppStateI = {
  isLoading: false,
  toastNotification: {
    open: false,
    message: '',
    type: 'error',
  },
};

const appSlice = createSlice({
  name: 'appSlice',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setToastNotification: (state, action: PayloadAction<ToastNotificationT>) => {
      state.toastNotification = action.payload;
    },
    resetToastNotification: (state) => {
      state.toastNotification = initialState.toastNotification;
    },
  },
});

export const { setLoading, setToastNotification, resetToastNotification } = appSlice.actions;

export const isAppLoading = (state: RootState) => state.app.isLoading;

export const getToastNotification = (state: RootState) => state.app.toastNotification;

export default appSlice.reducer;
