import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

interface PropsI {
  isCatering: boolean;
}

export const Container = styled(Box)<{ props: PropsI }>(({ theme, props }) => ({
  padding: '30px 40px',
  border: `2px solid ${
    props.isCatering ? theme.palette.secondary.main : theme.palette.primary.main
  }`,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));
