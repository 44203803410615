import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const LoaderWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.translucent.main,
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  top: 0,
  bottom: 0,
  right: 0,
  left: 0,
  zIndex: 1500,
}));
