import React from 'react';

import { Grid, IconButton, Paper, TextField, Typography, useMediaQuery } from '@mui/material';
import { Doughnut, Bar } from 'react-chartjs-2';
import { format } from 'date-fns';

import { Container, FilterContainer, ChartContainer } from './dashboard.styles';
import { theme } from '../../../theme';
import { SearchOutlined } from '@mui/icons-material';
import { useLazyGetDashboardQuery } from '../../../app/api/dashboardAPI';
import 'chart.js/auto';

function Dashboard() {
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [getDashboard, { data }] = useLazyGetDashboardQuery();
  const [startDate, setStartDate] = React.useState<string>('undefined');
  const [endDate, setEndDate] = React.useState<string>('undefined');

  const schedulingsByState = {
    labels: data?.schedulings_by_state ? Object.keys(data?.schedulings_by_state) : [],
    datasets: [
      {
        label: '# agendamentos',
        data: data?.schedulings_by_state ? Object.values(data?.schedulings_by_state) : [],
        backgroundColor: [
          theme.palette.primary.main,
          theme.palette.secondary.main,
          theme.palette.yellow.main,
          theme.palette.greyAE.main,
          theme.palette.black.main,
        ],
      },
    ],
  };

  const schedulingsByDate = {
    labels: data?.schedulings_by_date
      ? Object.keys(data?.schedulings_by_date)
          .map((sbd) =>
            format(
              new Date(new Date(sbd).valueOf() + new Date(sbd).getTimezoneOffset() * 60 * 1000),
              'dd/MM/yyyy',
            ),
          )
          .reverse()
      : [],
    datasets: [
      {
        label: 'agendamentos',
        data: data?.schedulings_by_date
          ? Object.keys(data?.schedulings_by_date).map(
              (sbd) => data?.schedulings_by_date[sbd].total,
            )
          : [],
        backgroundColor: theme.palette.primary.main,
      },
    ],
  };

  const mostScheduled = {
    labels: data?.most_scheduled_menus ? data?.most_scheduled_menus.map((msm) => msm.name) : [],
    datasets: [
      {
        label: 'agendamentos',
        data: data?.most_scheduled_menus
          ? data?.most_scheduled_menus.map((msm) => msm.scheduled_quantity)
          : [],
        backgroundColor: theme.palette.secondary.main,
      },
    ],
  };

  React.useEffect(() => {
    getDashboard(undefined);
  }, []);

  const handleSearch = () => {
    getDashboard({ start_date: startDate, end_date: endDate });
  };

  return (
    <Container size={{ isMobile: Boolean(isMobile) }}>
      <FilterContainer gap={2}>
        <TextField
          id='date'
          label='Data Inicial'
          type='date'
          defaultValue=''
          value={startDate}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setStartDate(event.target.value)
          }
          sx={{ width: 220 }}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          id='date'
          label='Data Final'
          type='date'
          defaultValue=''
          value={endDate}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => setEndDate(event.target.value)}
          sx={{ width: 220 }}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <IconButton
          size='large'
          onClick={handleSearch}
          sx={{ background: theme.palette.primary.main, color: theme.palette.white.main }}
        >
          <SearchOutlined />
        </IconButton>
      </FilterContainer>
      <Grid container sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Grid item xs={12} md={4}>
          <Paper
            elevation={3}
            sx={{ padding: 5, margin: 2, backgroundColor: theme.palette.primary.main }}
          >
            <Typography variant='h4' fontWeight='bold'>
              {data?.total_number_of_schedulings || 0}
            </Typography>
            <Typography>Número de agendamentos</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper
            elevation={3}
            sx={{ padding: 5, margin: 2, backgroundColor: theme.palette.secondary.main }}
          >
            <Typography variant='h4' fontWeight='bold'>
              R${' '}
              {data?.average_cost_per_scheduling
                ? String(data?.average_cost_per_scheduling).replace('.', ',')
                : 0}
            </Typography>
            <Typography>Ticket Médio</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper
            elevation={3}
            sx={{ padding: 5, margin: 2, backgroundColor: theme.palette.yellow.main }}
          >
            <Typography variant='h4' fontWeight='bold'>
              R${' '}
              {data?.total_earnings
                ? String(data?.average_cost_per_scheduling).replace('.', ',')
                : 0}
            </Typography>
            <Typography>Faturamento</Typography>
          </Paper>
        </Grid>
        {data?.most_scheduled_menus && data?.most_scheduled_menus.length > 0 && (
          <Grid item xs={12} md={6}>
            <ChartContainer>
              <Typography variant={isMobile ? 'h5' : 'h4'}>Menus Mais Agendados</Typography>
              <Bar data={mostScheduled} />
            </ChartContainer>
          </Grid>
        )}
        {data?.schedulings_by_date && Object.keys(data?.schedulings_by_date).length > 0 && (
          <Grid item xs={12} md={6}>
            <ChartContainer>
              <Typography variant={isMobile ? 'h5' : 'h4'}>Agendamentos por Dia</Typography>
              <Bar data={schedulingsByDate} />
            </ChartContainer>
          </Grid>
        )}
        {data?.schedulings_by_state && Object.keys(data?.schedulings_by_state).length > 0 && (
          <Grid item xs={12} md={5}>
            <ChartContainer>
              <Typography variant={isMobile ? 'h5' : 'h4'}>Agendamentos por Status</Typography>
              <Doughnut data={schedulingsByState} />
            </ChartContainer>
          </Grid>
        )}
      </Grid>
    </Container>
  );
}

export default Dashboard;
