import { styled } from '@mui/material/styles';
import { Box, Avatar, IconButton } from '@mui/material';

export const Container = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: '300px',
  width: '250px',
  border: '10px',
  position: 'relative',
}));

export const MenuImage = styled('img')(() => ({
  minHeight: '250px',
  maxHeight: '250px',
  minWidth: '250px',
  maxWidth: '250px',
  objectFit: 'cover',
  borderRadius: '20px',
  cursor: 'pointer',
}));

export const BottomInfo = styled(Box)(() => ({
  display: 'flex',
  width: '100%',
}));

export const ChefImage = styled(Avatar)(() => ({
  marginTop: '-15px',
  width: 70,
  height: 70,
  cursor: 'pointer',
}));

export const FavoriteIconButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: '15px',
  right: '15px',
  backgroundColor: theme.palette.translucent.main,
}));

export const ShareIconButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: '15px',
  right: '60px',
  backgroundColor: theme.palette.translucent.main,
}));
