import React from 'react';

import { Box, Grid, Typography, useMediaQuery } from '@mui/material';

import { theme } from '../../../theme';
import { ImpactText } from '../explore.style';
import GppGoodOutlinedIcon from '@mui/icons-material/GppGoodOutlined';
import RuleFolderOutlinedIcon from '@mui/icons-material/RuleFolderOutlined';
import ContactSupportOutlinedIcon from '@mui/icons-material/ContactSupportOutlined';
import NoFoodOutlinedIcon from '@mui/icons-material/NoFoodOutlined';
import RestaurantOutlinedIcon from '@mui/icons-material/RestaurantOutlined';
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';
import { Icon } from './reasons.style';

function Reasons() {
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box sx={{ paddingX: { xs: 0, md: 10 }, marginTop: { xs: 0, md: 10 } }}>
      <Grid container>
        <Grid item xs={12}>
          <ImpactText variant={isMobile ? 'h4' : 'h3'} sx={{ textAlign: 'center' }}>
            Por que contratar um chef com a AboutEat
          </ImpactText>
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            padding: 5,
          }}
        >
          <Icon>
            <GppGoodOutlinedIcon sx={{ fontSize: '30px' }} />
          </Icon>
          <Typography>
            <b>Proteção de pagamento</b>
          </Typography>
          <Typography>
            Seu pagamento só é liberado ao chef pela nossa plataforma, após ele atender
            satisfatoriamente o seu evento
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            padding: 5,
          }}
        >
          <Icon>
            <RuleFolderOutlinedIcon sx={{ fontSize: '30px' }} />
          </Icon>
          <Typography>
            <b>Cancelamentos e Reembolsos</b>
          </Typography>
          <Typography>
            Você pode cancelar e receber um reembolso total até 7 dias antes. Seu pagamento fica
            protegido até depois do evento.
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            padding: 5,
          }}
        >
          <Icon>
            <ContactSupportOutlinedIcon sx={{ fontSize: '30px' }} />
          </Icon>
          <Typography>
            <b>Suporte ao cliente</b>
          </Typography>
          <Typography>
            Sem robôs, aqui sua ajuda é feita diretamente com a equipe da AboutEat
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            padding: 5,
          }}
        >
          <Icon>
            <NoFoodOutlinedIcon sx={{ fontSize: '30px' }} />
          </Icon>
          <Typography>
            <b>Restrições alimentares</b>
          </Typography>
          <Typography>
            Alinhamos opções de menus que atendam totalmente as necessidades especiais de seus
            convidados, dentro do seu orçamento.
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            padding: 5,
          }}
        >
          <Icon>
            <RestaurantOutlinedIcon sx={{ fontSize: '30px' }} />
          </Icon>
          <Typography>
            <b>Sem preocupações</b>
          </Typography>
          <Typography>
            Seu chef fará as compras, levará ao local, servirá seus convidados e ainda manterá sua
            cozinha limpa.
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            padding: 5,
          }}
        >
          <Icon>
            <VerifiedOutlinedIcon sx={{ fontSize: '30px' }} />
          </Icon>
          <Typography>
            <b>Experiência única</b>
          </Typography>
          <Typography>Viva com seus convidados um evento especial e particular.</Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Reasons;
