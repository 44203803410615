import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { theme } from '../../theme';
import { LoadingButton } from '@mui/lab';
import { FormContainer } from './modal.styles';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppDispatch } from '../../app/hooks';
import { setToastNotification } from '../../app/slices/appSlice';
import { googleApiUrl } from '../../app/api/googleApi';

interface ModalForRegisteringEmailI {
  onClose: () => void;
  open: boolean;
}

type EmailRegisterFormT = {
  email: string;
};

const ModalForRegisteringEmail = ({ onClose, open }: ModalForRegisteringEmailI) => {
  const dispatch = useAppDispatch();
  const smallSize = useMediaQuery(theme.breakpoints.down('sm'));
  const [isLoading, setLoading] = useState(false);
  const handleClose = () => {
    onClose();
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().required('Email é obrigatório').email('Email inválido'),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<EmailRegisterFormT>({
    resolver: yupResolver(validationSchema),
    defaultValues: {},
  });

  const handleSubmitForm: SubmitHandler<EmailRegisterFormT> = async (values) => {
    const form = new FormData();
    form.append('email', values.email);
    form.append('sheet', 'email-contato');
    setLoading(true);

    fetch(googleApiUrl, {
      method: 'POST',
      body: form,
    })
      .then(() => {
        setLoading(false);
        dispatch(
          setToastNotification({
            type: 'success',
            open: true,
            message: 'Contato enviado com sucesso!!',
          }),
        );
        reset({ email: '' });
      })
      .catch((err) => console.log(err));
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <Box
        display='flex'
        alignItems='center'
        textAlign='center'
        justifyContent='center'
        flexDirection='column'
        sx={{
          padding: { xs: 5, md: 10 },
        }}
      >
        <DialogTitle
          align='center'
          variant={smallSize ? 'h6' : 'h5'}
          color='primary'
          display='flex'
        >
          Não perca tempo procurando
          <br />
          comidas para os seus eventos
        </DialogTitle>
        <Typography>
          Insira seu e-mail e receba grátis um
          <br />
          consultor dedicado para atender todos os
          <br />
          seus eventos
        </Typography>
        <FormContainer>
          <TextField
            required
            id='email'
            label='Email'
            fullWidth
            size='small'
            margin='normal'
            InputLabelProps={{ shrink: true }}
            {...register('email')}
            error={errors.email ? true : false}
            helperText={errors.email ? String(errors.email.message) : null}
          />
          {isLoading ? (
            <LoadingButton
              size='large'
              sx={{ marginTop: '15px' }}
              loading
              variant='contained'
              color='primary'
              fullWidth
            >
              Carregando
            </LoadingButton>
          ) : (
            <Button
              size='large'
              sx={{ marginTop: '15px' }}
              variant='contained'
              color='primary'
              onClick={handleSubmit(handleSubmitForm)}
              fullWidth
            >
              Enviar
            </Button>
          )}
        </FormContainer>
      </Box>
    </Dialog>
  );
};

export default ModalForRegisteringEmail;
