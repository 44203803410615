/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import {
  Typography,
  Box,
  TextField,
  IconButton,
  Button,
  FormControl,
  Autocomplete,
} from '@mui/material';
import {
  Control,
  Controller,
  FieldErrors,
  useFieldArray,
  UseFormRegister,
  UseFormWatch,
} from 'react-hook-form';
import { Add, Delete } from '@mui/icons-material';

import { ItemT } from '../../../../app/api/itemAPI';
import { MenuFormT } from '.';

export type NestedFieldArrayT = {
  nestIndex: number;
  control: Control<MenuFormT, any>;
  register: UseFormRegister<MenuFormT>;
  watch: UseFormWatch<MenuFormT>;
  errors: Partial<FieldErrors<MenuFormT>>;
  items: ItemT[];
};

const NestedFieldArray = ({
  nestIndex,
  control,
  register,
  watch,
  errors,
  items,
}: NestedFieldArrayT) => {
  const { fields, remove, append } = useFieldArray({
    control,
    name: `configurations.${nestIndex}.config_items`,
  });

  return (
    <>
      {fields.map((item, index) => (
        <Box key={item.id} display='flex' alignItems='center'>
          <Box p={2}>
            <Typography>Item {index + 1}</Typography>
            {items && (
              <Controller
                name={`configurations.${nestIndex}.config_items.${index}.item_id`}
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <FormControl
                      fullWidth
                      error={
                        errors?.configurations?.[nestIndex]?.config_items?.[index]?.item_id
                          ? true
                          : false
                      }
                    >
                      <Autocomplete
                        id={`configurations.${nestIndex}.config_items.${index}.item_id`}
                        size='small'
                        defaultValue={value}
                        options={items
                          .filter((item) => item.active)
                          .map((item) => {
                            return {
                              value: item._id,
                              label: item.name,
                            };
                          }, [])}
                        loadingText='Carregando...'
                        getOptionLabel={(option) => option?.label ?? ''}
                        onChange={(_, option) => {
                          onChange(option);
                        }}
                        ListboxProps={{
                          style: {
                            maxHeight: '250px',
                          },
                        }}
                        isOptionEqualToValue={(option, optionValue) =>
                          option?.value === optionValue?.value
                        }
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        renderOption={(props, option: any) => {
                          return <li {...props}>{option.label}</li>;
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            required
                            id={`configurations.${nestIndex}.config_items.${index}.item_id-id`}
                            label='Item'
                            fullWidth
                            size='small'
                            margin='normal'
                            InputLabelProps={{ shrink: true }}
                            error={
                              errors?.configurations?.[nestIndex]?.config_items?.[index]?.item_id
                                ? true
                                : false
                            }
                            helperText={
                              errors?.configurations?.[nestIndex]?.config_items?.[index]?.item_id
                                ? String(
                                    errors?.configurations?.[nestIndex]?.config_items?.[index]
                                      ?.item_id?.message,
                                  )
                                : null
                            }
                          />
                        )}
                      />
                    </FormControl>
                  );
                }}
              />
            )}
            <TextField
              id={`configurations.${nestIndex}.config_items.${index}.optional_description-id`}
              label='Descrição opcional'
              fullWidth
              size='small'
              margin='normal'
              InputLabelProps={{ shrink: true }}
              {...register(
                `configurations.${nestIndex}.config_items.${index}.optional_description`,
              )}
              error={
                errors?.configurations?.[nestIndex]?.config_items?.[index]?.optional_description
                  ? true
                  : false
              }
              helperText={
                errors?.configurations?.[nestIndex]?.config_items?.[index]?.optional_description
                  ? String(
                      errors?.configurations?.[nestIndex]?.config_items?.[index]
                        ?.optional_description?.message,
                    )
                  : null
              }
            />
            <TextField
              required={watch('is_catering')}
              id={`configurations.${nestIndex}.config_items.${index}.value-id`}
              label='Valor'
              type='number'
              fullWidth
              size='small'
              margin='normal'
              InputLabelProps={{ shrink: true }}
              defaultValue={0}
              {...register(`configurations.${nestIndex}.config_items.${index}.value`)}
              error={
                errors?.configurations?.[nestIndex]?.config_items?.[index]?.value ? true : false
              }
              helperText={
                errors?.configurations?.[nestIndex]?.config_items?.[index]?.value
                  ? String(
                      errors?.configurations?.[nestIndex]?.config_items?.[index]?.value?.message,
                    )
                  : null
              }
            />
            {watch('is_catering') && (
              <TextField
                id={`configurations.${nestIndex}.config_items.${index}.max_quantity-id`}
                label='Quantidade Máxima'
                type='number'
                fullWidth
                size='small'
                margin='normal'
                InputLabelProps={{ shrink: true }}
                {...register(`configurations.${nestIndex}.config_items.${index}.max_quantity`)}
                error={
                  errors?.configurations?.[nestIndex]?.config_items?.[index]?.max_quantity
                    ? true
                    : false
                }
                helperText={
                  errors?.configurations?.[nestIndex]?.config_items?.[index]?.max_quantity
                    ? String(
                        errors?.configurations?.[nestIndex]?.config_items?.[index]?.max_quantity
                          ?.message,
                      )
                    : null
                }
              />
            )}
          </Box>
          <IconButton aria-label='delete' onClick={() => remove(index)}>
            <Delete color={'primary'} />
          </IconButton>
        </Box>
      ))}
      <Button
        onClick={() => {
          append({
            item_id: {},
            optional_description: undefined,
            value: undefined,
            max_quantity: undefined,
          });
        }}
      >
        <Add color={'primary'} /> Adicionar Item
      </Button>
    </>
  );
};

export default NestedFieldArray;
