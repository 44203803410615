import React, { Dispatch, SetStateAction } from 'react';

import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControlLabel,
  Switch,
  Stepper,
  Step,
  StepButton,
  Box,
  Button,
  useMediaQuery,
  FormControl,
  Autocomplete,
  IconButton,
  Typography,
} from '@mui/material';

import { ChefListT } from '../../../../app/api/chefAPI';
import { ItemT } from '../../../../app/api/itemAPI';
import { theme } from '../../../../theme';
import { MenuT, PricesPerPersonI } from '../../../../app/api/menuAPI';
import { InputFile } from '../../../../components';
import ConfigFieldArray from './ConfigFieldArray';
import { Add, Delete } from '@mui/icons-material';

export type MenuFormT = {
  name: string;
  description: string;
  chef_user_id: {
    value?: string | undefined;
    label?: string | undefined;
  };
  price_per_person?: number;
  prices_per_person?: PricesPerPersonI[] | undefined;
  min_person?: number;
  max_person?: number;
  delivery_rate?: number;
  is_catering: boolean;
  active?: boolean;
  configurations: {
    name: string;
    description: string;
    order: number;
    max_quantity?: number;
    min_quantity?: number;
    dietary_requirements: boolean;
    config_items: {
      item_id: {
        value?: string | undefined;
        label?: string | undefined;
      };
      optional_description?: string;
      value?: number;
      max_quantity?: number;
    }[];
  }[];
};

export type MenuModalT = {
  isOpen: boolean;
  onClose: () => void;
  menuToEdit?: MenuT;
  onSubmit: (formValues: MenuFormT) => void;
  chefs: ChefListT[];
  items: ItemT[];
  pictureFile?: (File & { preview: string })[];
  setPictureFile: Dispatch<SetStateAction<(File & { preview: string })[] | undefined>>;
  albumpictureFile?: (File & { preview: string })[];
  setAlbumPictureFile: Dispatch<SetStateAction<(File & { preview: string })[] | undefined>>;
  isSuccess: boolean;
};

const steps = ['Informações', 'Configurações', 'Imagens'];

const MenuModal = ({
  isOpen,
  onClose,
  menuToEdit,
  onSubmit,
  chefs,
  items,
  pictureFile,
  setPictureFile,
  albumpictureFile,
  setAlbumPictureFile,
  isSuccess,
}: MenuModalT) => {
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [activeStep, setActiveStep] = React.useState<number>(0);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Nome é obrigatório'),
    description: Yup.string().required('Descrição é obrigatória'),
    chef_user_id: Yup.object()
      .shape({
        value: Yup.string(),
        label: Yup.string(),
      })
      .nullable(true)
      .required('Chef é obrigatório')
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .test('empty-chef', 'Chef é obrigatório', function (field: any) {
        if (field && field.value) {
          return true;
        }
        return false;
      }),
    is_catering: Yup.bool(),
    active: Yup.bool(),
    min_person: Yup.number()
      .notRequired()
      .transform((value) => (isNaN(value) ? undefined : value))
      .nullable(true),
    prices_per_person: Yup.array(
      Yup.object().shape({
        number_of_guests: Yup.number(),
        price: Yup.number(),
      }),
    )
      .notRequired()
      .nullable(true),
    delivery_rate: Yup.number()
      .notRequired()
      .transform((value) => (isNaN(value) ? undefined : value))
      .nullable(true),
    configurations: Yup.array(
      Yup.object().shape({
        name: Yup.string().required('Nome é obrigatório'),
        description: Yup.string().required('Descrição é obrigatória'),
        order: Yup.number().required('Ordem é obrigatória').typeError('Ordem é obrigatória'),
        max_quantity: Yup.number()
          .notRequired()
          .transform((value) => (isNaN(value) ? undefined : value))
          .nullable(true),
        min_quantity: Yup.number()
          .notRequired()
          .transform((value) => (isNaN(value) ? undefined : value))
          .nullable(true),
        dietary_requirements: Yup.bool(),
        config_items: Yup.array(
          Yup.object().shape({
            item_id: Yup.object()
              .shape({
                value: Yup.string(),
                label: Yup.string(),
              })
              .nullable(true)
              .required('Item é obrigatório')
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              .test('empty-item', 'Item é obrigatório', function (field: any) {
                if (field && field.value) {
                  return true;
                }
                return false;
              }),
            optional_description: Yup.string().nullable().notRequired(),
            value: Yup.number()
              .notRequired()
              .transform((value) => (isNaN(value) ? undefined : value))
              .nullable(true),
            max_quantity: Yup.number()
              .notRequired()
              .transform((value) => (isNaN(value) ? undefined : value))
              .nullable(true),
          }),
        ),
      }),
    ),
  });

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
    watch,
  } = useForm<MenuFormT>({
    resolver: yupResolver(validationSchema),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'prices_per_person',
  });

  React.useEffect(() => {
    if (
      errors.name ||
      errors.description ||
      errors.chef_user_id ||
      errors.delivery_rate ||
      errors.min_person ||
      errors.prices_per_person
    ) {
      setActiveStep(0);
      return;
    }
    if (errors.configurations) {
      setActiveStep(1);
      return;
    }
  }, [errors]);

  React.useEffect(() => {
    if (menuToEdit) {
      if (typeof menuToEdit.chef_user_id.chef_id === 'string') {
        reset({
          ...menuToEdit,
          chef_user_id: {
            value: chefs.find((chef) => chef._id === menuToEdit.chef_user_id._id && chef.active)
              ?._id,
            label: chefs.find((chef) => chef._id === menuToEdit.chef_user_id._id && chef.active)
              ?.name,
          },
          configurations: menuToEdit.configurations.map((config) => {
            return {
              ...config,
              config_items: config.config_items.map((itemMap) => {
                return {
                  ...itemMap,
                  item_id: {
                    label: items.find((item) => item._id === itemMap.item_id && item.active)?.name,
                    value: items.find((item) => item._id === itemMap.item_id && item.active)?._id,
                  },
                };
              }),
            };
          }),
        });
      }
    }
  }, [menuToEdit]);

  React.useEffect(() => {
    if (isSuccess) {
      handleClose();
    }
  }, [isSuccess]);

  const isLastStep = () => {
    return activeStep === steps.length - 1;
  };

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  const handleClose = () => {
    setActiveStep(0);
    reset({
      name: '',
      description: '',
      chef_user_id: {},
      is_catering: false,
      active: false,
      min_person: 0,
      prices_per_person: [],
      delivery_rate: 0,
      configurations: [],
    });
    setPictureFile(undefined);
    setAlbumPictureFile(undefined);
    onClose();
  };

  return (
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    <Dialog
      open={isOpen}
      onClose={(event: object, reason: string) => {
        if (reason !== 'backdropClick') {
          handleClose();
        }
      }}
      fullScreen={fullScreen}
    >
      <DialogTitle>{menuToEdit ? 'Editar' : 'Adicionar'} Menu</DialogTitle>
      <DialogContent>
        <Stepper nonLinear activeStep={activeStep}>
          {steps.map((label, index) => (
            <Step key={label}>
              <StepButton color='inherit' onClick={handleStep(index)}>
                {label}
              </StepButton>
            </Step>
          ))}
        </Stepper>
        <Box mt={2}>
          <>
            {activeStep === 0 && (
              <>
                {menuToEdit && (
                  <Controller
                    control={control}
                    name='active'
                    defaultValue={undefined}
                    render={({ field: { onChange, value } }) => (
                      <FormControlLabel
                        control={
                          <Switch
                            onChange={onChange}
                            checked={value ?? false}
                            inputProps={{ 'aria-label': 'controlled' }}
                          />
                        }
                        label='Ativo'
                      />
                    )}
                  />
                )}
                <Controller
                  control={control}
                  name='is_catering'
                  defaultValue={false}
                  render={({ field: { onChange, value } }) => (
                    <FormControlLabel
                      control={
                        <Switch
                          onChange={onChange}
                          checked={value ?? false}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      }
                      label='Catering'
                    />
                  )}
                />
                <TextField
                  required
                  id='name'
                  label='Nome'
                  fullWidth
                  size='small'
                  margin='normal'
                  InputLabelProps={{ shrink: true }}
                  {...register('name')}
                  error={errors.name ? true : false}
                  helperText={errors.name ? String(errors.name.message) : null}
                />
                <TextField
                  required
                  id='description'
                  label='Descrição'
                  fullWidth
                  size='small'
                  margin='normal'
                  InputLabelProps={{ shrink: true }}
                  {...register('description')}
                  error={errors.description ? true : false}
                  helperText={errors.description ? String(errors.description.message) : null}
                />
                {chefs && (
                  <Controller
                    name={'chef_user_id'}
                    control={control}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <FormControl fullWidth error={errors?.chef_user_id ? true : false}>
                          <Autocomplete
                            id={'chef_user_id'}
                            size='small'
                            defaultValue={value}
                            options={chefs
                              .filter((menu) => menu.active)
                              .map((menu) => {
                                return {
                                  value: menu._id,
                                  label: menu.name,
                                };
                              }, [])}
                            loadingText='Carregando...'
                            getOptionLabel={(option) => option?.label ?? ''}
                            onChange={(_, option) => {
                              onChange(option);
                            }}
                            ListboxProps={{
                              style: {
                                maxHeight: '250px',
                              },
                            }}
                            isOptionEqualToValue={(option, optionValue) =>
                              option?.value === optionValue?.value
                            }
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            renderOption={(props, option: any) => {
                              return <li {...props}>{option.label}</li>;
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                required
                                id='select-chef'
                                label='Chef'
                                fullWidth
                                size='small'
                                margin='normal'
                                InputLabelProps={{ shrink: true }}
                                error={errors.chef_user_id ? true : false}
                                helperText={
                                  errors.chef_user_id ? String(errors.chef_user_id.message) : null
                                }
                              />
                            )}
                          />
                        </FormControl>
                      );
                    }}
                  />
                )}
                {watch('is_catering') ? (
                  <TextField
                    id='delivery_rate'
                    label='Taxa de Entrega'
                    type='number'
                    fullWidth
                    size='small'
                    margin='normal'
                    InputLabelProps={{ shrink: true }}
                    {...register('delivery_rate')}
                    error={errors.delivery_rate ? true : false}
                    helperText={errors.delivery_rate ? String(errors.delivery_rate.message) : null}
                  />
                ) : (
                  <>
                    <TextField
                      id='min_person'
                      label='Mínimo de Pessoas'
                      type='number'
                      fullWidth
                      size='small'
                      margin='normal'
                      InputLabelProps={{ shrink: true }}
                      {...register('min_person')}
                      error={errors.min_person ? true : false}
                      helperText={errors.min_person ? String(errors.min_person.message) : null}
                    />
                    <Typography>Preços</Typography>
                    {fields.map((item, index) => (
                      <Box
                        key={item.id}
                        paddingX={2}
                        width='100%'
                        display='flex'
                        alignItems='center'
                        flexDirection='row'
                        gap={2}
                      >
                        <TextField
                          id={`prices_per_person.${index}.price`}
                          label='Preço por Pessoa'
                          type='number'
                          size='small'
                          margin='normal'
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          {...register(`prices_per_person.${index}.price`)}
                          error={errors?.prices_per_person?.[index]?.price ? true : false}
                          helperText={
                            errors?.prices_per_person?.[index]?.price
                              ? String(errors?.prices_per_person?.[index]?.price?.message)
                              : null
                          }
                        />
                        <TextField
                          id={`prices_per_person.${index}.number_of_guests`}
                          label='Até x Pessoas'
                          type='number'
                          size='small'
                          margin='normal'
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          {...register(`prices_per_person.${index}.number_of_guests`)}
                          error={
                            errors?.prices_per_person?.[index]?.number_of_guests ? true : false
                          }
                          helperText={
                            errors?.prices_per_person?.[index]?.number_of_guests
                              ? String(
                                  errors?.prices_per_person?.[index]?.number_of_guests?.message,
                                )
                              : null
                          }
                        />
                        <IconButton aria-label='delete' onClick={() => remove(index)}>
                          <Delete color={'primary'} />
                        </IconButton>
                      </Box>
                    ))}
                    <Box>
                      <Button
                        onClick={() => {
                          append({ number_of_guests: 0, price: 0 });
                        }}
                      >
                        <Add color={'primary'} /> Adicionar preço
                      </Button>
                    </Box>
                  </>
                )}
              </>
            )}
            {activeStep === 1 && (
              <>
                <ConfigFieldArray
                  control={control}
                  register={register}
                  watch={watch}
                  errors={errors}
                  items={items}
                />
              </>
            )}
            {activeStep === 2 && (
              <>
                <InputFile
                  maxFiles={1}
                  label='Upload foto do menu'
                  files={pictureFile}
                  setFiles={setPictureFile}
                  defaultImages={menuToEdit?.picture_url ? [menuToEdit?.picture_url] : undefined}
                />
                <InputFile
                  maxFiles={20}
                  label='Upload album do menu'
                  files={albumpictureFile}
                  setFiles={setAlbumPictureFile}
                  defaultImages={menuToEdit?.album_pics ? menuToEdit.album_pics : undefined}
                />
              </>
            )}
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Button
                color='inherit'
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Anterior
              </Button>
              <Box sx={{ flex: '1 1 auto' }} />
              {!isLastStep() && (
                <Button onClick={handleNext} sx={{ mr: 1 }}>
                  Próximo
                </Button>
              )}
            </Box>
          </>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color='secondary'>
          Cancelar
        </Button>
        <Button onClick={handleSubmit(onSubmit)} color='primary'>
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MenuModal;
