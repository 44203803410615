import { Box, styled } from '@mui/material';

export const Icon = styled(Box)(({ theme }) => ({
    color: theme.palette.white.main,
    backgroundColor: theme.palette.black.main,
    borderRadius: '50%',
    width: '40px',
    height: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }));