/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import { StandaloneSearchBox, useLoadScript } from '@react-google-maps/api';
import { TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { theme } from '../../theme';

export type LocationT = { lat: number; long: number };
type GoogleAutoCompleteT = {
  required?: boolean;
  error?: boolean;
  helperText?: string;
  label: string;
  fullWidth?: boolean;
  setAddress: (address?: string, location?: LocationT) => void;
  size?: 'small' | 'medium';
  margin?: 'dense' | 'none' | 'normal';
  shrink?: boolean;
  defaultValue?: string;
};

const LIBRARIES: ('places' | 'drawing' | 'geometry' | 'visualization')[] = ['places'];

const GoogleAutoComplete = ({
  required,
  error,
  helperText,
  label,
  fullWidth,
  setAddress,
  size = 'medium',
  margin = 'dense',
  shrink = false,
  defaultValue,
}: GoogleAutoCompleteT) => {
  const inputRef = React.useRef<any>();
  const muiInputRef = React.useRef<any>();
  const [value, setValue] = React.useState<string | undefined>(defaultValue);
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY || '',
    libraries: LIBRARIES,
  });

  React.useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const handlePlaceChanged = () => {
    if (!inputRef.current) {
      return;
    }
    const places = inputRef.current.getPlaces();
    if (places?.length) {
      const [place] = places;
      if (place) {
        setValue(place.formatted_address);
        setAddress(place.formatted_address, {
          lat: place.geometry.location.lat(),
          long: place.geometry.location.lng(),
        });
      }
    }
  };

  const handleInputBlur = () => {
    if (!muiInputRef.current?.value) {
      setAddress(undefined, undefined);
    }
  };

  const defaultOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  return (
    <>
      {!isLoaded ? (
        <LoadingButton
          size='large'
          sx={{
            background: theme.palette.white.main,
            color: theme.palette.primary.main,
            height: '50px',
            marginTop: '8px',
            marginBottom: '4px',
          }}
          loading
          variant='outlined'
          fullWidth={fullWidth}
        >
          {label}
        </LoadingButton>
      ) : (
        <StandaloneSearchBox
          onLoad={(ref) => (inputRef.current = ref)}
          onPlacesChanged={handlePlaceChanged}
        >
          <TextField
            id='google-autocomplete'
            defaultValue={defaultValue ?? ''}
            value={value}
            onChange={defaultOnChange}
            required={required}
            size={size}
            fullWidth={fullWidth}
            margin={margin}
            InputLabelProps={{ shrink }}
            error={error}
            helperText={helperText}
            placeholder='Endereço'
            onBlur={handleInputBlur}
            inputRef={muiInputRef}
            sx={{ width: '100%' }}
          />
        </StandaloneSearchBox>
      )}
    </>
  );
};

export default GoogleAutoComplete;
