import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

interface SizePropsI {
  isMobile: boolean;
}

export const BoxBottomRight = styled(Box)<{ size: SizePropsI }>(({ size }) => ({
  display: 'flex',
  width: size.isMobile ? '46px' : '72px',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  position: 'absolute',
  right: 5,
  bottom: 0,
  zIndex: 9999,
  cursor: 'pointer',
}));

export const StyledImg = styled('img')<{ size: SizePropsI }>(({ size }) => ({
  width: size.isMobile ? '46px' : '72px',
  height: size.isMobile ? '46px' : '72px',
  objectFit: 'cover',
  objectPosition: 'right',
}));
