import React from 'react';
import { Box, Button, Typography, useMediaQuery } from '@mui/material';
import { theme } from '../../theme';
import { Container } from './payment.styles';
import WaitPaymentImg from '../../assets/images/WaitPaymentImg.png';
import { Link } from 'react-router-dom';

function Payment() {
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Container
      sx={{
        padding: { xs: '100px 0 15px', md: '100px 0 5px' },
        margin: {
          xs: '80px auto 115px',
          sm: '80px auto 240px',
          md: '80px auto 200px',
          xl: '80px auto min-content',
        },
      }}
    >
      <Box
        display='flex'
        alignItems='center'
        justifyContent='center'
        flexDirection='column'
        gap={1}
      >
        <Typography color='primary' variant={isMobile ? 'h6' : 'h5'} fontWeight={600}>
          Aguarde, estamos te redirecionando para realizar o pagamento
        </Typography>
        <img
          src={WaitPaymentImg}
          alt={'Aguardando pagamento'}
          style={{ height: isMobile ? 350 : 400 }}
        ></img>
        <Typography color='primary' variant={isMobile ? 'body1' : 'body1'} fontWeight={600}>
          Ou
        </Typography>
        <Link
          to='/schedule'
          style={{ textDecoration: 'none', color: theme.palette.muiTypographyText.main }}
        >
          <Button variant='contained'>Ver meus agendamentos</Button>
        </Link>
      </Box>
    </Container>
  );
}

export default Payment;
