import { apiBase } from '../../service';

export type MenuByIdConfigurationT = {
  _id: string;
  name: string;
  description: string;
  order: number;
  max_quantity?: number;
  min_quantity?: number;
  dietary_requirements?: boolean;
  config_items: {
    _id: string;
    optional_description?: string;
    max_quantity?: number;
    value?: number;
    item_id: {
      _id: string;
      name: string;
      description: string;
    };
  }[];
};

export type MenuByIdT = {
  _id?: string;
  name: string;
  description: string;
  chef_user_id: {
    chef_id: string;
    _id: string;
  };
  picture_url?: string;
  album_pics?: string[];
  is_catering: boolean;
  prices_per_person?: PricesPerPersonI[];
  min_person?: number;
  max_person?: number;
  delivery_rate?: number;
  active: boolean;
  configurations: MenuByIdConfigurationT[];
};

export type ConfigurationT = {
  name: string;
  description: string;
  order: number;
  max_quantity?: number;
  min_quantity?: number;
  dietary_requirements?: boolean;
  config_items: {
    item_id: string;
    optional_description?: string;
    value?: number;
    max_quantity?: number;
  }[];
};

export interface PricesPerPersonI {
  number_of_guests: number;
  price?: number;
}

export type MenuT = {
  _id?: string;
  name: string;
  description: string;
  chef_user_id: {
    active?: boolean;
    chef_id: string;
    name: string;
    email: string;
    user_type: 'client' | 'chef' | 'admin';
    _id: string;
  };
  picture_url?: string;
  album_pics?: string[];
  is_catering: boolean;
  prices_per_person?: PricesPerPersonI[] | undefined;
  min_person?: number;
  max_person?: number;
  delivery_rate?: number;
  active: boolean;
  configurations: ConfigurationT[];
};

export type MenuMutationT = {
  created_at?: string;
  updated_at?: string;
  __v?: number;
  _id?: string;
  name: string;
  description: string;
  picture_url?: string;
  album_pics?: string[];
  is_catering: boolean;
  prices_per_person?: PricesPerPersonI[] | undefined;
  min_person?: number;
  max_person?: number;
  delivery_rate?: number;
  active?: boolean;
  chef_user_id: string;
  configurations: ConfigurationT[];
};

export type MenuUploadImageT = {
  _id?: string;
  form?: FormData;
};

export const MenuService = apiBase.injectEndpoints({
  endpoints: (builder) => ({
    createMenu: builder.mutation<MenuMutationT, MenuMutationT>({
      query: (menu) => ({
        url: '/menu',
        method: 'POST',
        body: menu,
      }),
      invalidatesTags: ['Menu'],
    }),
    getMenus: builder.query<MenuT[], undefined>({
      query: () => ({
        url: '/menu',
      }),
      providesTags: ['Menu'],
    }),
    updateMenu: builder.mutation<MenuMutationT, MenuMutationT>({
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      query: ({ _id, album_pics, picture_url, created_at, updated_at, __v, ...rest }) => ({
        url: `/menu/${_id}`,
        method: 'PUT',
        body: rest,
      }),
      invalidatesTags: ['Menu'],
    }),
    uploadMenuImages: builder.mutation<MenuUploadImageT, MenuUploadImageT>({
      query: ({ _id, form }) => ({
        url: `/menu/${_id}/picture`,
        method: 'PATCH',
        body: form,
      }),
      invalidatesTags: ['Menu'],
    }),
    getMenuById: builder.query<MenuByIdT, string | undefined>({
      query: (menuId) => ({
        url: `/menu/${menuId}`,
      }),
      providesTags: ['Menu'],
    }),
  }),
});

export const {
  useCreateMenuMutation,
  useGetMenusQuery,
  useUpdateMenuMutation,
  useUploadMenuImagesMutation,
  useGetMenuByIdQuery,
} = MenuService;
