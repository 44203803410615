import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const Container = styled(Box)(() => ({
  width: '100%',
  margin: 'auto',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  height: 'max-content',
}));
