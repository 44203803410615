import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

interface SizePropsI {
  small: boolean;
  medium: boolean;
}

export const Container = styled(Box)<{ size: SizePropsI }>(({ size }) => ({
  width: size.small ? '100%' : size.medium ? '70%' : '50%',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  height: 'max-content',
  minHeight: '90vh',
}));
