import { format } from 'date-fns';

export function phoneFormat(number = '') {
  if (number.length === 11) return number.replace(/(\d{2})(\d{1})(\d{4})(\d{4})/, '($1) $2 $3-$4');

  if (number.length === 10) return number.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');

  return '';
}

export function cpfFormat(number = '') {
  if (number.length !== 11) return '';

  return number.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
}

export function cnpjFormat(number = '') {
  if (number.length !== 14) return '';

  return number.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
}

export const formatDate = (date: string, typeDateFormat = 'dd/MM/yyyy - HH:mm') => {
  try {
    const receivedDate = new Date(date);
    if (receivedDate) {
      const formatedDate = format(receivedDate, typeDateFormat);

      return formatedDate;
    }
  } catch (error) {
    return '';
  }
};
