import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Cookies from 'universal-cookie';
import { MeResponseT, UserService } from '../../api/userAPI';
import { RootState } from '../../store';

export type UserT = {
  name: string;
  email: string;
  user_type: 'client' | 'chef' | 'admin';
};

export type TokensT = {
  token: string;
  refresh_token: string;
};
export interface UserStateI {
  token?: string | undefined;
  refresh_token?: string | undefined;
  me?: MeResponseT | undefined;
}

const setTokensToCookie = (token: string, refresh_token: string) => {
  const cookies = new Cookies();
  cookies.set('abouteat_token', token, { path: '/', expires: new Date(Date.now() + 2592000000) });
  cookies.set('abouteat_refresh_token', refresh_token, {
    path: '/',
    expires: new Date(Date.now() + 2592000000),
  });
};

export const getTokensCookie = () => {
  const cookies = new Cookies();
  const token = cookies.get('abouteat_token');
  const refresh_token = cookies.get('abouteat_refresh_token');
  return { token, refresh_token };
};

const removeTokensCookie = () => {
  const cookies = new Cookies();
  cookies.remove('abouteat_token', { path: '/' });
  cookies.remove('abouteat_refresh_token', { path: '/' });
};

const setUserTypeToCookie = (user_type: 'client' | 'chef' | 'admin') => {
  const cookies = new Cookies();
  cookies.set('abouteat_user_type', user_type, {
    path: '/',
    expires: new Date(Date.now() + 2592000000),
  });
};

export const getUserTypeCookie = () => {
  const cookies = new Cookies();
  const user_type = cookies.get('abouteat_user_type');
  return user_type;
};

const removeUserTypeCookie = () => {
  const cookies = new Cookies();
  cookies.remove('abouteat_user_type', { path: '/' });
};

const initialState: UserStateI = {
  token: undefined,
  refresh_token: undefined,
  me: undefined,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setTokens: (state, action: PayloadAction<TokensT>) => {
      const { token, refresh_token } = action.payload;
      state.token = token;
      state.refresh_token = refresh_token;
      setTokensToCookie(token, refresh_token);
    },

    logout: (state) => {
      removeTokensCookie();
      removeUserTypeCookie();
      state.token = initialState.token;
      state.refresh_token = initialState.refresh_token;
      state.me = initialState.me;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(UserService.endpoints.login.matchFulfilled, (state, action) => {
      const { token, refresh_token } = action.payload;
      state.token = token;
      state.refresh_token = refresh_token;
      setTokensToCookie(token, refresh_token);
    });
    builder.addMatcher(UserService.endpoints.getMe.matchFulfilled, (state, action) => {
      const user = action.payload;
      state.me = user;
      setUserTypeToCookie(user.user_type);
    });
  },
});

export const { setTokens, logout } = userSlice.actions;

export const getToken = (state: RootState) => state.user.token;
export const getRefreshToken = (state: RootState) => state.user.refresh_token;
export const getMe = (state: RootState) => state.user.me;

export default userSlice.reducer;
