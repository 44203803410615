import React from 'react';

import { Box, Grid, Typography, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import PropComment1 from '../../../assets/images/popupInfo/11.png';
import PropComment2 from '../../../assets/images/popupInfo/12.png';
import ExploreHeroBG from '../../../assets/images/ExploreHeroBG.jpg';
import Effect from '../../../assets/images/effect.png';
import { theme } from '../../../theme';
import { GoldStar } from './exploreHero.styles';
import {
  CheckedIcon,
  ImgBackground,
  ImpactText,
  PopImage,
  StyledButton,
  StyledImg,
} from '../landingPage.styles';

function ExploreHero() {
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();

  const handleNavigateToExplore = () => navigate('/explore', { state: { openModal: true } });

  return (
    <Box sx={{ paddingLeft: { xs: 0, md: 40 }, paddingRight: { xs: 0, md: 5 } }}>
      <Grid container>
        <Grid
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            backgroundColor: theme.palette.white.main,
            paddingBottom: 10,
            paddingTop: isMobile ? 10 : 5,
            paddingRight: isMobile ? 0 : 20,
          }}
          item
          xs={12}
          md={6}
        >
          <Box>
            <ImpactText variant={isMobile ? 'h4' : 'h3'}>
              Encontre um Personal Chef para seu evento
            </ImpactText>
            <Typography variant='subtitle1' color='rgb(87,106,122)'>
              <CheckedIcon /> Qualquer lugar em SP • <CheckedIcon /> Receba cotações ou explore os
              menus
            </Typography>
            <StyledButton variant='outlined' color='primary' onClick={handleNavigateToExplore}>
              Começe aqui
            </StyledButton>
            <Typography variant='subtitle1' fontWeight={'bold'}>
              Avaliações excelentes <GoldStar /> Google
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <ImgBackground size={{ isMobile: Boolean(isMobile) }}>
          <Box sx={{
              position: 'absolute',
              top: 0,
              right: -10,
              zIndex: 2,
              textAlign: 'right'
            }}>
              <PopImage size={{ isMobile: Boolean(isMobile) }} src={PropComment1} />
            </Box>
            <Box sx={{
              position: 'absolute',
              bottom: 0,
              zIndex: 2,
            }}>
              <PopImage size={{ isMobile: Boolean(isMobile) }} src={PropComment2} />
            </Box>
            <Box sx={{
              position: 'absolute',
              zIndex: 1,
              width: { xs: 0, md: '100%'},
              top: 0,
            }}>
              <img src={Effect} width={'100%'} />
            </Box>
            <StyledImg
              src={ExploreHeroBG}
              alt='explore hero bg'
              size={{ isMobile: Boolean(isMobile) }}
            />
          </ImgBackground>
        </Grid>
      </Grid>
    </Box>
  );
}

export default ExploreHero;
