import { Box, Typography, useMediaQuery } from '@mui/material';
import React, { useEffect } from 'react';
import { theme } from '../../theme';
import { termMap } from './termMap';

function Terms() {
  const smallSize = useMediaQuery(theme.breakpoints.down('sm'));
  const mediumSize = useMediaQuery(theme.breakpoints.down('md'));
  const largeSize = useMediaQuery(theme.breakpoints.down('lg'));

  const readSize = () => {
    if (smallSize) {
      return 3;
    }
    if (mediumSize) {
      return 5;
    }
    if (largeSize) {
      return 8;
    }
    return 40;
  };

  useEffect(() => {
    const element = document.getElementById('box-top-page');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  return (
    <Box
      id='box-top-page'
      component='form'
      paddingTop={15}
      paddingBottom={8}
      display='flex'
      gap={2}
      flexDirection='column'
    >
      <Box display='flex' textAlign='left' paddingTop={5} paddingX={readSize()}>
        <Typography
          variant={smallSize ? 'h4' : 'h2'}
          color={theme.palette.primaryTitle.main}
          fontStyle='normal'
          fontWeight={600}
          lineHeight='normal'
          fontFamily='raleway-semibold,raleway,sans-serif'
        >
          Termos de serviço
        </Typography>
      </Box>

      <Box display='flex' textAlign='left' paddingTop={2} paddingX={readSize()}>
        <Box>
          <Box paddingBottom={2}>
            <Typography variant='subtitle1' color='rgb(87,106,122)' fontSize={14}>
              Abouteat LTDA – ME, pessoa jurídica de direito privado, inscrita no CNPJ sob nº
              49.756.857/0001-25
            </Typography>
          </Box>
          {termMap.map((item) => {
            return (
              <Box key={item.clause}>
                <Box paddingTop={2} paddingBottom={2}>
                  <Typography variant='subtitle1' color='rgb(87,106,122)' fontSize={14}>
                    {item.clause}
                  </Typography>
                </Box>
                <Box>
                  {item.body.map((clauseItem) => {
                    if (clauseItem.text) {
                      return (
                        <Typography
                          key={clauseItem.text}
                          fontFamily='sans-serif'
                          variant='caption'
                          color='rgb(87,106,122)'
                          display='flex'
                          width='100%'
                          textAlign='justify'
                        >
                          {clauseItem.text}
                          {'\n'}
                        </Typography>
                      );
                    }
                  })}
                </Box>
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
}

export default Terms;
