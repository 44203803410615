import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';

import { CRUDTemplate } from '../../../components';
import { setToastNotification } from '../../../app/slices/appSlice';
import { useAppDispatch } from '../../../app/hooks';
import {
  useCreateSystemSettingMutation,
  useGetSystemSettingQuery,
  useUpdateSystemSettingMutation,
  useRemoveSystemSettingMutation,
} from '../../../app/api/systemSettingsAPI';

export type SystemSettingsFormT = {
  name: string;
  context: string;
  value: string;
};

function SystemSettings() {
  const dispatch = useAppDispatch();
  const { data: systemSettings } = useGetSystemSettingQuery(undefined);
  const [createSystemSetting, { isSuccess: isSuccessCreate }] = useCreateSystemSettingMutation();
  const [updateSystemSetting, { isSuccess: isSuccessUpdate }] = useUpdateSystemSettingMutation();
  const [removeSystemSetting, { isSuccess: isSuccessRemove }] = useRemoveSystemSettingMutation();

  const [itemToEdit, setItemToEdit] = React.useState<string | undefined>(undefined);
  const [isModalOpen, setModalOpen] = React.useState<boolean>(false);

  const resetFields = () => {
    reset({
      name: '',
      context: '',
      value: '',
    });
  };

  React.useEffect(() => {
    if (isSuccessCreate) {
      setModalOpen(false);
      resetFields();
      dispatch(
        setToastNotification({
          type: 'success',
          open: true,
          message: 'Configurações do sistema criado com sucesso!',
        }),
      );
    }
  }, [isSuccessCreate]);

  React.useEffect(() => {
    if (isSuccessUpdate) {
      setModalOpen(false);
      resetFields();
      dispatch(
        setToastNotification({
          type: 'success',
          open: true,
          message: 'Configurações do sistema atualizado com sucesso!',
        }),
      );
    }
  }, [isSuccessUpdate]);

  React.useEffect(() => {
    if (isSuccessRemove) {
      setModalOpen(false);
      resetFields();
      dispatch(
        setToastNotification({
          type: 'success',
          open: true,
          message: 'Configurações do sistema removida com sucesso!',
        }),
      );
    }
  }, [isSuccessRemove]);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Nome é obrigatório'),
    context: Yup.string().required('Contexto é obrigatória'),
    value: Yup.string().required('Valor é obrigatório'),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<SystemSettingsFormT>({
    resolver: yupResolver(validationSchema),
  });

  const handleEditClick = (id: string) => {
    const selectedItem = systemSettings?.find((it) => it._id === id);
    if (selectedItem) {
      reset({
        name: selectedItem.name,
        context: selectedItem.context,
        value: selectedItem.value,
      });
      setItemToEdit(selectedItem._id);
      setModalOpen(true);
    }
  };

  const handleRemoveClick = async (id: string) => {
    await removeSystemSetting({ _id: id });
  };

  const handleCancelModal = () => {
    setModalOpen(false);
    setItemToEdit(undefined);
    resetFields();
  };

  const handleSubmitForm = (formValues: SystemSettingsFormT) => {
    if (itemToEdit) {
      updateSystemSetting({ ...formValues, _id: itemToEdit });
    } else {
      createSystemSetting(formValues);
    }
  };

  return (
    <>
      <CRUDTemplate
        title='Configurações do Sistema'
        onAddClick={() => setModalOpen(true)}
        onEditClick={(id: string) => handleEditClick(id)}
        onDeleteClick={(id: string) => handleRemoveClick(id)}
        hasEdit={true}
        deleteMessage='Essa configuração altera o funcionamento do sistema!'
        columns={[
          {
            field: 'name',
            headerName: 'Nome',
          },
          {
            field: 'context',
            headerName: 'Contexto',
          },
          {
            field: 'value',
            headerName: 'Valor',
          },
        ]}
        rows={systemSettings || []}
      />
      <Dialog
        open={isModalOpen}
        onClose={(event: object, reason: string) => {
          if (reason !== 'backdropClick') {
            handleCancelModal();
          }
        }}
      >
        <DialogTitle>{itemToEdit ? 'Editar' : 'Adicionar'} Configurações do sistema</DialogTitle>
        <DialogContent>
          <TextField
            required
            id='name'
            label='Nome'
            fullWidth
            size='small'
            margin='normal'
            InputLabelProps={{ shrink: true }}
            {...register('name')}
            error={errors.name ? true : false}
            helperText={errors.name ? String(errors.name.message) : null}
          />
          <TextField
            required
            id='context'
            label='Descrição'
            fullWidth
            size='small'
            margin='normal'
            InputLabelProps={{ shrink: true }}
            {...register('context')}
            error={errors.context ? true : false}
            helperText={errors.context ? String(errors.context.message) : null}
          />
          <TextField
            required
            id='value'
            label='Valor'
            fullWidth
            size='small'
            margin='normal'
            InputLabelProps={{ shrink: true }}
            {...register('value')}
            error={errors.value ? true : false}
            helperText={errors.value ? String(errors.value.message) : null}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelModal} color='secondary'>
            Cancelar
          </Button>
          <Button onClick={handleSubmit(handleSubmitForm)} color='primary'>
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default SystemSettings;
