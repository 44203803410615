import { Avatar, Box, Rating, Typography, useMediaQuery } from '@mui/material';
import { StarOutlineRounded } from '@mui/icons-material';
import { Container, DividerInfos, FormContainer } from './chefProfile.styles';
import { theme } from '../../theme';
import { useParams } from 'react-router-dom';
import { useGetChefByIdQuery } from '../../app/api/chefAPI';
import React from 'react';

function ChefProfile() {
  const { chefId } = useParams();

  const { data: chef } = useGetChefByIdQuery(chefId);

  const smallSize = useMediaQuery(theme.breakpoints.down('sm'));
  const mediumSize = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Container sx={{ padding: { xs: '0', md: '100px 0 5px' } }}>
      <FormContainer size={{ small: smallSize, medium: mediumSize }} elevation={7}>
        <Avatar
          title={chef?.name}
          alt={`Logo chef ${chef?.name}`}
          src={chef?.chef_id.picture_url}
          sx={{ width: 120, height: 120, marginBottom: 2 }}
        >
          {chef?.name.charAt(0)}
        </Avatar>

        <Box display='flex' flexDirection='row' gap={1} mb={1} flexWrap='wrap'>
          <Typography>{chef?.name}</Typography>
        </Box>

        <Box display='flex' flexDirection='row' gap={1} mb={1} alignItems='center' flexWrap='wrap'>
          <Box display='flex' alignItems='center'>
            <Typography color='primary' variant='body1' fontWeight='bold'>
              {chef &&
              chef.chef_id.evaluation_summary &&
              chef.chef_id.evaluation_summary.total_evaluation > 0 &&
              chef.scheduling_evaluations.total > 0
                ? (
                    chef?.chef_id?.evaluation_summary.total_evaluation /
                    chef?.scheduling_evaluations?.total
                  ).toFixed(1)
                : '-'}
            </Typography>
            <StarOutlineRounded color='error' fontSize='small' titleAccess='Teste' />
          </Box>
          <Typography>
            • {chef?.chef_id.evaluation_summary.total_number_of_schedulings} experiências
          </Typography>
          <Typography>• {chef?.scheduling_evaluations?.total} avaliações</Typography>
        </Box>

        <DividerInfos size={{ small: smallSize, medium: mediumSize }} variant='middle' />

        <Box
          display='flex'
          flexDirection='column'
          gap={1}
          mb={1}
          mt={1}
          maxWidth={smallSize ? 350 : mediumSize ? 600 : 650}
          minWidth={350}
          alignItems='center'
          flexWrap='wrap'
        >
          <Typography variant='body1' color={theme.palette.greyAE.main}>
            {chef?.chef_id.bio.substring(0, 500)}
          </Typography>
          <Typography>{chef?.chef_id.title}</Typography>
        </Box>

        <DividerInfos size={{ small: smallSize, medium: mediumSize }} variant='middle' />

        {!chef?.scheduling_evaluations.preview.length ? (
          <Box
            display='flex'
            flexDirection='column'
            alignItems={'center'}
            mb={1}
            mt={1}
            minWidth={300}
            maxWidth={300}
          >
            <Box
              ml={2}
              gap={1}
              alignItems='center'
              display='flex'
              flexDirection='column'
              textAlign='center'
            >
              <Typography variant='body1'>
                <strong>Chef novo na AboutEat</strong>
              </Typography>
              <Typography variant='subtitle2'>
                Ele(a) ainda não possui avaliações, mas fique tranquilo(a).
              </Typography>
              <Typography variant='subtitle2'>
                Todos os nossos chefs são qualificados e avaliados antes de ingressar na plataforma.
              </Typography>
            </Box>
            <Rating name='icon-rating' value={5} readOnly />
          </Box>
        ) : (
          <Box
            display='flex'
            flexDirection='column'
            gap={1}
            mb={1}
            mt={1}
            maxWidth={smallSize ? 350 : mediumSize ? 600 : 650}
            flexWrap='wrap'
            textAlign='start'
          >
            <Typography variant='body1' textAlign='center'>
              <strong>Avaliação dos usuários:</strong>
            </Typography>

            {chef?.scheduling_evaluations?.preview.map((item) => {
              return (
                <Box
                  key={item.client_id.name}
                  display='flex'
                  flexDirection='row'
                  mb={1}
                  mt={1}
                  minWidth={300}
                >
                  <Box
                    display='flex'
                    alignItems='center'
                    justifyContent='flex-start'
                    flexDirection='column'
                    flexWrap='wrap'
                  >
                    <Avatar alt='User Avatar' sx={{ width: 45, height: 45, marginBottom: 1 }}>
                      {item.client_id.name.charAt(0)}
                    </Avatar>
                    <Box display='flex' alignItems='center'>
                      <Typography color='primary' variant='body1' fontWeight='bold'>
                        {item.evaluation}
                      </Typography>
                      <StarOutlineRounded color='error' fontSize='small' titleAccess='Teste' />
                    </Box>
                  </Box>

                  <Box ml={2}>
                    <Typography variant='body1'></Typography>
                    <Typography variant='caption'>
                      <strong>{item.client_id.name}: </strong>
                      {item?.comment?.substring(0, 300)}
                    </Typography>
                  </Box>
                </Box>
              );
            })}
          </Box>
        )}
      </FormContainer>
    </Container>
  );
}

export default ChefProfile;
