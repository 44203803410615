import { apiBase } from '../../service';

export type ScheduleRatingT = {
  _id?: string;
  scheduling_id: number;
  evaluation: number;
  comment: string;
};

export type ScheduleRatingPostT = {
  scheduling_id: string;
  evaluation: number;
  comment?: string;
};

export const ScheduleRatingService = apiBase.injectEndpoints({
  endpoints: (builder) => ({
    createScheduleRating: builder.mutation<ScheduleRatingT, ScheduleRatingPostT>({
      query: ({ scheduling_id, evaluation, comment }) => ({
        url: '/scheduling-evaluation',
        method: 'POST',
        body: { scheduling_id, evaluation, comment },
      }),
      invalidatesTags: ['ScheduleEvaluation', 'Schedule', 'Scheduling'],
    }),
    getScheduleRating: builder.query<ScheduleRatingT[], undefined>({
      query: () => ({
        url: '/scheduling-evaluation',
      }),
      providesTags: ['ScheduleEvaluation'],
    }),
  }),
});

export const { useCreateScheduleRatingMutation, useGetScheduleRatingQuery } = ScheduleRatingService;
