import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControlLabel,
  Switch,
} from '@mui/material';

import { CRUDTemplate } from '../../../components';
import { setToastNotification } from '../../../app/slices/appSlice';
import { useAppDispatch } from '../../../app/hooks';
import {
  useCreateItemMutation,
  useGetItemsQuery,
  useUpdateItemMutation,
} from '../../../app/api/itemAPI';

export type ItemFormT = {
  name: string;
  description: string;
  active: boolean;
};

function Item() {
  const dispatch = useAppDispatch();
  const { data: items } = useGetItemsQuery(undefined);
  const [createItem, { isSuccess: isSuccessCreate }] = useCreateItemMutation();
  const [updateItem, { isSuccess: isSuccessUpdate }] = useUpdateItemMutation();
  const [itemToEdit, setItemToEdit] = React.useState<string | undefined>(undefined);
  const [isModalOpen, setModalOpen] = React.useState<boolean>(false);

  const resetFields = () => {
    reset({
      name: '',
      description: '',
      active: false,
    });
  };

  React.useEffect(() => {
    if (isSuccessCreate) {
      setModalOpen(false);
      resetFields();
      dispatch(
        setToastNotification({
          type: 'success',
          open: true,
          message: 'Item criado com sucesso!',
        }),
      );
    }
  }, [isSuccessCreate]);

  React.useEffect(() => {
    if (isSuccessUpdate) {
      setModalOpen(false);
      resetFields();
      dispatch(
        setToastNotification({
          type: 'success',
          open: true,
          message: 'Item atualizado com sucesso!',
        }),
      );
    }
  }, [isSuccessUpdate]);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Nome é obrigatório'),
    description: Yup.string().required('Descrição é obrigatória'),
    active: Yup.bool(),
  });

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm<ItemFormT>({
    resolver: yupResolver(validationSchema),
  });

  const handleEditClick = (id: string) => {
    const selectedItem = items?.find((it) => it._id === id);
    if (selectedItem) {
      reset({
        name: selectedItem.name,
        description: selectedItem.description,
        active: selectedItem.active,
      });
      setItemToEdit(selectedItem._id);
      setModalOpen(true);
    }
  };

  const handleCancelModal = () => {
    setModalOpen(false);
    setItemToEdit(undefined);
    resetFields();
  };

  const handleSubmitForm = (formValues: ItemFormT) => {
    if (itemToEdit) {
      updateItem({ ...formValues, _id: itemToEdit });
    } else {
      createItem(formValues);
    }
  };

  return (
    <>
      <CRUDTemplate
        title='Item'
        onAddClick={() => setModalOpen(true)}
        onEditClick={(id: string) => handleEditClick(id)}
        hasEdit={true}
        columns={[
          {
            field: 'name',
            headerName: 'Nome',
          },
          {
            field: 'description',
            headerName: 'Descrição',
          },
          {
            field: 'active',
            headerName: 'Ativo',
            type: 'boolean',
          },
        ]}
        rows={items || []}
      />
      <Dialog
        open={isModalOpen}
        onClose={(event: object, reason: string) => {
          if (reason !== 'backdropClick') {
            handleCancelModal();
          }
        }}
      >
        <DialogTitle>{itemToEdit ? 'Editar' : 'Adicionar'} Item</DialogTitle>
        <DialogContent>
          <TextField
            required
            id='name'
            label='Nome'
            fullWidth
            size='small'
            margin='normal'
            InputLabelProps={{ shrink: true }}
            {...register('name')}
            error={errors.name ? true : false}
            helperText={errors.name ? String(errors.name.message) : null}
          />
          <TextField
            required
            id='description'
            label='Descrição'
            fullWidth
            size='small'
            margin='normal'
            InputLabelProps={{ shrink: true }}
            {...register('description')}
            error={errors.description ? true : false}
            helperText={errors.description ? String(errors.description.message) : null}
          />
          <Controller
            control={control}
            name='active'
            render={({ field: { onChange, value } }) => (
              <FormControlLabel
                control={
                  <Switch
                    onChange={onChange}
                    checked={value ?? false}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                label='Ativo'
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelModal} color='secondary'>
            Cancelar
          </Button>
          <Button onClick={handleSubmit(handleSubmitForm)} color='primary'>
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Item;
