import { apiBase } from '../../service';
import { PricesPerPersonI } from '../menuAPI';

type UserBasicInfoT = {
  _id: string;
  name: string;
  email: string;
  phone: string;
};

export type OptionItemT = {
  item_id: {
    _id: string;
    name: string;
    description: string;
    active: boolean;
  };
  value: number;
  max_quantity: number;
  selected_quantity: number;
};

export type OptionsInfoT = {
  name: string;
  min_quantity: number;
  option_items: OptionItemT[];
  dietary_requirements: boolean;
};

export type SchedulingT = {
  _id?: string;
  client_id: UserBasicInfoT;
  menu_id: {
    _id: string;
    name: string;
    is_catering: boolean;
    picture_url: string;
    prices_per_person: PricesPerPersonI[];
  };
  chef_user_id: UserBasicInfoT;
  scheduling_state:
    | 'Finalizado'
    | 'Aguardando Pagamento'
    | 'Agendado'
    | 'Erro no pagamento'
    | 'Cancelado';
  event_date: string;
  total: number;
  delivery_rate: number;
  address: string;
  address_complement?: string;
  address_location: {
    type: string;
    coordinates: number[];
  };
  observation?: string;
  number_of_guests?: number;
  options: OptionsInfoT[];
  created_at: string;
  payment_url?: string;
  prices_per_person: PricesPerPersonI[];
  coupon?: {
    code: string;
    percentage: number;
  };
};

export type SchedulingOptionsT = {
  option_id: string;
  option_items: {
    option_item_id: string;
    selected_quantity: number;
  }[];
};

export type SchedulingPostT = {
  menu_id: string;
  event_date: string;
  address: string;
  address_complement?: string;
  latitude: string;
  longitude: string;
  observation?: string;
  number_of_guests?: number;
  options: SchedulingOptionsT[];
  coupon_id?: string;
};

export type PaymentUrlT = {
  payment_url: string;
};

export const SchedulingService = apiBase.injectEndpoints({
  endpoints: (builder) => ({
    getScheduling: builder.query<SchedulingT[], undefined>({
      query: () => ({
        url: '/scheduling',
      }),
      providesTags: ['Scheduling'],
    }),
    removeScheduling: builder.mutation<undefined, { _id: string }>({
      query: ({ _id }) => ({
        url: `/scheduling/${_id}/cancel`,
        method: 'PATCH',
      }),
      invalidatesTags: ['Scheduling'],
    }),
    createScheduling: builder.mutation<SchedulingT, SchedulingPostT>({
      query: (scheduling) => ({
        url: '/scheduling',
        method: 'POST',
        body: scheduling,
      }),
      invalidatesTags: ['Scheduling'],
    }),
    getSchedulingPaymentUrl: builder.query<PaymentUrlT, string>({
      query: (id) => ({
        url: `/scheduling/${id}/payment_url`,
      }),
    }),
  }),
});

export const {
  useGetSchedulingQuery,
  useRemoveSchedulingMutation,
  useCreateSchedulingMutation,
  useLazyGetSchedulingPaymentUrlQuery,
} = SchedulingService;
