import React from 'react';
import ModalForRegisteringEmail from '../../components/ModalForRegisteringEmail';
import { Container } from './explore.style';
import { useLocation } from 'react-router-dom';
import Features from './Features';
import Form from './Form';
import Reasons from './Reasons';
import { useMediaQuery } from '@mui/material';
import { theme } from '../../theme';

function Explore() {
  const fromLocation = useLocation();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [openModal, setOpenModal] = React.useState<boolean>(fromLocation.state?.openModal || false);
  return (
    <Container size={{ isMobile: Boolean(isMobile) }}>
      <Form />
      <Features />
      <Reasons />
      <ModalForRegisteringEmail open={openModal} onClose={() => setOpenModal(false)} />
    </Container>
  );
}

export default Explore;
