import React from 'react';

import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { TextField, Button, Avatar } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import InputMask from 'react-input-mask';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { setToastNotification } from '../../app/slices/appSlice';
import { Container, FormContainer } from './clientProfile.styles';
import { useUpdateClientMutation } from '../../app/api/userAPI';
import { getMe } from '../../app/slices/userSlice';

export type UpdateteClientFormT = {
  name: string;
  email: string;
  phone: string;
  password?: string;
};

function ClientProfile() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const me = useAppSelector(getMe);
  const [updateClient, { isLoading, isSuccess }] = useUpdateClientMutation();

  React.useEffect(() => {
    reset({ name: me?.name, email: me?.email, phone: me?.phone, password: undefined });
  }, [me]);

  React.useEffect(() => {
    if (isSuccess) {
      reset({ name: me?.name, email: me?.email, phone: me?.phone, password: undefined });
      dispatch(
        setToastNotification({
          type: 'success',
          open: true,
          message: 'Cadastro atualizado com sucesso!',
        }),
      );
    }
  }, [isSuccess]);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Nome é obrigatório'),
    email: Yup.string().required('Email é obrigatório').email('Email inválido'),
    phone: Yup.string().required('Telefone é obrigatório'),
    password: Yup.string().nullable().notRequired(),
  });

  const {
    register,
    handleSubmit,
    control,
    reset,
    getValues,
    formState: { errors },
  } = useForm<UpdateteClientFormT>({
    defaultValues: me
      ? { name: me?.name, email: me?.email, phone: me?.phone, password: undefined }
      : {},
    resolver: yupResolver(validationSchema),
  });

  const handleSubmitForm = (formValues: UpdateteClientFormT) => {
    updateClient({ ...formValues, phone: formValues.phone.replace(/[^\d]+/g, '') });
  };

  const handleNavigateBack = () => navigate(-1);

  return (
    <Container>
      <FormContainer elevation={7}>
        <Avatar alt='User Avatar' sx={{ width: 80, height: 80, marginBottom: 2 }}>
          {me?.name?.charAt(0)}
        </Avatar>
        <TextField
          required
          id='name'
          label='Nome'
          fullWidth
          size='small'
          margin='normal'
          InputLabelProps={{ shrink: true }}
          defaultValue={getValues('name')}
          autoComplete='off'
          {...register('name')}
          error={errors.name ? true : false}
          helperText={errors.name ? String(errors.name.message) : null}
        />
        <TextField
          required
          id='email'
          label='Email'
          fullWidth
          size='small'
          margin='normal'
          InputLabelProps={{ shrink: true }}
          defaultValue={me?.email}
          {...register('email')}
          error={errors.email ? true : false}
          helperText={errors.email ? String(errors.email.message) : null}
        />
        <Controller
          name='phone'
          control={control}
          defaultValue={me?.phone}
          render={({ field: { onChange, value } }) => (
            <InputMask mask='(99) 99999-9999' value={value} onChange={onChange}>
              <TextField
                required
                id='phone'
                label='Telefone'
                fullWidth
                type='tel'
                size='small'
                margin='normal'
                InputLabelProps={{ shrink: true }}
                error={errors.phone ? true : false}
                helperText={errors.phone ? String(errors.phone.message) : null}
              />
            </InputMask>
          )}
        />

        <InputMask mask='999.999.999-99' value={me?.document} disabled>
          <TextField
            id='document'
            label='Cpf'
            fullWidth
            type='text'
            size='small'
            margin='normal'
            InputLabelProps={{ shrink: true }}
          />
        </InputMask>

        <TextField
          required
          id='password'
          label='Senha'
          type='password'
          fullWidth
          size='small'
          margin='normal'
          InputLabelProps={{ shrink: true }}
          autoComplete='off'
          inputProps={{
            autocomplete: 'new-password',
            form: {
              autocomplete: 'off',
            },
          }}
          {...register('password')}
          error={errors.password ? true : false}
          helperText={errors.password ? String(errors.password.message) : null}
        />
        {isLoading ? (
          <LoadingButton
            size='large'
            sx={{ marginTop: '15px' }}
            loading
            variant='contained'
            color='primary'
            fullWidth
          >
            Atualizar
          </LoadingButton>
        ) : (
          <Button
            size='large'
            sx={{ marginTop: '15px' }}
            variant='contained'
            color='primary'
            onClick={handleSubmit(handleSubmitForm)}
            fullWidth
          >
            Atualizar
          </Button>
        )}
        <Button
          size='large'
          sx={{ marginTop: '15px' }}
          variant='text'
          color='secondary'
          onClick={handleNavigateBack}
          fullWidth
        >
          Voltar
        </Button>
      </FormContainer>
    </Container>
  );
}

export default ClientProfile;
