import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

interface SizePropsI {
  isMobile: boolean;
}

export const Container = styled(Box)<{ size: SizePropsI }>(({ size }) => ({
  padding: size.isMobile ? '100px 10px 50px' : '100px 50px 50px',
}));
