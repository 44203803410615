import { styled } from '@mui/material/styles';
import { AppBar, Button, Box, Typography } from '@mui/material';

export const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  height: '80px',
  display: 'flex',
  justifyContent: 'center',
}));

export const StyledLogo = styled('img')(() => ({
  height: '70px',
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  color: theme.palette.white.main,
  marginLeft: '15px',
  textTransform: 'none',
}));

export const StyledBoxMobileMenu = styled(Box)(() => ({
  flexGrow: 0,
}));

export const StyledBoxWebMenu = styled(Box)(() => ({
  flexGrow: 1,
}));

export const StyledUserAvatar = styled(Box)(() => ({
  flexGrow: 0,
  marginLeft: 'auto',
}));

export const StyledLoginTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.white.main,
  marginLeft: '5px',
  textTransform: 'none',
}));
