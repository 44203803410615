import React from 'react';

import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { TextField, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';

import { useCreateClientMutation } from '../../app/api/userAPI';
import { useAppDispatch } from '../../app/hooks';
import { setToastNotification } from '../../app/slices/appSlice';
import Logo from '../../assets/images/LogoShort.png';
import { Container, FormContainer, StyledLogo } from './signup.styles';
import InputMask from 'react-input-mask';

export type CreateClientFormT = {
  name: string;
  email: string;
  phone: string;
  document: string;
  password: string;
  confirmPassword: string;
};

function SignUp() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [createClient, { isLoading, isSuccess }] = useCreateClientMutation();

  React.useEffect(() => {
    if (isSuccess) {
      navigate('/sign-in');
      dispatch(
        setToastNotification({
          type: 'success',
          open: true,
          message: 'Cadastro realizado com sucesso, bem vindo ao AboutEat!',
        }),
      );
    }
  }, [isSuccess]);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Nome é obrigatório'),
    email: Yup.string().required('Email é obrigatório').email('Email inválido'),
    phone: Yup.string().required('Celular é obrigatório'),
    document: Yup.string().required('Documento é obrigatório'),
    password: Yup.string()
      .required('Senha é obrigatória')
      .min(6, 'Senha deve conter ao menos 6 caracteres'),
    confirmPassword: Yup.string()
      .required('Confirmar a senha é obrigatório')
      .oneOf([Yup.ref('password'), null], 'Senhas não idênticas'),
  });

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<CreateClientFormT>({
    resolver: yupResolver(validationSchema),
  });

  const handleSubmitForm = (formValues: CreateClientFormT) => {
    createClient({
      ...formValues,
      phone: formValues.phone.replace(/[^\d]+/g, ''),
      document: formValues.document.replace(/[^\d]+/g, ''),
    });
  };

  const handleNavigateBack = () => navigate(-1);

  return (
    <Container>
      <FormContainer elevation={7}>
        <StyledLogo src={Logo} alt='Logo' />
        <TextField
          required
          id='name'
          label='Nome'
          fullWidth
          size='small'
          margin='normal'
          InputLabelProps={{ shrink: true }}
          {...register('name')}
          error={errors.name ? true : false}
          helperText={errors.name ? String(errors.name.message) : null}
        />
        <TextField
          required
          id='email'
          label='Email'
          fullWidth
          size='small'
          margin='normal'
          InputLabelProps={{ shrink: true }}
          {...register('email')}
          error={errors.email ? true : false}
          helperText={errors.email ? String(errors.email.message) : null}
        />
        <Controller
          name='phone'
          control={control}
          defaultValue=''
          render={({ field: { onChange, value } }) => (
            <InputMask mask='(99) 99999-9999' value={value} onChange={onChange}>
              <TextField
                required
                id='phone'
                label='Telefone'
                fullWidth
                type='tel'
                size='small'
                margin='normal'
                InputLabelProps={{ shrink: true }}
                error={errors.phone ? true : false}
                helperText={errors.phone ? String(errors.phone.message) : null}
              />
            </InputMask>
          )}
        />
        <Controller
          name='document'
          control={control}
          defaultValue=''
          render={({ field: { onChange, value } }) => (
            <InputMask mask='999.999.999-99' value={value} onChange={onChange}>
              <TextField
                required
                id='document'
                label='Cpf'
                fullWidth
                type='text'
                size='small'
                margin='normal'
                InputLabelProps={{ shrink: true }}
                error={errors.document ? true : false}
                helperText={errors.document ? String(errors.document.message) : null}
              />
            </InputMask>
          )}
        />
        <TextField
          required
          id='password'
          label='Senha'
          type='password'
          fullWidth
          size='small'
          margin='normal'
          InputLabelProps={{ shrink: true }}
          {...register('password')}
          error={errors.password ? true : false}
          helperText={errors.password ? String(errors.password.message) : null}
        />
        <TextField
          required
          id='confirmPassword'
          label='Confirmar Senha'
          type='password'
          fullWidth
          size='small'
          margin='normal'
          InputLabelProps={{ shrink: true }}
          {...register('confirmPassword')}
          error={errors.confirmPassword ? true : false}
          helperText={errors.confirmPassword ? String(errors.confirmPassword.message) : null}
        />
        {isLoading ? (
          <LoadingButton
            size='large'
            sx={{ marginTop: '15px' }}
            loading
            variant='contained'
            color='primary'
            fullWidth
          >
            Cadastrar
          </LoadingButton>
        ) : (
          <Button
            size='large'
            sx={{ marginTop: '15px' }}
            variant='contained'
            color='primary'
            onClick={handleSubmit(handleSubmitForm)}
            fullWidth
          >
            Cadastrar
          </Button>
        )}
        <Button
          size='large'
          sx={{ marginTop: '15px' }}
          variant='text'
          color='secondary'
          onClick={handleNavigateBack}
          fullWidth
        >
          Cancelar
        </Button>
      </FormContainer>
    </Container>
  );
}

export default SignUp;
