import { PricesPerPersonI } from '../app/api/menuAPI';

interface getPriceByNumberOfGuestI {
  pricesPerPerson?: PricesPerPersonI[];
  numberOfGuestToSearch: number;
}

export const getPriceByNumberOfGuest = ({
  numberOfGuestToSearch,
  pricesPerPerson,
}: getPriceByNumberOfGuestI) => {
  let priceObjectFound = undefined;
  if (pricesPerPerson && pricesPerPerson.length > 0 && numberOfGuestToSearch) {
    const index = pricesPerPerson.findIndex(
      (config) => config.number_of_guests >= numberOfGuestToSearch,
    );
    if (index === -1) {
      priceObjectFound = pricesPerPerson[pricesPerPerson.length - 1];
    } else {
      priceObjectFound = pricesPerPerson[index];
    }
  }
  return priceObjectFound;
};

export function getLowestPrice(pricesPerPerson?: PricesPerPersonI[]): number | undefined {
  if (!pricesPerPerson) {
    return undefined;
  }
  return pricesPerPerson.reduce(
    (lowestPrice: number | undefined, currentPrice: PricesPerPersonI) => {
      if (
        currentPrice.price !== undefined &&
        (lowestPrice === undefined || currentPrice.price < lowestPrice)
      ) {
        return currentPrice.price;
      } else {
        return lowestPrice;
      }
    },
    undefined,
  );
}

export const getMaxNumberOfGuests = (pricesPerPerson?: PricesPerPersonI[]): number | undefined => {
  if (!pricesPerPerson) {
    return undefined;
  }
  const maxNumberOfGuests = pricesPerPerson.reduce((acc, cur) => {
    if (cur.number_of_guests > acc) {
      return cur.number_of_guests;
    }
    return acc;
  }, 0);

  return maxNumberOfGuests;
};
