import { apiBase } from '../../service';

export type SystemSettingT = {
  _id?: string;
  name: string;
  context: string;
  value: string;
};

export const SystemSettingService = apiBase.injectEndpoints({
  endpoints: (builder) => ({
    createSystemSetting: builder.mutation<SystemSettingT, SystemSettingT>({
      query: (item) => ({
        url: '/system-settings',
        method: 'POST',
        body: item,
      }),
      invalidatesTags: ['SystemSetting'],
    }),
    getSystemSetting: builder.query<SystemSettingT[], undefined>({
      query: () => ({
        url: '/system-settings',
      }),
      providesTags: ['SystemSetting'],
    }),
    removeSystemSetting: builder.mutation<SystemSettingT, { _id: string }>({
      query: ({ _id }) => ({
        url: `/system-settings/${_id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['SystemSetting'],
    }),
    updateSystemSetting: builder.mutation<SystemSettingT, SystemSettingT>({
      query: ({ _id, name, context, value }) => ({
        url: `/system-settings/${_id}`,
        method: 'PUT',
        body: { name, context, value },
      }),
      invalidatesTags: ['SystemSetting'],
    }),
  }),
});

export const {
  useCreateSystemSettingMutation,
  useGetSystemSettingQuery,
  useUpdateSystemSettingMutation,
  useRemoveSystemSettingMutation,
} = SystemSettingService;
