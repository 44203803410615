import { apiBase } from '../../service';

export type ContactT = {
  _id?: string;
  name: string;
  email: string;
  message: string;
  read?: boolean;
  created_at: Date;
  updated_at?: Date;
};

export const ContactService = apiBase.injectEndpoints({
  endpoints: (builder) => ({
    createContact: builder.mutation({
      query: ({ email, name, message }) => ({
        url: '/contact',
        method: 'POST',
        body: { email, name, message },
      }),
    }),
    getContacts: builder.query<ContactT[], undefined>({
      query: () => ({
        url: '/contact',
      }),
      providesTags: ['Contact'],
    }),
    updateContact: builder.mutation<ContactT, ContactT>({
      query: ({ _id, read }) => ({
        url: `/contact/${_id}`,
        method: 'PUT',
        body: { read },
      }),
      invalidatesTags: ['Contact'],
    }),
  }),
});

export const { useCreateContactMutation, useGetContactsQuery, useUpdateContactMutation } =
  ContactService;
