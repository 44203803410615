import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  InputAdornment,
} from '@mui/material';

import { CRUDTemplate } from '../../../components';

import { setToastNotification } from '../../../app/slices/appSlice';
import { useAppDispatch } from '../../../app/hooks';
import {
  useCreateCouponMutation,
  useGetCouponsQuery,
  useRemoveCouponMutation,
  useUpdateCouponMutation,
} from '../../../app/api/couponAPI';

// 1qaz2wsx3edc4rfv
export type DiscountCouponI = {
  code: string;
  percentage: number;
};

function DiscountCoupon() {
  const dispatch = useAppDispatch();

  const { data } = useGetCouponsQuery(undefined);
  const [createCoupon, { isSuccess: isSuccessCreate }] = useCreateCouponMutation();
  const [updateCoupon, { isSuccess: isSuccessUpdate }] = useUpdateCouponMutation();
  const [removeCoupon, { isSuccess: isSuccessRemove }] = useRemoveCouponMutation();

  const [isModalOpen, setModalOpen] = React.useState<boolean>(false);
  const [discountToEdit, setDiscountToEdit] = React.useState<string | undefined>(undefined);

  const defaultFields = {
    code: '',
    percentage: 0,
  };

  const validationSchema = Yup.object().shape({
    code: Yup.string().required('Codigo do Cupom é obrigatório'),
    percentage: Yup.number()
      .required('Porcentagem é obrigatório')
      .typeError('Porcentagem é obrigatório')
      .positive('Porcentagem precisa ser maior que 0')
      .min(1, 'Porcentagem minima é de 1%')
      .max(100, 'Porcentagem máxima é de 100%'),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<DiscountCouponI>({
    resolver: yupResolver(validationSchema),
  });

  React.useEffect(() => {
    if (isSuccessCreate) {
      setModalOpen(false);
      defaultResetFields();
      dispatch(
        setToastNotification({
          type: 'success',
          open: true,
          message: 'Cupom criado com sucesso!',
        }),
      );
    }
  }, [isSuccessCreate]);

  React.useEffect(() => {
    if (isSuccessUpdate) {
      setModalOpen(false);
      defaultResetFields();
      dispatch(
        setToastNotification({
          type: 'success',
          open: true,
          message: 'Cupom atualizado com sucesso!',
        }),
      );
    }
  }, [isSuccessUpdate]);

  React.useEffect(() => {
    if (isSuccessRemove) {
      setModalOpen(false);
      defaultResetFields();
      dispatch(
        setToastNotification({
          type: 'success',
          open: true,
          message: 'Cupom removido com sucesso!',
        }),
      );
    }
  }, [isSuccessRemove]);

  const defaultResetFields = () => {
    reset(defaultFields);
  };

  React.useEffect(() => {
    defaultResetFields();
  }, []);

  const handleEditClick = async (id: string) => {
    const coupon = data?.find((item) => item?._id?.toString() === id.toString());
    if (coupon) {
      const { code, percentage } = coupon;
      reset({
        code,
        percentage,
      });
      setDiscountToEdit(coupon._id);
      setModalOpen(true);
    }
  };

  const handleDeleteClick = async (id: string) => {
    removeCoupon({ _id: id });
  };

  const handleCancelModal = () => {
    setModalOpen(false);
    setDiscountToEdit(undefined);
    defaultResetFields();
  };

  const handleSubmitForm = (formValues: DiscountCouponI) => {
    if (discountToEdit) {
      updateCoupon({
        ...formValues,
        _id: discountToEdit,
      });
    } else {
      createCoupon({
        ...formValues,
      });
    }
  };

  return (
    <>
      <CRUDTemplate
        title='Cupom de desconto'
        onAddClick={() => {
          setDiscountToEdit(undefined);
          setModalOpen(true);
        }}
        onEditClick={(id: string) => handleEditClick(id)}
        onDeleteClick={(id: string) => handleDeleteClick(id)}
        hasEdit={true}
        hasDelete={true}
        columns={[
          {
            field: 'code',
            headerName: 'Codigo',
          },
          {
            field: 'percentage',
            headerName: 'Porcentagem',
          },
        ]}
        rows={data ?? []}
      />
      <Dialog
        open={isModalOpen}
        onClose={(event: object, reason: string) => {
          if (reason !== 'backdropClick') {
            handleCancelModal();
          }
        }}
        sx={{
          '.pac-container': {
            'z-index': 1051,
          },
        }}
      >
        <DialogTitle>{discountToEdit ? 'Editar' : 'Adicionar'} Cupom de desconto</DialogTitle>
        <DialogContent>
          <Box display='flex' flexDirection='row' gap={2} width='100%'>
            <TextField
              required
              id='code'
              label='Codigo do Cupom'
              fullWidth
              size='small'
              margin='normal'
              InputLabelProps={{ shrink: true }}
              {...register('code')}
              error={errors.code ? true : false}
              helperText={errors.code ? String(errors.code.message) : null}
            />
          </Box>
          <Box display='flex' flexDirection='row' gap={2} width='100%'>
            <TextField
              required
              id='percentage'
              label='Porcentagem'
              fullWidth
              size='small'
              margin='normal'
              placeholder='10%'
              autoComplete='off'
              inputProps={{
                autocomplete: 'new-password',
                form: {
                  autocomplete: 'off',
                },
              }}
              InputProps={{
                endAdornment: <InputAdornment position='end'>%</InputAdornment>,
              }}
              type='number'
              InputLabelProps={{ shrink: true }}
              {...register('percentage')}
              error={errors.percentage ? true : false}
              helperText={errors.percentage ? String(errors.percentage.message) : null}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelModal} color='secondary'>
            Cancelar
          </Button>
          <Button onClick={handleSubmit(handleSubmitForm)} color='primary'>
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default DiscountCoupon;
