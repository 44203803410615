import React from 'react';

import { Route, Routes } from 'react-router-dom';
import { useAppSelector } from '../app/hooks';
import { getMe, getToken, getUserTypeCookie, getTokensCookie } from '../app/slices/userSlice';
import { Wrapper } from '../components';
import {
  AppointmentsPerClient,
  AvailabilityConfig,
  Booking,
  Category,
  Chef,
  ChefProfile,
  Contact,
  ClientProfile,
  Dashboard,
  Home,
  Favorites,
  Explore,
  HowItWorks,
  Item,
  Menu,
  Payment,
  Schedule,
  SignIn,
  SignUp,
  Terms,
  AdminContact,
  AdminSystemSetting,
  Catering,
  CateringSearch,
  ExploreSearch,
} from '../pages';
import AdminProfile from '../pages/Admin/Profile';
import ChefOwnProfile from '../pages/ChefOwnProfile';

import ProtectedRoute from './ProtectedRoute';
import DiscountCoupon from '../pages/Admin/DiscountCoupon';

function App() {
  const reduxToken = useAppSelector(getToken);
  const { token: cookieToken } = getTokensCookie();
  const token = reduxToken || cookieToken;
  const me = useAppSelector(getMe);
  const reduxUserType = me?.user_type;
  const cookieUserType = getUserTypeCookie();
  const userType = reduxUserType || cookieUserType;

  return (
    <>
      <Routes>
        <Route
          path='/'
          element={
            <Wrapper showHeader showFooter>
              <Home />
            </Wrapper>
          }
        />
        <Route path='/sign-in'>
          <Route path=':userType'>
            <Route
              path=''
              element={
                <Wrapper>
                  <SignIn />
                </Wrapper>
              }
            />
            <Route
              path=':redirectTo'
              element={
                <Wrapper>
                  <SignIn />
                </Wrapper>
              }
            />
          </Route>
          <Route
            path=''
            element={
              <Wrapper>
                <SignIn />
              </Wrapper>
            }
          />
        </Route>
        <Route
          path='/sign-up'
          element={
            <Wrapper>
              <SignUp />
            </Wrapper>
          }
        />
        <Route
          path='/terms'
          element={
            <Wrapper showHeader showFooter>
              <Terms />
            </Wrapper>
          }
        />
        <Route path='/explore'>
          <Route
            path=''
            element={
              <Wrapper showHeader showFooter>
                <Explore />
              </Wrapper>
            }
          />
          <Route
            path='search'
            element={
              <Wrapper showHeader showFooter>
                <ExploreSearch />
              </Wrapper>
            }
          />
        </Route>
        <Route path='/catering'>
          <Route
            path=''
            element={
              <Wrapper showHeader showFooter>
                <Catering />
              </Wrapper>
            }
          />
          {/* <Route
            path='search'
            element={
              <Wrapper showHeader showFooter>
                <CateringSearch />
              </Wrapper>
            }
          /> */}
        </Route>
        <Route
          path='/favorites'
          element={
            <ProtectedRoute
              redirectPath='/sign-in/favorite'
              isAllowed={Boolean(token && userType === 'client')}
            >
              <Wrapper showHeader showFooter>
                <Favorites />
              </Wrapper>
            </ProtectedRoute>
          }
        />
        <Route
          path='/how-it-works'
          element={
            <Wrapper showHeader showFooter>
              <HowItWorks />
            </Wrapper>
          }
        />
        <Route
          path='/contact'
          element={
            <Wrapper showHeader showFooter footerPosition='fixed'>
              <Contact />
            </Wrapper>
          }
        />
        <Route path='/chef-profile'>
          <Route
            path=':chefId'
            element={
              <Wrapper showHeader showFooter>
                <ChefProfile />
              </Wrapper>
            }
          />
        </Route>
        <Route path='/booking'>
          <Route
            path=':menuId'
            element={
              <Wrapper showHeader showFooter>
                <Booking />
              </Wrapper>
            }
          />
        </Route>
        <Route
          path='/availability-config'
          element={
            <ProtectedRoute
              redirectPath='/sign-in/chef/availability-config'
              isAllowed={Boolean(token && userType === 'chef')}
            >
              <Wrapper showHeader>
                <AvailabilityConfig />
              </Wrapper>
            </ProtectedRoute>
          }
        />
        <Route
          path='/schedule'
          element={
            <ProtectedRoute
              redirectPath='/sign-in/client/schedule'
              isAllowed={Boolean(token && (userType === 'chef' || userType === 'client'))}
            >
              <Wrapper showHeader showFooter>
                <Schedule />
              </Wrapper>
            </ProtectedRoute>
          }
        />
        <Route
          path='/client-profile'
          element={
            <ProtectedRoute
              redirectPath='/sign-in/client/client-profile'
              isAllowed={Boolean(token && userType === 'client')}
            >
              <Wrapper showHeader showFooter>
                <ClientProfile />
              </Wrapper>
            </ProtectedRoute>
          }
        />
        <Route
          path='/chef-own-profile'
          element={
            <ProtectedRoute
              redirectPath='/sign-in/chef/chef-own-profile'
              isAllowed={Boolean(token && userType === 'chef')}
            >
              <Wrapper showHeader showFooter>
                <ChefOwnProfile />
              </Wrapper>
            </ProtectedRoute>
          }
        />
        <Route
          path='/admin-profile'
          element={
            <ProtectedRoute
              redirectPath='/sign-in/admin/admin-profile'
              isAllowed={Boolean(token && userType === 'admin')}
            >
              <Wrapper showHeader showFooter>
                <AdminProfile />
              </Wrapper>
            </ProtectedRoute>
          }
        />
        <Route
          path='/payment'
          element={
            <ProtectedRoute
              redirectPath='/sign-in/client/payment'
              isAllowed={Boolean(token && userType === 'client')}
            >
              <Wrapper showHeader showFooter footerPosition='fixed'>
                <Payment />
              </Wrapper>
            </ProtectedRoute>
          }
        />

        <Route path='/admin'>
          <Route
            path=''
            element={
              <ProtectedRoute
                redirectPath='/sign-in/admin'
                isAllowed={Boolean(token && userType === 'admin')}
              >
                <Wrapper showHeader showFooter footerPosition='fixed'>
                  <Dashboard />
                </Wrapper>
              </ProtectedRoute>
            }
          />
          <Route
            path='category'
            element={
              <ProtectedRoute
                redirectPath='/sign-in/admin/category'
                isAllowed={Boolean(token && userType === 'admin')}
              >
                <Wrapper showHeader>
                  <Category />
                </Wrapper>
              </ProtectedRoute>
            }
          />
          <Route
            path='appointments-per-client'
            element={
              <ProtectedRoute
                redirectPath='/sign-in/admin/appointments-per-client'
                isAllowed={Boolean(token && userType === 'admin')}
              >
                <Wrapper showHeader>
                  <AppointmentsPerClient />
                </Wrapper>
              </ProtectedRoute>
            }
          />
          <Route
            path='menu'
            element={
              <ProtectedRoute
                redirectPath='/sign-in/admin/menu'
                isAllowed={Boolean(token && userType === 'admin')}
              >
                <Wrapper showHeader>
                  <Menu />
                </Wrapper>
              </ProtectedRoute>
            }
          />
          <Route
            path='chef'
            element={
              <ProtectedRoute
                redirectPath='/sign-in/admin/chef'
                isAllowed={Boolean(token && userType === 'admin')}
              >
                <Wrapper showHeader>
                  <Chef />
                </Wrapper>
              </ProtectedRoute>
            }
          />
          <Route
            path='discount-coupon'
            element={
              <ProtectedRoute
                redirectPath='/sign-in/admin/discount-coupon'
                isAllowed={Boolean(token && userType === 'admin')}
              >
                <Wrapper showHeader>
                  <DiscountCoupon />
                </Wrapper>
              </ProtectedRoute>
            }
          />
          <Route
            path='item'
            element={
              <ProtectedRoute
                redirectPath='/sign-in/admin/item'
                isAllowed={Boolean(token && userType === 'admin')}
              >
                <Wrapper showHeader>
                  <Item />
                </Wrapper>
              </ProtectedRoute>
            }
          />
          <Route
            path='contact'
            element={
              <ProtectedRoute
                redirectPath='/sign-in/admin/contact'
                isAllowed={Boolean(token && userType === 'admin')}
              >
                <Wrapper showHeader>
                  <AdminContact />
                </Wrapper>
              </ProtectedRoute>
            }
          />
          <Route
            path='system-setting'
            element={
              <ProtectedRoute
                redirectPath='/sign-in/admin/system-setting'
                isAllowed={Boolean(token && userType === 'admin')}
              >
                <Wrapper showHeader>
                  <AdminSystemSetting />
                </Wrapper>
              </ProtectedRoute>
            }
          />
        </Route>
      </Routes>
    </>
  );
}
export default App;
