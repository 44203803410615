import React from 'react';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineOppositeContent,
  TimelineSeparator,
} from '@mui/lab';
import { Box, Button, Typography, useMediaQuery } from '@mui/material';
import { Container, BoxCenter, CardSchedule, TimeLineItemAE } from './schedule.styles';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { getToken, getMe } from '../../app/slices/userSlice';
import { SchedulingT, useGetSchedulingQuery } from '../../app/api/schedulingAPI';
import { setToastNotification } from '../../app/slices/appSlice';

import ScheduleImg from '../../assets/images/ScheduleImg.png';
import { theme } from '../../theme';
import { formatStatusIcon, ScheduleDetailModal } from '../../components/ScheduleDetailModal';
import { formatDate } from '../../utils/formats';
import { StarRate, Visibility } from '@mui/icons-material';
import { RatingScheduleModal } from '../../components/RatingScheduleModal';
import { useGetScheduleRatingQuery } from '../../app/api/scheduleRatingAPI';

function Schedule() {
  const dispatch = useAppDispatch();
  const token = useAppSelector(getToken);
  const me = useAppSelector(getMe);

  const smallSize = useMediaQuery(theme.breakpoints.down('sm'));

  const [isModalRatingOpen, setModalRatingOpen] = React.useState<boolean>(false);

  const [isModalOpen, setModalOpen] = React.useState<boolean>(false);
  const [schedulingViewId, setSchedulingViewId] = React.useState<string | undefined>(undefined);
  const [schedulingView, setSchedulingView] = React.useState<SchedulingT | undefined>(undefined);

  const { data: schedulings } = useGetSchedulingQuery(undefined, {
    skip: !token,
  });

  const { data: ratings } = useGetScheduleRatingQuery(undefined);

  const handleCancelModal = () => {
    setModalOpen(false);
    setModalRatingOpen(false);
    setSchedulingView(undefined);
    setSchedulingViewId(undefined);
  };

  const openPopupInfoAvailableRating = () => {
    dispatch(
      setToastNotification({
        type: 'info',
        open: true,
        message: 'Existe agendamentos que não foram avaliados!',
      }),
    );
  };

  React.useEffect(() => {
    if (me?.user_type === 'client') {
      let isAvailable = false;
      schedulings?.forEach((scheduling) => {
        const { _id, scheduling_state } = scheduling;
        if (
          Date.now() >= new Date(scheduling.event_date).getTime() &&
          !ratings?.find((rating) => String(rating.scheduling_id) === _id) &&
          scheduling_state === 'Agendado'
        ) {
          isAvailable = true;
        }
      });
      if (isAvailable) {
        openPopupInfoAvailableRating();
      }
    }
  }, [schedulings]);

  React.useEffect(() => {
    const currentScheduling = schedulings?.find(
      (scheduling) => scheduling._id === schedulingViewId,
    );
    setSchedulingView(currentScheduling);
  }, [isModalOpen]);

  return (
    <Container
      sx={{
        padding: { xs: '100px 0 15px', md: '100px 0 5px' },
        margin: {
          xs: '10px auto 115px',
          sm: '10px auto 240px',
          md: '10px auto 200px',
          xl: '10px auto 55px',
        },
      }}
    >
      <BoxCenter>
        <Typography variant={smallSize ? 'h3' : 'h2'} color='primary'>
          Minha agenda
        </Typography>
      </BoxCenter>

      <BoxCenter mt={8}>
        {schedulings?.length ? (
          <Timeline>
            {schedulings?.length &&
              schedulings?.map((scheduling) => {
                const {
                  _id,
                  chef_user_id,
                  client_id,
                  menu_id,
                  address,
                  event_date,
                  scheduling_state,
                } = scheduling;

                let isRatingAvailable = true;

                if (
                  Date.now() < new Date(scheduling.event_date).getTime() ||
                  ratings?.find((rating) => String(rating.scheduling_id) === _id) ||
                  scheduling_state !== 'Agendado'
                ) {
                  isRatingAvailable = false;
                }

                return (
                  <TimeLineItemAE key={_id}>
                    <TimelineOppositeContent color='textSecondary'>
                      {formatDate(event_date)}
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineDot color='primary' />
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent sx={{ py: -10, px: 2, width: 'max-content' }}>
                      <CardSchedule width={smallSize ? '150px' : '300px'}>
                        <Box>
                          <Typography variant='subtitle2' color='primary' component='span'>
                            {me?.user_type === 'client'
                              ? `Chef: ${chef_user_id.name}`
                              : `Cliente: ${client_id.name}`}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography variant='caption'>
                            <b>Menu</b>: {menu_id.name}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography variant='caption'>
                            <b>Local</b>: {address}
                          </Typography>
                        </Box>
                        <Box display='flex' alignItems='flex-end' justifyContent='flex-end' mt={2}>
                          {formatStatusIcon(scheduling_state)}
                        </Box>
                        <Box margin={-2} mt={1}>
                          <Box
                            display='flex'
                            justifyContent='space-evenly'
                            sx={{
                              backgroundColor: theme.palette.primary.main,
                              borderBottomRightRadius: '16px',
                              borderBottomLeftRadius: '16px',
                              height: '35px',
                            }}
                          >
                            {isRatingAvailable && me?.user_type === 'client' ? (
                              <>
                                <Button
                                  size='small'
                                  fullWidth
                                  sx={{
                                    color: theme.palette.white.main,
                                    borderRight: '1px solid white',
                                  }}
                                  onClick={() => {
                                    setModalRatingOpen(true);
                                    setSchedulingViewId(_id);
                                  }}
                                >
                                  <StarRate fontSize='small' />
                                </Button>
                                <Button
                                  size='small'
                                  fullWidth
                                  sx={{
                                    color: theme.palette.white.main,
                                    borderLeft: '1px solid white',
                                  }}
                                  onClick={() => {
                                    setModalOpen(true);
                                    setSchedulingViewId(_id);
                                  }}
                                >
                                  <Visibility fontSize='small' />
                                </Button>
                              </>
                            ) : (
                              <Button
                                size='small'
                                fullWidth
                                sx={{
                                  color: theme.palette.white.main,
                                }}
                                onClick={() => {
                                  setModalOpen(true);
                                  setSchedulingViewId(_id);
                                }}
                              >
                                <Visibility fontSize='small' />
                              </Button>
                            )}
                          </Box>
                        </Box>
                      </CardSchedule>
                    </TimelineContent>
                  </TimeLineItemAE>
                );
              })}
          </Timeline>
        ) : (
          <>
            <Box
              display='flex'
              alignItems='center'
              justifyContent='center'
              flexDirection='column'
              gap={4}
              m={smallSize ? 25 : 20}
            >
              <img src={ScheduleImg} alt={'Nenhuma categoria encontrada'} width={300}></img>
              <Typography color='primary' variant={smallSize ? 'h6' : 'h4'} fontWeight={600}>
                Seus agendamentos ficarão aqui
              </Typography>
            </Box>
          </>
        )}
      </BoxCenter>
      <ScheduleDetailModal data={schedulingView} open={isModalOpen} onClose={handleCancelModal} />
      <RatingScheduleModal
        open={isModalRatingOpen}
        onClose={handleCancelModal}
        scheduleId={schedulingViewId ?? ''}
      />
    </Container>
  );
}

export default Schedule;
