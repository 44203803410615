import { styled } from '@mui/material/styles';
import { AppBar, Link, Typography } from '@mui/material';

export const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.white.main,
  width: '100%',
  height: '8rem',
  top: 'auto',
  bottom: 0,
  left: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const StyledLinkHelpPages = styled(Link)(({ theme }) => ({
  color: theme.palette.greyAE.main,
  cursor: 'pointer',
}));

export const StyledTypographyIcons = styled(Typography)(({ theme }) => ({
  color: theme.palette.black.main,
}));
