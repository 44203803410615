import { Close } from '@mui/icons-material';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { theme } from '../../theme';
import { PricesPerPersonI } from '../../app/api/menuAPI';

interface ModalForMenuPriceInformationI {
  pricesPerPerson: PricesPerPersonI[] | undefined;
  open: boolean;
  onClose: () => void;
  minPerson?: number;
}

const ModalForMenuPriceInformation = ({
  pricesPerPerson,
  onClose,
  open,
  minPerson = 1,
}: ModalForMenuPriceInformationI) => {
  const smallSize = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth='xs'>
      <Box display='flex' alignItems='center' justifyContent='center' flexDirection='row'>
        <DialogTitle
          align='center'
          variant={smallSize ? 'h6' : 'h5'}
          color='primary'
          display='flex'
        >
          Preços por pessoa
        </DialogTitle>
        <Box
          display='flex'
          justifyContent='flex-end'
          alignItems='flex-end'
          position='relative'
          left={smallSize ? -10 : 50}
          bottom={3}
        >
          <IconButton onClick={handleClose}>
            <Close fontSize='small' color='error' />
          </IconButton>
        </Box>
      </Box>
      <DialogContent
        sx={{
          sm: {
            marginX: 2,
            paddingX: 2,
          },
          xl: {
            marginX: 5,
            paddingX: 5,
          },
        }}
      >
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          flexDirection='column'
          gap={1}
        >
          <Box
            display='flex'
            justifyContent='center'
            flexDirection='column'
            alignItems='center'
            width={smallSize ? 300 : 395}
            overflow={'auto'}
            maxHeight={smallSize ? 350 : 'fit-content'}
            gap={2}
          >
            <Box maxHeight={smallSize ? 350 : 500} flexWrap={'wrap'} mb={3}>
              {pricesPerPerson?.map(({ number_of_guests, price }, index, array) => (
                <Box
                  key={index}
                  padding={smallSize ? 1 : 0}
                  ml={smallSize ? 3 : 0}
                  display={'flex'}
                  flexWrap={'wrap'}
                >
                  <Typography variant='body1' component='span' marginRight={1}>
                    Menu de {index === 0 ? minPerson : array[index - 1].number_of_guests + 1} para
                    até {number_of_guests} pessoas:
                  </Typography>
                  <Typography variant='body1' component='span' color='primary'>
                    R$ {price?.toFixed(2).replace('.', ',')}
                  </Typography>
                  <Typography variant='body1' component='span' marginLeft={1}>
                    pp
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ModalForMenuPriceInformation;
