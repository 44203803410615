import React from 'react';
import { Box, Container, Typography, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useGetFavoritesQuery, useRemoveFavoriteMutation } from '../../app/api/favoriteAPI';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { setToastNotification } from '../../app/slices/appSlice';
import { getMe, getToken } from '../../app/slices/userSlice';
import { CategoryMenuCarousel } from '../../components';
import { theme } from '../../theme';
import { setMenusRecentlySeenToCookie } from '../../utils/setMenuRecentlySeenToCookie';
import NoFavoritesImg from '../../assets/images/NoFavoritesImg.png';
import { MenuForCategoryT } from '../../app/api/categoryAPI';
import { EvaluationDataByChefId, useGetEvaluationByChefIdsMutation } from '../../app/api/chefAPI';
import { getLowestPrice } from '../../utils/pricesByNumberFunctions';

function Favorites() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const token = useAppSelector(getToken);
  const me = useAppSelector(getMe);

  const smallSize = useMediaQuery(theme.breakpoints.down('sm'));

  const [evaluationChefsData, setEvaluationChefsData] = React.useState<EvaluationDataByChefId[]>(
    [],
  );

  const [getEvaluationByChefId] = useGetEvaluationByChefIdsMutation();
  const { data: favorites } = useGetFavoritesQuery(
    { populated: true },
    {
      skip: !token,
    },
  );

  const [removeFavorite, { isSuccess: isSuccessRemoveFavorite }] = useRemoveFavoriteMutation();

  const handleChefClick = (chefId: string) => {
    navigate(`/chef-profile/${chefId}`);
  };

  const handleMenuClick = (menuId: string) => {
    setMenusRecentlySeenToCookie(menuId);
    navigate(`/booking/${menuId}`);
  };

  const handleShareClick = (menuId: string) => {
    const urlToRedirect = window.location.href.replace('/favorites', `/booking/${menuId}`);
    navigator.clipboard.writeText(urlToRedirect);
    dispatch(
      setToastNotification({
        type: 'success',
        open: true,
        message: 'Link do menu copiado com sucesso!',
      }),
    );
  };

  const handleFavoriteClick = (menuId: string) => {
    if (me?.name && token) {
      removeFavorite({ menu_id: menuId });
    } else {
      dispatch(
        setToastNotification({
          type: 'warning',
          open: true,
          message: 'Para favoritar um menu é preciso estar logado!',
        }),
      );
      navigate('/sign-in');
    }
  };

  const handleEvaluationDataByChefId = async () => {
    if (favorites?.menus) {
      const favoritesChefMenu = favorites?.menus.map((menu) => menu.chef_user_id._id).flat();
      if (favoritesChefMenu?.length) {
        const evaluationData = await getEvaluationByChefId({ ids: favoritesChefMenu }).unwrap();
        setEvaluationChefsData(evaluationData);
      }
    }
  };

  const getDataEvaluationByChefId = (chefId: string) => {
    const chefSchedulingData = evaluationChefsData.find((item) => item._id === chefId);
    let ratting = '-';
    let experiences = 0;
    if (chefSchedulingData) {
      ratting =
        chefSchedulingData?.chef_id.evaluation_summary?.total_evaluation > 0 &&
        chefSchedulingData?.total_eval > 0
          ? (
              (chefSchedulingData?.chef_id.evaluation_summary.total_evaluation ?? 0) /
                (chefSchedulingData?.total_eval ?? 0) ?? 0
            ).toFixed(1)
          : '-';
      experiences = chefSchedulingData?.chef_id.evaluation_summary.total_number_of_schedulings;
    }
    return {
      ratting,
      experiences,
    };
  };

  React.useEffect(() => {
    if (isSuccessRemoveFavorite) {
      dispatch(
        setToastNotification({
          type: 'success',
          open: true,
          message: 'Favorito removido com sucesso!',
        }),
      );
    }
  }, [isSuccessRemoveFavorite]);

  React.useEffect(() => {
    if (isSuccessRemoveFavorite) {
      dispatch(
        setToastNotification({
          type: 'success',
          open: true,
          message: 'Favorito removido com sucesso!',
        }),
      );
    }
  }, [isSuccessRemoveFavorite]);

  React.useEffect(() => {
    if (favorites) {
      handleEvaluationDataByChefId();
    }
  }, [favorites]);

  return (
    <Container
      sx={{
        padding: { xs: '100px 50px 10px', md: '100px 0 5px' },
        margin: {
          xs: '10px auto 115px',
          sm: '10px auto 240px',
          md: '10px auto 200px',
          xl: '10px auto 55px',
        },
        minHeight: '90vh',
      }}
    >
      <Box>
        <Typography variant={smallSize ? 'h3' : 'h2'} color='primary'>
          Favoritos
        </Typography>
      </Box>

      <Box mt={8}>
        {favorites?.menus?.length ? (
          <Box>
            <Box>
              <Box display='flex' flexDirection='column' mt={2}>
                {favorites?.menus && (
                  <CategoryMenuCarousel
                    isFavoritePage
                    onChefClick={handleChefClick}
                    onFavoriteClick={handleFavoriteClick}
                    onMenuClick={handleMenuClick}
                    onShareClick={handleShareClick}
                    items={favorites?.menus.map((menu: MenuForCategoryT) => {
                      const { experiences, ratting } = getDataEvaluationByChefId(
                        menu.chef_user_id._id,
                      );
                      const lowestPriceMenu = getLowestPrice(menu.prices_per_person);

                      return {
                        menuId: menu._id || '',
                        menuImage: menu.picture_url,
                        menuName: menu.name,
                        menuPrice: lowestPriceMenu,
                        chefId: menu.chef_user_id._id,
                        chefName: menu.chef_user_id.name,
                        chefImage: menu.chef_user_id.chef_id.picture_url,
                        chefExperiences: experiences ?? 0,
                        chefRating: String(ratting ?? '-'),
                        isFavorite: true,
                      };
                    })}
                  />
                )}
              </Box>
            </Box>
          </Box>
        ) : (
          <>
            <Box
              display='flex'
              alignItems='center'
              justifyContent='center'
              flexDirection='column'
              gap={4}
              m={smallSize ? 25 : 20}
            >
              <img src={NoFavoritesImg} alt={'Nenhum menu favoritado'} width={300}></img>
              <Typography color='primary' variant={smallSize ? 'h6' : 'h4'} fontWeight={600}>
                Seus menus favoritos ficarão aqui
              </Typography>
            </Box>
          </>
        )}
      </Box>
    </Container>
  );
}

export default Favorites;
