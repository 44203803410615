import { apiBase } from '../../service';

export type CouponT = {
  _id?: string;
  code: string;
  percentage: number;
};

export const CouponService = apiBase.injectEndpoints({
  endpoints: (builder) => ({
    createCoupon: builder.mutation({
      query: ({ code, percentage }) => ({
        url: '/coupon',
        method: 'POST',
        body: { code, percentage },
      }),
      invalidatesTags: ['Coupon'],
    }),
    getCoupons: builder.query<CouponT[], undefined>({
      query: () => ({
        url: '/coupon',
      }),
      providesTags: ['Coupon'],
    }),
    getCouponById: builder.query<CouponT, string | undefined>({
      query: (CouponCode) => ({
        url: `/coupon/${CouponCode}`,
      }),
    }),
    updateCoupon: builder.mutation<CouponT, CouponT>({
      query: ({ _id, code, percentage }) => ({
        url: `/coupon/${_id}`,
        method: 'PUT',
        body: { code, percentage },
      }),
      invalidatesTags: ['Coupon'],
    }),
    removeCoupon: builder.mutation<CouponT, { _id: string }>({
      query: ({ _id }) => ({
        url: `/coupon/${_id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Coupon'],
    }),
  }),
});

export const {
  useCreateCouponMutation,
  useLazyGetCouponByIdQuery,
  useGetCouponsQuery,
  useRemoveCouponMutation,
  useUpdateCouponMutation,
} = CouponService;
