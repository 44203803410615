import { CreateClientFormT } from '../../../pages/SignUp';
import { LoginFormT } from '../../../pages/SignIn';
import { apiBase } from '../../service';
import { UpdateteClientFormT } from '../../../pages/ClientProfile';

type LoginResponseT = {
  token: string;
  refresh_token: string;
};

export type MeResponseT = {
  email: string;
  name: string;
  phone: string;
  document: string;
  chef_id?: {
    _id?: string;
  };
  user_type: 'client' | 'chef' | 'admin';
  _id?: string;
  __v?: number;
};

export type UserListT = {
  _id?: string;
  name: string;
  email: string;
  password: string;
  active: boolean;
  document?: string;
  user_type: 'client' | 'chef' | 'admin';
  chef_id: {
    _id?: string;
    picture_url: string;
    phone: string;
    document: string;
    chef_type: string;
    bio: string;
    title: string;
    attendance_radius_km: number;
    bank_info: {
      pix?: string;
      bank_acc?: string;
      bank_agency?: string;
      bank_type?: string;
    };
  };
};

export const UserService = apiBase.injectEndpoints({
  endpoints: (builder) => ({
    createClient: builder.mutation<MeResponseT, CreateClientFormT>({
      query: ({ email, name, password, phone, document }) => ({
        url: '/user/client',
        method: 'POST',
        body: { email, name, password, phone, document },
      }),
    }),
    login: builder.mutation<LoginResponseT, LoginFormT>({
      query: (body) => ({
        url: '/user/login',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Auth'],
    }),
    getMe: builder.query<MeResponseT, undefined>({
      query: () => ({
        url: '/user/me',
      }),
      providesTags: ['User'],
    }),
    updateClient: builder.mutation<MeResponseT, UpdateteClientFormT>({
      query: ({ email, name, password, phone }) => ({
        url: '/user/client',
        method: 'PUT',
        body: { email, name, password, phone },
      }),
      invalidatesTags: ['User'],
    }),
    changePassword: builder.mutation<MeResponseT, { password: string }>({
      query: ({ password }) => ({
        url: '/user/update-password',
        method: 'PATCH',
        body: { password },
      }),
    }),
    getAllUsers: builder.query<UserListT[], undefined>({
      query: () => ({
        url: '/user?user_type_arr=["chef","client"]',
      }),
      providesTags: ['User'],
    }),
  }),
});

export const {
  useCreateClientMutation,
  useLoginMutation,
  useGetMeQuery,
  useUpdateClientMutation,
  useGetAllUsersQuery,
  useChangePasswordMutation,
} = UserService;
