import React from 'react';

import { Box, Typography, Tooltip } from '@mui/material';
import StarRateIcon from '@mui/icons-material/StarRate';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteOutlinedIcon from '@mui/icons-material/FavoriteOutlined';

import {
  Container,
  MenuImage,
  BottomInfo,
  ChefImage,
  FavoriteIconButton,
  ShareIconButton,
} from './menuCard.styles';
import { ShareOutlined } from '@mui/icons-material';
import { theme } from '../../theme';

type MenuCardPropsT = {
  menuId: string;
  menuImage: string;
  menuName: string;
  menuPrice?: number;
  isFavorite: boolean;
  chefId: string;
  chefImage: string;
  chefName: string;
  chefRating: string;
  chefExperiences: number;
  onShareClick: (menuId: string) => void;
  onFavoriteClick: (menuId: string) => void;
  onChefClick: (chefId: string) => void;
  onMenuClick: (menuId: string) => void;
};

const MenuCard = ({
  menuId,
  menuImage,
  menuName,
  menuPrice,
  isFavorite,
  chefId,
  chefImage,
  chefName,
  chefRating,
  chefExperiences,
  onShareClick,
  onFavoriteClick,
  onChefClick,
  onMenuClick,
}: MenuCardPropsT) => {
  return (
    <Container>
      <MenuImage alt='menu img' src={menuImage} onClick={() => onMenuClick(menuId)} />
      <ShareIconButton aria-label='share' onClick={() => onShareClick(menuId)}>
        <ShareOutlined color={'primary'} />
      </ShareIconButton>
      <FavoriteIconButton aria-label='favorite' onClick={() => onFavoriteClick(menuId)}>
        {isFavorite ? (
          <FavoriteOutlinedIcon color={'primary'} />
        ) : (
          <FavoriteBorderIcon color={'primary'} />
        )}
      </FavoriteIconButton>
      <BottomInfo>
        <ChefImage alt='chef img' src={chefImage} onClick={() => onChefClick(chefId)} />
        <Box ml={1} display='flex' flexDirection='column' flex={1} alignItems='flex-start'>
          <Box display='flex' alignItems='center'>
            <StarRateIcon fontSize='small' sx={{ marginTop: -0.4 }} />
            <Typography variant='caption' lineHeight={1.2}>
              {chefRating}
            </Typography>
            <Typography variant='caption' lineHeight={1.2} ml={1}>
              • {chefExperiences} experiências
            </Typography>
          </Box>
          <>
            {menuName.length > 24 ? (
              <Tooltip placement='top' title={menuName}>
                <Typography
                  variant='caption'
                  fontWeight='bold'
                  lineHeight={1.2}
                >{`${menuName.substring(0, 22)}...`}</Typography>
              </Tooltip>
            ) : (
              <Typography variant='caption' fontWeight='bold' lineHeight={1.2}>
                {menuName}
              </Typography>
            )}
          </>
          <Typography
            variant='caption'
            fontWeight='bold'
            lineHeight={1.2}
            color={theme.palette.greyAE.main}
          >
            {menuPrice ? `R$ ${String(menuPrice.toFixed(2))?.replace('.', ',')} pp` : ''}
          </Typography>
          <Typography variant='caption' lineHeight={1.2}>
            {chefName}
          </Typography>
        </Box>
      </BottomInfo>
    </Container>
  );
};

export default MenuCard;
