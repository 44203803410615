import Cookies from 'universal-cookie';

export const setMenusRecentlySeenToCookie = (menuId: string) => {
  const cookies = new Cookies();
  const menusCookie = cookies.get('abouteat_recently_seen_menus');
  if (menusCookie) {
    const menusToSave = new Set([...menusCookie, menuId]);
    cookies.set('abouteat_recently_seen_menus', Array.from(menusToSave), {
      path: '/explore',
      expires: new Date(Date.now() + 2592000000),
    });
  } else {
    cookies.set('abouteat_recently_seen_menus', [menuId], {
      path: '/explore',
      expires: new Date(Date.now() + 2592000000),
    });
  }
};
