/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Add, Delete } from '@mui/icons-material';
import {
  Box,
  Button,
  FormControlLabel,
  IconButton,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import {
  Control,
  useFieldArray,
  UseFormRegister,
  UseFormWatch,
  Controller,
  FieldErrors,
} from 'react-hook-form';
import { MenuFormT } from '.';
import { ItemT } from '../../../../app/api/itemAPI';
import NestedFieldArray from './NestedFieldArray';

export type FieldArrayT = {
  control: Control<MenuFormT, any>;
  register: UseFormRegister<MenuFormT>;
  watch: UseFormWatch<MenuFormT>;
  errors: Partial<FieldErrors<MenuFormT>>;
  items: ItemT[];
};

const ConfigFieldArray = ({ control, register, watch, errors, items }: FieldArrayT) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'configurations',
  });

  return (
    <>
      {fields.map((item, index) => (
        <Box key={item.id} display='flex' alignItems='center'>
          <Box p={2}>
            <Typography>Configuração {index + 1}</Typography>
            <Controller
              control={control}
              name={`configurations.${index}.dietary_requirements`}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  control={
                    <Switch
                      onChange={onChange}
                      checked={Boolean(value) ?? false}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  }
                  label='Necessidades dietéticas'
                />
              )}
            />
            <TextField
              required
              id={`configurations.${index}.name-id`}
              label='Nome'
              fullWidth
              size='small'
              margin='normal'
              InputLabelProps={{ shrink: true }}
              defaultValue=''
              {...register(`configurations.${index}.name`)}
              error={errors.configurations?.[index]?.name ? true : false}
              helperText={
                errors.configurations?.[index]?.name
                  ? String(errors.configurations?.[index]?.name?.message)
                  : null
              }
            />
            <TextField
              required
              id={`configurations.${index}.description`}
              label='Descrição'
              fullWidth
              size='small'
              margin='normal'
              InputLabelProps={{ shrink: true }}
              defaultValue=''
              {...register(`configurations.${index}.description`)}
              error={errors.configurations?.[index]?.description ? true : false}
              helperText={
                errors.configurations?.[index]?.description
                  ? String(errors.configurations?.[index]?.description?.message)
                  : null
              }
            />
            <TextField
              required
              id={`configurations.${index}.order`}
              label='Ordem'
              type='number'
              fullWidth
              size='small'
              margin='normal'
              InputLabelProps={{ shrink: true }}
              defaultValue={0}
              {...register(`configurations.${index}.order`)}
              error={errors.configurations?.[index]?.order ? true : false}
              helperText={
                errors.configurations?.[index]?.order
                  ? String(errors.configurations?.[index]?.order?.message)
                  : null
              }
            />
            <TextField
              id={`configurations.${index}.min_quantity`}
              label='Quantidade Mínima'
              type='number'
              fullWidth
              size='small'
              margin='normal'
              InputLabelProps={{ shrink: true }}
              {...register(`configurations.${index}.min_quantity`)}
              error={errors.configurations?.[index]?.min_quantity ? true : false}
              helperText={
                errors.configurations?.[index]?.min_quantity
                  ? String(errors.configurations?.[index]?.min_quantity?.message)
                  : null
              }
            />
            <TextField
              id={`configurations.${index}.max_quantity`}
              label='Quantidade Máxima'
              type='number'
              fullWidth
              size='small'
              margin='normal'
              InputLabelProps={{ shrink: true }}
              {...register(`configurations.${index}.max_quantity`)}
              error={errors.configurations?.[index]?.max_quantity ? true : false}
              helperText={
                errors.configurations?.[index]?.max_quantity
                  ? String(errors.configurations?.[index]?.max_quantity?.message)
                  : null
              }
            />
            <NestedFieldArray
              nestIndex={index}
              control={control}
              register={register}
              watch={watch}
              errors={errors}
              items={items}
            />
          </Box>
          <IconButton aria-label='delete' onClick={() => remove(index)}>
            <Delete color={'primary'} />
          </IconButton>
        </Box>
      ))}
      <Button
        onClick={() => {
          append({
            name: '',
            description: '',
            order: 0,
            max_quantity: undefined,
            min_quantity: undefined,
            dietary_requirements: false,
            config_items: [],
          });
        }}
      >
        <Add color={'primary'} /> Adicionar Configuração
      </Button>
    </>
  );
};

export default ConfigFieldArray;
