import { apiBase } from '../../service';

export type DashboardT = {
  total_number_of_schedulings: number;
  schedulings_by_state: {
    Finalizado?: number;
    Agendado?: number;
    'Aguardando Pagamento'?: number;
    'Erro no pagamento'?: number;
    Cancelado?: number;
  };
  schedulings_by_date: {
    [key: string]: {
      total: number;
    };
  };
  most_scheduled_menus: {
    _id: string;
    name: string;
    scheduled_quantity: number;
  }[];
  average_cost_per_scheduling: number;
  total_earnings: number;
};

export type GetDashboardQueryParamsT = {
  start_date?: string;
  end_date?: string;
};

export const DashboardService = apiBase.injectEndpoints({
  endpoints: (builder) => ({
    getDashboard: builder.query<DashboardT, GetDashboardQueryParamsT | undefined>({
      query: (queryParams) => {
        let obj = {};
        if (queryParams?.start_date) obj = { ...obj, start_date: queryParams?.start_date };
        if (queryParams?.end_date) obj = { ...obj, end_date: queryParams?.end_date };
        return {
          url: '/dashboard',
          params: obj,
        };
      },
      providesTags: ['Dashboard'],
    }),
  }),
});

export const { useLazyGetDashboardQuery } = DashboardService;
