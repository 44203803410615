import React from 'react';

import ExploreHero from './ExploreHero';
import CateringHero from './CateringHero';

import { Container } from './landingPage.styles';
import Features from './Features';
import Customers from './Customers';

function LandingPage() {
  return (
    <Container>
      <ExploreHero />
      <CateringHero />
      <Features />
      <Customers />
    </Container>
  );
}

export default LandingPage;
