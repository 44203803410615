import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { TimelineItem } from '@mui/lab';

export const Container = styled(Box)(() => ({
  width: '80%',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  height: 'max-content',
  minHeight: '90vh',
}));

export const ItemsWrapper = styled(Box)(() => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
}));

export const BoxCenter = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
}));

export const CardSchedule = styled(Box)(() => ({
  border: '0.5px solid',
  borderRadius: '20px',
  boxShadow: '3px 3px grey',
  padding: '16px',
  marginTop: '-40px',
  marginBottom: '40px',
  textAlign: 'center',
  ':hover': {
    border: '0.5px solid red',
    boxShadow: '3px 3px red',
  },
}));

export const TimeLineItemAE = styled(TimelineItem)(() => ({
  width: 600,
  ':hover': {
    borderTop: '1px red',
  },
}));
