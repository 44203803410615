import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import { ScrollContainer } from 'react-indiana-drag-scroll';

type CarouselItemT = {
  isActive?: boolean;
};

export const CarouselWrapper = styled(ScrollContainer)(({ theme }) => ({
  width: '100%',
  display: '-webkit-box',
  justifyContent: 'center',
  alignItems: 'center',
  overflowX: 'auto',
  borderTop: `1px solid ${theme.palette.black.main}`,
}));

export const CarouselItem = styled(Box)<{ active?: CarouselItemT }>(({ active }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '0 5px',
  width: '100px',
  minWidth: '100px',
  height: '100px',
  cursor: 'pointer',
  borderBottom: active?.isActive ? '3px solid red' : '',
}));

export const ItemIcon = styled('img')(() => ({
  width: '50px',
}));

export const ItemText = styled(Typography)(() => ({
  marginTop: '5px',
}));
