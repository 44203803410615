import React from 'react';

import { IconButton, Typography } from '@mui/material';
import { Box } from '@mui/system';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

type BookingCateringMenuItemT = {
  label: string;
  value: number;
  onChange: (value: number) => void;
  maxGeneralValue?: number;
  maxValue: number;
  price: number;
  optionalDescription?: string;
  isMobile: boolean;
  usePrimaryColor?: boolean;
};

const BookingCateringMenuItem = ({
  label,
  value,
  onChange,
  maxGeneralValue = 1000,
  maxValue,
  price,
  optionalDescription,
  isMobile,
  usePrimaryColor,
}: BookingCateringMenuItemT) => {
  const handleIncreaseValue = () => {
    if (maxValue > 0) {
      onChange(value + 1);
    }
  };

  const handleDecreaseValue = () => {
    if (value > 0) {
      onChange(value - 1);
    }
  };

  return (
    <Box display='flex' alignItems='center' marginTop={isMobile ? 2.5 : 1.5}>
      <IconButton size='small' onClick={handleDecreaseValue} disabled={value === 0}>
        <RemoveIcon />
      </IconButton>
      <Typography>{value}</Typography>
      <IconButton
        size='small'
        onClick={handleIncreaseValue}
        disabled={maxGeneralValue === 0 || value === maxValue}
        sx={{ marginRight: '10px' }}
      >
        <AddIcon />
      </IconButton>
      <Box display='flex' alignItems='center'>
        <Typography>
          <b>{label}</b>
          {optionalDescription ? ` - ${optionalDescription}` : ''}
        </Typography>{' '}
        <Typography
          minWidth='fit-content'
          color={usePrimaryColor ? 'primary' : 'secondary'}
          ml={1}
        >{`R$ ${String(price.toFixed(2))?.replace('.', ',')}`}</Typography>
      </Box>
    </Box>
  );
};

export default BookingCateringMenuItem;
