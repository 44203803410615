import React from 'react';
import { Dayjs } from 'dayjs';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Box, Button, TextField, Tooltip, Typography, useMediaQuery } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/pt-br';
import {
  ScheduleT,
  useLazyGetAvailableScheduleQuery,
  useLazyGetScheduleQuery,
} from '../../app/api/scheduleAPI';
import { BookingCateringMenuItem } from '../../components';
import GoogleAutoComplete, { LocationT } from '../GoogleAutoComplete';

import { Container } from './bookingCheckout.styles';
import { MenuByIdConfigurationT, PricesPerPersonI } from '../../app/api/menuAPI';
import { OptionItemT, OptionT } from '../../pages/Booking';
import { LoadingButton } from '@mui/lab';
import { theme } from '../../theme';
import { MobileDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { getPriceByNumberOfGuest } from '../../utils/pricesByNumberFunctions';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import ModalForMenuPriceInformation from '../ModalForMenuPriceInformation';
import { useLazyGetCouponByIdQuery } from '../../app/api/couponAPI';
import { setToastNotification } from '../../app/slices/appSlice';
import { useAppDispatch } from '../../app/hooks';
import { isAfter, isBefore } from 'date-fns';

type BookingCheckoutT = {
  isCatering: boolean;
  chefId: string;
  menuId: string;
  menuPrices?: PricesPerPersonI[];
  maxPerson?: number;
  minPerson?: number;
  latParam?: number;
  longParam?: number;
  addressParam?: string;
  configurations: MenuByIdConfigurationT[];
  option_items: OptionItemT[];
  setSelectedOption: (option: OptionItemT) => void;
  selectedOptions: OptionT[];
  onSubmit: (checkout: CheckoutFormT, location: LocationT) => void;
  deliveryRate?: number;
};

export type CheckoutFormT = {
  address: string;
  address_complement?: string;
  number_of_guests?: number;
  date: string;
  time: string;
  coupon: string;
  coupon_id?: string;
  observation?: string;
};

const BookingCheckout = ({
  isCatering,
  chefId,
  menuId,
  menuPrices,
  maxPerson,
  minPerson,
  latParam,
  longParam,
  addressParam,
  configurations,
  option_items,
  setSelectedOption,
  selectedOptions,
  onSubmit,
  deliveryRate,
}: BookingCheckoutT) => {
  const dispatch = useAppDispatch();
  const [modalInfosPriceMenu, setModalInfosPriceMenu] = React.useState<boolean>(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [location, setlocation] = React.useState<LocationT | undefined>();
  const [loadingAvailable, setLoadingAvailable] = React.useState<boolean>(true);
  const [coupon, setCoupon] = React.useState<{
    _id?: string;
    code: string;
    percentage: number;
  } | null>(null);

  const [
    getAvailableSchedule,
    {
      data: availableSlots,
      isLoading: isLoadingAvailableSchedule,
      isError: isErrorAvailableSchedule,
    },
  ] = useLazyGetAvailableScheduleQuery();
  const [getSchedule, { data: schedule, isLoading: isLoadingSchedule, isError: isErrorSchedule }] =
    useLazyGetScheduleQuery();

  const [getCouponById] = useLazyGetCouponByIdQuery();

  const dietaryConfig = configurations.find((cf) => cf.dietary_requirements === true);

  const [menuPrice, setMenuPrice] = React.useState(0);

  const validationSchema = Yup.object().shape({
    address: Yup.string().required('Endereço é obrigatório'),
    address_complement: Yup.string(),
    number_of_guests: isCatering
      ? Yup.number().max(maxPerson || 10, `Número máximo de convidados é ${maxPerson || 10}`)
      : Yup.number()
          .min(1, 'Número de Convidados é obrigatório')
          .max(maxPerson || 10, `Número máximo de convidados é ${maxPerson || 10}`)
          .required('Número de Convidados é obrigatório'),
    date: Yup.string().required('Data é obrigatória'),
    time: Yup.string()
      .required('Horario é obrigatório')
      .test('valid-time', 'Horário não disponivel para o chef', (value) => {
        const availableTimes = availableSlots?.filter((as) => as.available === true);
        if (availableTimes?.length === 0) {
          return false;
        }
        const matchSlot = availableTimes?.find((slot) => {
          const startDateTime = new Date(
            2023,
            6,
            10,
            Number(slot?.start?.split(':')[0]),
            Number(slot?.start?.split(':')[1]),
          );
          const endDateTime = new Date(
            2023,
            6,
            10,
            Number(slot?.end?.split(':')[0]),
            Number(slot?.end?.split(':')[1]),
          );
          const selectedDateTime = new Date(
            2023,
            6,
            10,
            Number(value?.split(':')[0]),
            Number(value?.split(':')[1]),
          );
          return (
            isAfter(selectedDateTime, startDateTime) && isBefore(selectedDateTime, endDateTime)
          );
        });
        return Boolean(matchSlot);
      }),
    copon: Yup.string(),
    observation: Yup.string(),
  });

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    watch,
    formState: { errors },
    reset,
  } = useForm<CheckoutFormT>({
    resolver: yupResolver(validationSchema),
  });

  React.useEffect(() => {
    if (menuPrices) {
      setMenuPrice(
        getPriceByNumberOfGuest({
          numberOfGuestToSearch: watch('number_of_guests') || 1,
          pricesPerPerson: menuPrices,
        })?.price || 0,
      );
    }
  }, [watch('number_of_guests'), menuPrices]);

  const shouldSumValuePrice = (item: OptionItemT) => {
    if (isCatering) {
      return true;
    }
    if (!item) {
      return false;
    }
    if (item.option_item_value === undefined) {
      return false;
    }
    if (item.config_id === dietaryConfig?._id) {
      return false;
    }
    const config = configurations.find((cf) => cf._id === item.config_id);
    if (config) {
      const options = selectedOptions.find((so) => so.option_id === config._id);
      if (options) {
        const position = options.option_items.indexOf(item) + 1;
        if (item.config_min_quantity < position) {
          return true;
        } else {
          return false;
        }
      }
    }
    return true;
  };

  const dietaryConfigValue = () => {
    const allItems = selectedOptions;
    let value = 0;
    for (let index = 0; index < allItems.length; index++) {
      const item = allItems[index];
      if (item.option_id === dietaryConfig?._id) {
        for (let indexJ = 0; indexJ < item.option_items.length; indexJ++) {
          const option = item.option_items[indexJ];
          if (option.config_id === dietaryConfig?._id) {
            value = value + (option?.option_item_value || 0) * option?.selected_quantity;
          }
        }
      }
    }
    if (coupon) {
      const discount = (value * coupon.percentage) / 100;
      value = value - discount;
    }
    return value;
  };

  const selectedItems = () => {
    const allItems = selectedOptions.filter((so) => so.option_id !== dietaryConfig?._id);
    const items = [];
    for (let index = 0; index < allItems.length; index++) {
      const item = allItems[index];
      for (let indexJ = 0; indexJ < item.option_items.length; indexJ++) {
        const option = item.option_items[indexJ];
        items.push(`${option.selected_quantity}x ${option.option_item_name}`);
      }
    }
    return items;
  };

  const subTotal = () => {
    const allItems = selectedOptions;
    let subTotalValue = 0;
    for (let index = 0; index < allItems.length; index++) {
      const item = allItems[index];
      for (let indexJ = 0; indexJ < item.option_items.length; indexJ++) {
        const option = item.option_items[indexJ];
        if (option.option_item_value && shouldSumValuePrice(option)) {
          subTotalValue = subTotalValue + option.option_item_value * option.selected_quantity;
        }
      }
    }
    if (!isCatering && menuPrice) {
      subTotalValue += menuPrice;
    }
    return subTotalValue;
  };

  const total = () => {
    let totalValue = subTotal();
    if (isCatering && deliveryRate) {
      totalValue += deliveryRate;
    }
    if (coupon) {
      const discount = (totalValue * coupon.percentage) / 100;
      totalValue = totalValue - discount;
    }

    if (isCatering) {
      return `R$ ${(totalValue + dietaryConfigValue()).toFixed(2)}`;
    }
    return `${
      getValues('number_of_guests')?.toString() === '0'
        ? ''
        : `x${getValues('number_of_guests')} convidados:`
    } R$ ${(totalValue * (getValues('number_of_guests') || 1) + dietaryConfigValue()).toFixed(2)}`;
  };

  React.useEffect(() => {
    if (latParam && longParam && addressParam) {
      setValue('address', addressParam);
      setlocation({ lat: latParam, long: longParam });
    }
  }, [latParam, longParam, addressParam]);

  React.useEffect(() => {
    getSchedule(chefId);
  }, [chefId]);

  React.useEffect(() => {
    setLoadingAvailable(isLoadingAvailableSchedule);
  }, [isLoadingAvailableSchedule]);

  React.useEffect(() => {
    if (isErrorAvailableSchedule) {
      reset({
        date: '',
      });
    }
  }, [isErrorAvailableSchedule]);

  React.useEffect(() => {
    if (isErrorSchedule) {
      reset({
        date: '',
      });
    }
  }, [isErrorSchedule]);

  const fetchAvailableSchedule = async () => {
    const date = getValues('date');
    if (date) {
      setLoadingAvailable(true);
      await getAvailableSchedule({ date, chefId, menuId }).unwrap();
      setLoadingAvailable(false);
    }
  };

  const findOption = (itemId: string) => {
    const op = option_items?.find((os) => os.option_item_id === itemId);
    return op ? op.selected_quantity : 0;
  };

  const handleAddressSelection = (address?: string, location?: LocationT) => {
    if (address) {
      setValue('address', address);
    }
    setlocation(location);
  };

  const handleSubmitForm = (formValues: CheckoutFormT) => {
    if (location) {
      onSubmit(formValues, location);
    }
  };

  const checkAvailableDays = (date: Dayjs) => {
    if (schedule) {
      const { days } = schedule as ScheduleT;
      const enabledDays = days?.map(({ day }) => day) || [];
      return enabledDays.length
        ? !enabledDays.includes(date.day()) || date.toDate().getTime() - Date.now() < 86400000
        : true;
    }
    return true;
  };

  const handleCheckCoupon = async (code: string) => {
    const result = await getCouponById(code);

    if (result.isSuccess) {
      dispatch(
        setToastNotification({
          type: 'success',
          open: true,
          message: 'Cupom adicionado com sucesso!',
        }),
      );
      setValue('coupon_id', result.data._id);
      setCoupon({
        _id: result.data._id,
        code: result.data.code,
        percentage: result.data.percentage,
      });
    }
  };

  return (
    <Box>
      <Container props={{ isCatering }}>
        <Typography variant='h5' fontWeight='bold' alignSelf='center'>
          Seu Pedido
        </Typography>
        <GoogleAutoComplete
          required
          fullWidth
          label='Endereço (Filtrar por localização)'
          size='small'
          margin='normal'
          error={errors.address ? true : false}
          helperText={errors.address ? String(errors.address.message) : undefined}
          setAddress={handleAddressSelection}
          defaultValue={getValues('address')}
        />
        <TextField
          sx={{ display: 'none' }}
          required
          id='address-field'
          label='Endereço (Filtrar por localização)'
          fullWidth
          size='small'
          margin='normal'
          InputLabelProps={{ shrink: true }}
          {...register('address')}
          error={errors.address ? true : false}
          helperText={errors.address ? String(errors.address.message) : null}
        />
        <TextField
          id='address-complement-field'
          label='Complemento'
          fullWidth
          size='small'
          margin='normal'
          InputLabelProps={{ shrink: true }}
          {...register('address_complement')}
          error={errors.address_complement ? true : false}
          helperText={errors.address_complement ? String(errors.address_complement.message) : null}
        />
        {!isCatering && (
          <TextField
            required={!isCatering}
            id='guests-field'
            label='Número de Convidados'
            defaultValue={0}
            fullWidth
            InputProps={{ inputProps: { min: 0, max: maxPerson || 10 } }}
            size='small'
            margin='normal'
            InputLabelProps={{ shrink: true }}
            type='number'
            {...register('number_of_guests')}
            error={errors.number_of_guests ? true : false}
            helperText={errors.number_of_guests ? String(errors.number_of_guests.message) : null}
          />
        )}
        <Box>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='pt-br'>
            <MobileDatePicker
              label='Data do Evento'
              value={watch('date') || ''}
              onChange={(newValue) => {
                if (newValue) {
                  setValue('date', newValue.toDate().toLocaleDateString('fr-CA'));
                  fetchAvailableSchedule();
                }
              }}
              shouldDisableDate={checkAvailableDays}
              disablePast
              readOnly={isLoadingSchedule}
              renderInput={(params) => (
                <TextField
                  required
                  id='date-field'
                  fullWidth
                  size='small'
                  margin='normal'
                  InputLabelProps={{ shrink: true }}
                  helperText={errors.date ? String(errors.date.message) : null}
                  {...register('date')}
                  {...params}
                  error={!!errors.date}
                  sx={{ input: { cursor: 'pointer' } }}
                />
              )}
            />
          </LocalizationProvider>
        </Box>
        {loadingAvailable ? (
          <LoadingButton
            size='small'
            sx={{
              background: theme.palette.white.main,
              color: theme.palette.primary.main,
              height: '50px',
              marginTop: '8px',
              marginBottom: '4px',
            }}
            loading
            variant='outlined'
            fullWidth
          >
            Horário
          </LoadingButton>
        ) : (
          <TextField
            required
            id='time'
            disabled={
              !getValues('date') ||
              Boolean(!availableSlots?.filter((as) => as.available === true)?.length)
            }
            label={
              getValues('date') && !availableSlots?.filter((as) => as.available === true)?.length
                ? 'Nenhum horário disponível'
                : 'Horário'
            }
            fullWidth
            type='time'
            size='small'
            margin='normal'
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              step: 600,
            }}
            {...register('time')}
            error={errors.time ? true : false}
            helperText={
              getValues('date') && !availableSlots?.filter((as) => as.available)?.length
                ? 'Nenhum horário disponível para este dia'
                : errors.time
                ? String(errors.time.message)
                : null
            }
          />
        )}
        {selectedItems().length > 0 && (
          <Box marginY={1}>
            <Typography
              mt={1}
              variant='h6'
              fontWeight='bold'
              color={isCatering ? 'secondary' : 'primary'}
            >
              Resumo do pedido
            </Typography>
            <Box>
              {selectedItems().map((si) => (
                <Typography marginTop={2.5} key={si}>
                  {si}
                </Typography>
              ))}
            </Box>
          </Box>
        )}
        {dietaryConfig && (
          <>
            <Typography
              mt={1}
              variant='h6'
              fontWeight='bold'
              color={isCatering ? 'secondary' : 'primary'}
            >
              {dietaryConfig.name}
            </Typography>
            <Typography>{dietaryConfig.description}</Typography>
            <Box mb={1}>
              {dietaryConfig.config_items.map((ci) => (
                <BookingCateringMenuItem
                  key={ci._id}
                  label={ci.item_id.name}
                  isMobile={isMobile}
                  value={findOption(ci._id)}
                  usePrimaryColor={!isCatering}
                  onChange={(value: number) =>
                    setSelectedOption({
                      option_item_id: ci._id,
                      option_item_name: ci.item_id.name,
                      config_min_quantity: dietaryConfig.min_quantity || 0,
                      config_id: dietaryConfig._id,
                      option_item_value: ci?.value,
                      selected_quantity: value,
                    })
                  }
                  maxValue={ci.max_quantity ?? 100}
                  price={ci.value || 0}
                />
              ))}
            </Box>
          </>
        )}
        <TextField
          id='msg-field'
          label='Mensagem ao Chef'
          fullWidth
          size='small'
          margin='normal'
          InputLabelProps={{ shrink: true }}
          multiline
          maxRows={4}
          minRows={2}
          {...register('observation')}
          error={errors.observation ? true : false}
          helperText={errors.observation ? String(errors.observation.message) : null}
        />
        <Box display='flex' flexDirection={'row'} gap={2}>
          <TextField
            id='coupon'
            label='Cupom de desconto'
            fullWidth
            size='small'
            margin='normal'
            sx={{
              input: {
                textTransform: 'uppercase',
              },
            }}
            InputLabelProps={{ shrink: true }}
            {...register('coupon')}
            error={errors.coupon ? true : false}
            helperText={errors.coupon ? String(errors.coupon.message) : null}
          />
          <LoadingButton
            fullWidth
            size='small'
            variant='contained'
            color={'primary'}
            sx={{ borderRadius: 2, width: 200, height: 40, alignSelf: 'center', top: 3 }}
            onClick={() => {
              const coupon = getValues('coupon');
              handleCheckCoupon(coupon.toUpperCase());
            }}
          >
            Aplicar cupom
          </LoadingButton>
        </Box>
        {isCatering && deliveryRate ? (
          <Box display='flex' alignItems='baseline'>
            <Typography
              mt={1}
              variant='h6'
              fontWeight='bold'
              color={isCatering ? 'secondary' : 'primary'}
            >
              Taxa de entega:
            </Typography>
            <Typography ml={1}>R$ {deliveryRate.toFixed(2)}</Typography>
          </Box>
        ) : null}

        {!isCatering ? (
          <Box display='flex' alignItems='baseline' flexWrap={'wrap'}>
            <Typography
              mt={1}
              variant='h6'
              fontWeight='bold'
              color={isCatering ? 'secondary' : 'primary'}
            >
              Subtotal:
            </Typography>
            <Typography ml={1} mr={1}>
              R$ {subTotal().toFixed(2)}
            </Typography>
            {menuPrices && menuPrices?.length > 1 ? (
              <Tooltip placement='top' title='Entenda mais sobre o valor'>
                <InfoIcon
                  color='info'
                  fontSize='small'
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setModalInfosPriceMenu(true);
                  }}
                />
              </Tooltip>
            ) : null}
          </Box>
        ) : null}

        {coupon ? (
          <Box display='flex' alignItems='baseline'>
            <Typography variant='h6' fontWeight='bold' color={isCatering ? 'secondary' : 'primary'}>
              Cupom:
            </Typography>
            <Box display='flex' justifyContent='flex-end' alignItems='center'>
              <Typography ml={1} variant='subtitle2'>
                {coupon?.code} ({coupon?.percentage}% OFF)
              </Typography>
            </Box>
          </Box>
        ) : null}

        <Box display='flex' alignItems='baseline'>
          <Typography variant='h6' fontWeight='bold' color={isCatering ? 'secondary' : 'primary'}>
            Total:
          </Typography>
          <Typography ml={1}>{total()}</Typography>
        </Box>
      </Container>

      <Button
        fullWidth
        size='large'
        onClick={handleSubmit(handleSubmitForm)}
        variant='contained'
        color={isCatering ? 'secondary' : 'primary'}
        sx={{ borderRadius: 0 }}
      >
        Agendar
      </Button>
      <ModalForMenuPriceInformation
        onClose={() => {
          setModalInfosPriceMenu(false);
        }}
        open={modalInfosPriceMenu}
        pricesPerPerson={menuPrices}
        minPerson={minPerson}
      />
    </Box>
  );
};

export default BookingCheckout;
