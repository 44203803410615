import React from 'react';

import { Box, Grid, Typography, useMediaQuery } from '@mui/material';

import Chef1 from '../../../assets/images/chef1.jpg';
import Chef2 from '../../../assets/images/chef2.jpg';
import Chef3 from '../../../assets/images/chef3.jpg';
import { theme } from '../../../theme';
import { ImpactText, StyledImg } from '../explore.style';
import FavoriteIcon from '@mui/icons-material/Favorite';

function Features() {
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box sx={{ paddingX: { xs: 0, md: 10 }, marginTop: { xs: 0, md: 10 } }}>
      <Grid container>
        <Grid item xs={12}>
          <ImpactText variant={isMobile ? 'h4' : 'h3'} sx={{ textAlign: 'center' }}>
            Nós facilitamos muito 
            <FavoriteIcon sx={{ fontSize: '40px', color: theme.palette.primary.main }} />
          </ImpactText>
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            paddingRight: { xs: 0, md: 5 },
            paddingBottom: { xs: 2, md: 0 },
          }}
        >
          <StyledImg
            src={Chef1}
            alt='Corporate canapes bg'
            size={{ isMobile: Boolean(isMobile) }}
          />
          <Typography>
            <b>Explore nossos menus</b>
          </Typography>
          <Typography>
            Você também pode solicitar menus personalizados através de nosso whatsapp.
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            paddingRight: { xs: 0, md: 5 },
            paddingBottom: { xs: 2, md: 0 },
          }}
        >
          <StyledImg
            src={Chef2}
            alt='Corporate buffet bg'
            size={{ isMobile: Boolean(isMobile) }}
          />
          <Typography>
            <b>Receba os menus e cotações</b>
          </Typography>
          <Typography>
            Selecione um menu na plataforma ou receba as cotações via e-mail ou whatsapp para
            comparar.
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <StyledImg
            src={Chef3}
            alt='Corporate canapes bg'
            size={{ isMobile: Boolean(isMobile) }}
          />
          <Typography>
            <b>Alterações e customizações</b>
          </Typography>
          <Typography>Converse conosco para edições no seu menu após o agendamento.</Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Features;
