import React from 'react';

import { Box, Grid, useMediaQuery } from '@mui/material';

import HowItWorksImg from '../../assets/images/HowItWorksImg.png';
import { theme } from '../../theme';
import {
  ChefSteps,
  CateringSteps,
  ChefStepsTitle,
  CateringStepsTitle,
  ImpactText,
  StyledImg,
  StyledChefLi,
  StyledCateringLi,
  StyledOl,
  QuestionsTitle,
  QuestionsSubTitle,
  Question,
  Answer,
} from './howItWorks.styles';
import { cateringQuestions, chefQuestions } from './data';

function HowItWorks() {
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  React.useEffect(() => {
    const element = document.getElementById('box-top-page');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  return (
    <Box id='box-top-page'>
      <Grid container>
        <Grid
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            backgroundColor: theme.palette.white.main,
            paddingTop: 10,
            paddingBottom: 5,
            paddingX: 5,
          }}
          item
          xs={12}
          md={5.9}
        >
          <Box paddingTop={isMobile ? 5 : 0}>
            <ImpactText variant='h4'>
              <b>Quer saber como agendar</b>
              <br />
              <b>sua social gastronômica?</b>
              <br />
              Veja nosso passo a passo!
            </ImpactText>
          </Box>
        </Grid>
        <Grid
          sx={{
            backgroundColor: theme.palette.white.main,
            display: { xs: 'none', md: 'flex' },
          }}
          item
          xs={0.2}
        ></Grid>
        <Grid
          sx={{
            display: { xs: 'none', md: 'flex' },
            alignItems: 'center',
            justifyContent: 'flex-start',
            paddingTop: 20,
            paddingBottom: 5,
          }}
          item
          xs={12}
          md={5.9}
        >
          <StyledImg src={HowItWorksImg} alt='explore hero bg' />
        </Grid>
      </Grid>
      <Grid container>
        <Grid
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            backgroundColor: theme.palette.primary.main,
            padding: 5,
          }}
          item
          xs={12}
          md={5.9}
        >
          <Box>
            <ChefStepsTitle variant='h5'>Para contratar um Chef particular:</ChefStepsTitle>
            <StyledOl>
              <StyledChefLi>
                <ChefSteps variant='h6'>
                  Divirta-se explorando com gosto todos os
                  <br />
                  menus e chefs disponíveis a você na Abouteat!
                </ChefSteps>
              </StyledChefLi>
              <StyledChefLi>
                <ChefSteps variant='h6'>
                  Escolheu aquele menu que te deixou com
                  <br />
                  água na boca? Selecione as preferências de
                  <br />
                  seu grupo e finalize o agendamento com o<br />
                  pagamento.
                </ChefSteps>
              </StyledChefLi>
              <StyledChefLi>
                <ChefSteps variant='h6'>
                  Pronto! Agora é só aguardar o dia da sua
                  <br />
                  social gastronômica e chegar apenas com
                  <br />
                  fome, pois o chef levará os ingredientes e<br />
                  preparará tudo na hora para vocês.
                </ChefSteps>
              </StyledChefLi>
            </StyledOl>
          </Box>
        </Grid>
        <Grid
          sx={{
            backgroundColor: theme.palette.white.main,
            display: { xs: 'none', md: 'flex' },
          }}
          item
          xs={0.2}
        ></Grid>
        <Grid
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            backgroundColor: theme.palette.yellow.main,
            padding: 5,
          }}
          item
          xs={12}
          md={5.9}
        >
          <Box>
            <CateringStepsTitle variant='h5'>Para contratar Catering:</CateringStepsTitle>
            <StyledOl>
              <StyledCateringLi>
                <CateringSteps variant='h6'>
                  Navegue por todos os menus e<br />
                  estabelecimentos disponíveis a você na
                  <br />
                  Abouteat.
                </CateringSteps>
              </StyledCateringLi>
              <StyledCateringLi>
                <CateringSteps variant='h6'>
                  Escolha o menu mais adequado a seu evento
                  <br />e finalize o agendamento com o pagamento.
                </CateringSteps>
              </StyledCateringLi>
              <StyledCateringLi>
                <CateringSteps variant='h6'>
                  Pronto! No dia do seu evento será entregue o<br />
                  menu escolhido, pronto para sua equipe alocar
                  <br />e servir como desejar.
                </CateringSteps>
              </StyledCateringLi>
            </StyledOl>
          </Box>
        </Grid>
      </Grid>
      <Grid container>
        <Grid
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignitems: 'center',
            flexDirection: 'column',
            backgroundColor: theme.palette.white.main,
            padding: 5,
          }}
          item
          xs={12}
          md={12}
        >
          <QuestionsTitle variant='h4'>Perguntas Frequentes</QuestionsTitle>
          <QuestionsSubTitle variant='h5' color='primary'>
            Sobre agendamento de chefes
          </QuestionsSubTitle>
          <Grid container>
            <Grid item xs={0} md={2} sx={{ display: { xs: 'none', md: 'flex' } }}></Grid>

            <Grid item xs={12} md={5} sx={{ marginTop: 2 }}>
              {chefQuestions.map((q) => (
                <div key={q.question}>
                  <Question color='primary' variant='h5'>
                    {q.question}
                  </Question>
                  <Answer>{q.answer}</Answer>
                </div>
              ))}
            </Grid>
            <Grid item xs={0} md={5} sx={{ display: { xs: 'none', md: 'flex' } }}></Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <Grid
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignitems: 'center',
            flexDirection: 'column',
            backgroundColor: theme.palette.white.main,
            padding: 5,
          }}
          item
          xs={12}
          md={12}
        >
          <QuestionsTitle variant='h4'>Perguntas Frequentes</QuestionsTitle>
          <QuestionsSubTitle variant='h5' color='secondary'>
            Sobre catering
          </QuestionsSubTitle>
          <Grid container>
            <Grid item xs={0} md={2} sx={{ display: { xs: 'none', md: 'flex' } }}></Grid>

            <Grid item xs={12} md={5} sx={{ marginTop: 2 }}>
              {cateringQuestions.map((q) => (
                <>
                  <Question color='secondary' variant='h5'>
                    {q.question}
                  </Question>
                  <Answer>{q.answer}</Answer>
                </>
              ))}
            </Grid>
            <Grid item xs={0} md={5} sx={{ display: { xs: 'none', md: 'flex' } }}></Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

export default HowItWorks;
