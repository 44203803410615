import { styled } from '@mui/material/styles';
import { Typography, Box } from '@mui/material';

export const ImpactText = styled(Typography)(({ theme }) => ({
  color: theme.palette.black.main,
  lineHeight: '90px',
  fontWeight: '700',
}));

export const BoxCenter = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
}));
